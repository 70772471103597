import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['cancelBtn', 'deletionSubmit', 'noTags', 'tag', 'tagForm'];

  deletionModal;
  tagToDelete = null;
  deletionForbiddenModal = null;

  connect() {
    this.deletionModal = $('#delete-bg-img-tag-modal');
    this.deletionForbiddenModal = $('#bg-img-deletion-forbidden-modal');
  }

  addNewTagForm() {
    this.noTagsTarget.classList.add('hidden');

    const newTagForm = this.tagFormTarget.cloneNode(true);

    newTagForm.classList.remove('hidden');

    this.tagFormTarget.after(newTagForm);

    const input = newTagForm.querySelector('input');
    input.focus();
  }

  closeTagForm(event) {
    const { target } = event;
    const closestForm = target.closest('.tag-form');
    const { tagId } = closestForm.dataset;

    if (tagId) {
      const editedTagCard = closestForm.previousSibling;
      editedTagCard.classList.remove('hidden');
    }
    closestForm.remove();

    this.hideNoTags(this.isAnyTag());
  }

  async submitTagForm(event) {
    try {
      const { target } = event;
      const closestForm = target.closest('.tag-form');
      const { tagId } = closestForm.dataset;

      const inputValue = closestForm.querySelector('input')?.value.trim();

      if (!inputValue) {
        return;
      }

      const method = tagId ? 'PUT' : 'POST';

      const authenticityToken = this.getCSRFtoken();

      const data = {
        authenticity_token: authenticityToken,
        tag: { name: inputValue }
      };

      const endpoint = `/style_builder/images_library/tags/${tagId || ''}`;

      const response = await fetch(endpoint, {
        method,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const result = await response.json();

      if (!response.ok) {
        this.hideNoTags(this.isAnyTag());

        throw new Error(`Response not OK. ${response.status}: ${result}`);
      }

      if (tagId) {
        const editedTagCard = closestForm.previousSibling;
        editedTagCard.dataset.tagName = inputValue;
        const nameContainer = editedTagCard.querySelector('.name');
        nameContainer.textContent = inputValue;

        editedTagCard.classList.remove('hidden');
      } else {
        this.displayNewTag(result);
      }
      closestForm.remove();
    } catch (error) {
      console.error(error);
    }
  }

  displayNewTag({ id, name }) {
    const newTag = this.tagTarget.cloneNode(true);

    newTag.classList.remove('hidden');
    newTag.setAttribute('data-tag-id', id);
    newTag.setAttribute('data-tag-name', name);
    newTag.setAttribute('data-associated-images-count', 0);

    const nameElement = newTag.querySelector('.name');
    nameElement.textContent = name;

    this.tagTarget.before(newTag);
  }

  editTag(event) {
    const { target } = event;

    const tagCard = target.closest('.tag');
    const { tagId, tagName } = tagCard.dataset;

    const editTagForm = this.tagFormTarget.cloneNode(true);
    editTagForm.dataset.tagId = tagId;

    const input = editTagForm.querySelector('input');
    input.value = tagName;

    const submitButton = editTagForm.querySelector('.submit-btn');
    submitButton.textContent = 'Save';

    editTagForm.classList.remove('hidden');
    tagCard.classList.add('hidden');
    tagCard.after(editTagForm);

    input.focus();
  }

  isAnyTag() {
    const notHiddenTags = this.tagTargets.filter(
      tag => !tag.classList.contains('hidden')
    );
    return notHiddenTags.length !== 0;
  }

  hideNoTags(shouldHide) {
    if (shouldHide) {
      this.noTagsTarget.classList.add('hidden');
    } else {
      this.noTagsTarget.classList.remove('hidden');
    }
  }

  openDeletionModal(event) {
    const { target } = event;
    const tagCard = target.closest('.tag');

    const { associatedImagesCount } = tagCard.dataset;

    if (Number(associatedImagesCount) === 0) {
      this.deletionModal.modal('show');
    } else {
      this.deletionForbiddenModal.modal('show');
    }

    this.tagToDelete = tagCard.dataset.tagId;
  }

  async removeTag() {
    this.deletionSubmitTarget.disabled = true;

    const endpoint = `/style_builder/images_library/tags/${this.tagToDelete}`;

    const authenticityToken = this.getCSRFtoken();

    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': authenticityToken
      }
    });

    this.deletionModal.modal('hide');
    this.deletionSubmitTarget.disabled = false;

    if (response.ok) {
      this.removeTagCard();
    } else {
      const failureAlert = document.getElementById('failure-alert');
      failureAlert.textContent =
        response.status === 422
          ? 'Category cannot be deleted because there are images assigned to it.'
          : 'Something went wrong';
      failureAlert.classList.remove('hidden');
    }
  }

  removeTagCard() {
    const cardToDelete = this.tagTargets.find(tag => {
      const { tagId } = tag.dataset;
      return tagId === this.tagToDelete;
    });
    cardToDelete.remove();

    this.hideNoTags(this.isAnyTag());
  }
}
