import Inputmask from 'inputmask';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    this.addAliases();

    Inputmask(this.element.dataset.maskFormat).mask(this.element);

    super.connect();
  }

  addAliases() {
    Inputmask.extendAliases({
      phone: {
        mask: () => ['999-999-9999', '+9-999-999-9999', '+99-999-999-9999']
      }
    });
  }
}
