import ApplicationController from './application_controller';

export default class extends ApplicationController {
  // In app/javascript/controllers/loading_alert_controller.js
  // We add event listener on every submit action to show loader after 300ms
  // This method hides this loader
  openUrl() {
    setTimeout(() => {
      this.forceHideLoadingMessage();
    }, 400);
  }
}
