import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'dollarZone',
    'newFreightForm',
    'newFreightZones',
    'percentZone',
    'retailValueType'
  ];

  enable_form_saving(evt) {
    evt.currentTarget
      .closest('tr')
      .querySelector('.actions button').disabled = false;
  }

  show_form(evt) {
    evt.currentTarget.disabled = true;
    this.newFreightFormTarget.classList.remove('d-none');
    this.newFreightZonesTarget.classList.remove('d-none');
  }

  validate_and_submit(evt) {
    const submitButton = evt.currentTarget;
    const { form } = submitButton;

    const requiredFields = Array.from(
      submitButton
        .closest('tr')
        .querySelectorAll(
          'input[required], input[required="required"], select[required="required"]'
        )
    );

    const errorMessageNode = requiredFields
      .find(({ name }) => name === 'retail_freight[order_min]')
      .closest('td')
      .querySelector('.invalid-feedback');
    errorMessageNode.style.display = 'none';

    const params = Object.fromEntries(new FormData(form).entries());
    const orderMin = parseFloat(params['retail_freight[order_min]']);
    const orderMax = parseFloat(params['retail_freight[order_max]']);

    if (orderMin >= orderMax) {
      evt.preventDefault();
      const message = 'Order Min must be less than Order Max';
      errorMessageNode.textContent = message;
      errorMessageNode.style.display = 'block';

      return;
    }

    const areRequiredFieldsFilled = requiredFields.every(
      field => field.value.trim() !== ''
    );

    if (areRequiredFieldsFilled) {
      form.submit();
    }
  }

  change_zones_type(evt) {
    const zonesType = evt.currentTarget.value;

    if (zonesType === 'percent') {
      this.retailValueTypeTargets.forEach(retailValueType => {
        retailValueType.value = 'percent';
      });

      this.dollarZoneTargets.forEach(zone => {
        zone.required = false;
        zone.parentNode.classList.add('d-none');
      });
      this.percentZoneTargets.forEach(zone => {
        zone.required = true;
        zone.parentNode.classList.remove('d-none');
      });
    } else {
      this.retailValueTypeTargets.forEach(retailValueType => {
        retailValueType.value = 'percent';
      });

      this.percentZoneTargets.forEach(zone => {
        zone.required = false;
        zone.parentNode.classList.add('d-none');
      });
      this.dollarZoneTargets.forEach(zone => {
        zone.required = true;
        zone.parentNode.classList.remove('d-none');
      });
    }
  }
}
