import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['uploadButton', 'loader', 'uploadSection'];

  connect() {
    super.connect();
  }

  disconnect() {
    super.disconnect();
  }

  handleFileUpload() {
    this.uploadButtonTarget.disabled = false;
    this.hideLoader();
  }

  handleFileDelete() {
    this.uploadButtonTarget.disabled = true;
  }

  showLoader() {
    this.loaderTarget.classList.remove('d-none');
    this.uploadSectionTarget.classList.add('d-none');
  }

  hideLoader() {
    this.loaderTarget.classList.add('d-none');
    this.uploadSectionTarget.classList.remove('d-none');
  }
}
