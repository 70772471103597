const getSelectionCaretAndLine = target => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  const {
    startContainer: { parentNode }
  } = range;

  const selectedNode =
    target === parentNode ? selection.anchorNode : parentNode;

  if (target.firstChild) {
    range.setStart(target.firstChild, 0);
  }

  const content = selection.toString();
  const text = JSON.stringify(content);
  const lines = (text.match(/\\n/g) || []).length + 1;

  return selectedNode
    ? {
        caret: text.length - 2,
        line: lines
      }
    : { caret: -1, line: -1 };
};

export default getSelectionCaretAndLine;
