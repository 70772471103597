import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['lpNumber', 'lpNumberButton'];

  connect() {
    super.connect();
  }

  searchByLpNumber() {
    const queryParams = new URLSearchParams(window.location.search);
    if (this.lpNumberTarget.value) {
      queryParams.set('filters[lp_number]', this.lpNumberTarget.value);
      queryParams.set('filters[status]', 'In Progress');
    } else {
      queryParams.delete('filters[lp_number]');
    }
    window.location.href = `/manage/receiving_purchase_orders/packing_slips/items?${queryParams}`;
  }
}
