import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['selectedCompanyId'];

  initializer() {
    this.propage_changes = false;
  }

  connect() {
    super.connect();

    this.selected_company = this.selectedCompanyIdTarget.value;

    $('#switchCompany').on('click', event => {
      event.preventDefault();

      this.propagate_change_company();
      this.propage_changes = true;
      $('#CompanySelectModal').modal('hide');
    });

    $('#CompanySelectModal').on('hidden.bs.modal', event => {
      event.preventDefault();

      if (this.propage_changes === true) {
        this.propage_changes = false;
        return;
      }

      this.cancel_change_company();
    });
  }

  disconnect() {
    $('#switchCompany').off('click');
    $('#CompanySelectModal').off('hidden.bs.modal');
  }

  change_company(event) {
    event.preventDefault();

    if (event.bubbles) {
      $('#CompanySelectModal').modal('show');
    }
  }

  cancel_change_company() {
    this.selectedCompanyIdTarget.value = this.selected_company;
    this.selectedCompanyIdTarget.dispatchEvent(
      new Event('change', { bubbles: false })
    );
  }

  propagate_change_company() {
    const selected_company_id_input = $('#selectedCompanyId');
    selected_company_id_input.val(this.selectedCompanyIdTarget.value);
    selected_company_id_input.trigger('click');
  }
}
