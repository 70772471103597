import Rails from '@rails/ujs';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['table'];
  timeoutHandlers = {};
  connect() {
    super.connect();
  }

  check_item({ currentTarget }) {
    const targetCompleteUrl = $(currentTarget).data('complete-url');
    const targetTr = $(currentTarget).closest('tr');
    const targetProductVariantId = targetTr.data('product-variant-id');

    this.checkbox_visible(targetProductVariantId, false);

    const timeoutHandler = setTimeout(() => {
      this.check_request(targetCompleteUrl);
    }, 5000);

    this.timeoutHandlers[targetProductVariantId] = timeoutHandler;
  }

  check_request(targetCompleteUrl) {
    this.forceShowLoadingMessage();

    Rails.ajax({
      type: 'POST',
      url: targetCompleteUrl,
      dataType: 'json',
      success: data => {
        this.forceHideLoadingMessage();
        $(this.tableTarget)
          .children(`tr[data-product-variant-id='${data.product_variant_id}']`)
          .remove();
        $('.popover').remove();
      },
      error: data => {
        this.forceHideLoadingMessage();
      }
    });
  }

  restore_item({ currentTarget }) {
    const targetRestoreUrl = $(currentTarget).data('restore-url');
    this.forceShowLoadingMessage();
    console.log('checking request ' + targetRestoreUrl);
    Rails.ajax({
      type: 'POST',
      url: targetRestoreUrl,
      dataType: 'json',
      success: data => {
        console.log('success');
        this.forceHideLoadingMessage();
        $(this.tableTarget)
          .children(`tr[data-product-variant-id='${data.product_variant_id}']`)
          .remove();
      },
      error: data => {
        console.log(data);
        this.forceHideLoadingMessage();
      }
    });
  }

  cancel_item_request({ currentTarget }) {
    const targetTr = $(currentTarget).closest('tr');
    const targetProductVariantId = targetTr.data('product-variant-id');

    this.checkbox_visible(targetProductVariantId, true);
    clearTimeout(this.timeoutHandlers[targetProductVariantId]);
  }

  checkbox_visible(trIdentifier, checkboxVisible = true) {
    const finder = `[data-product-variant-id=${trIdentifier}]`;
    const trKeys = $(this.tableTarget).find(finder).get();
    trKeys.forEach(trKey => {
      const targetCheckButton = $(trKey).find('.btn-check');
      const targetUndoButton = $(trKey).find('.btn-undo');
      if (checkboxVisible) {
        targetCheckButton.removeClass('hidden');
        targetUndoButton.addClass('hidden');
      } else {
        targetCheckButton.addClass('hidden');
        targetUndoButton.removeClass('hidden');
      }
    });
  }

  redirect(e) {
    const queryParams = new URLSearchParams(window.location.search);
    if (e.target.value) {
      queryParams.set('workflow_report_name', e.target.value);
    } else {
      queryParams.delete('workflow_report_name');
    }
    history.pushState(null, null, `?${queryParams}`);
    this.stimulate();
  }
}
