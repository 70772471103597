import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['disapproveReasonSelect'];

  connect() {
    super.connect();
  }

  onReturnStatusChange(e) {
    const selectedValue = e.target.value;
    const parent = this.disapproveReasonSelectTarget.parentElement;
    const button = parent.querySelector('button');

    if (selectedValue === 'approved') {
      parent.classList.add('disabled');
      button.classList.add('disabled');
      this.disapproveReasonSelectTarget.disabled = true;
    } else {
      parent.classList.remove('disabled');
      button.classList.remove('disabled');
      this.disapproveReasonSelectTarget.disabled = false;
    }
  }

  disconnect() {
    super.disconnect();
  }
}
