import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      super.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });

    super.initialize();
  }

  connect() {
    $('.select2').selectpicker('destroy');
    $('.select2').select2({
      placeholder: 'Search Part and Edit',
      escapeMarkup: markup => markup,
      templateResult: data =>
        $('<div class="select-option"></div>').html(data.text),
      templateSelection: data =>
        $('<div class="select-option"></div>').html(data.text)
    });

    $('.select2').off('change');

    $('.select2').on('select2:select', e => {
      const contents = $('.select2 option:selected').text();
      const link = contents.substring(
        contents.search('manage/') + 'manage/'.length,
        contents.search('/edit">') + '/edit'.length
      );
      window.location.href = link;
    });
    super.connect();
  }
}
