import disableSubmitButtons from '../utils/disableSubmitButtons';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    this.spinner_html =
      '<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>';
    this.title = this.element.getAttribute('data-spinner-title');

    this.element.form.addEventListener('submit', () => {
      disableSubmitButtons(this.element);
    });
  }

  showSpinner() {
    this.element.innerHTML = this.spinner_html + this.title;
  }
}
