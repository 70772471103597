const getContrastRatio = color => {
  const hex = color.slice(1);

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const ratio = (r * 299 + g * 587 + b * 114) / 1000;

  return ratio;
};

export default getContrastRatio;
