import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import SimpleBar from 'simplebar';

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex(element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
    let reflexes = parseInt(document.body.getAttribute('data-reflexes'));
    reflexes++;
    document.body.setAttribute('data-reflexes', reflexes);
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    // show success message etc...
  }

  reflexError(element, reflex, error, reflexId) {
    // show error message etc...
  }

  afterReflex(element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    this.decrementReflexesCount();
    this.refreshSimpleBar();
  }

  decrementReflexesCount() {
    let reflexes = parseInt(document.body.getAttribute('data-reflexes'));
    reflexes--;
    document.body.setAttribute('data-reflexes', reflexes);
  }

  forceShowLoadingMessage() {
    document.body
      .getElementsByClassName('loading-alert')[0]
      .classList.remove('d-none');
  }

  forceHideLoadingMessage() {
    document.body
      .getElementsByClassName('loading-alert')[0]
      .classList.add('d-none');
  }

  getCSRFtoken() {
    const meta = [].find.call(
      document.head.getElementsByTagName('meta'),
      ({ name }) => name === 'csrf-token'
    );

    const token = meta?.content;

    return token;
  }

  refreshSimpleBar() {
    SimpleBar.instances
      .get(document.querySelector('[data-simplebar]'))
      .unMount();
    const simpleBar = new SimpleBar(
      document.getElementById('left-side-menu-container')
    );
    simpleBar.recalculate();
  }
}
