import $ from 'jquery';
import Sortable from 'sortablejs';
import ColorPicker from 'vanilla-picker';
import Cropper from 'cropperjs';
import interact from 'interactjs';

import ApplicationController from './application_controller';
import { cropAdImageSize, cropSlideImageSize } from '../utils/sliders';
import {
  adCreatorAdapter,
  adCreatorHints,
  any,
  ApplyToOthersModal,
  filter,
  fromEntries,
  getChildNode,
  getContrastRatio,
  getHintArea,
  uuid as getId,
  getSelectionCaretAndLine,
  getSlideOrders,
  HINT_STEPS,
  map,
  range,
  setAttributes,
  slideCreatorAdapter,
  sliderCreatorHints,
  slideSettings,
  TEMPLATE_BACKGROUND_COLOR,
  TEMPLATE_IMAGE,
  toSlideObject
} from '../utils';

import 'cropperjs/dist/cropper.css';

const TEMPLATE_ITEMS_CONFIG = {
  group: { name: 'shared', pull: 'clone', put: false },
  animation: 150,
  draggable: '.item-wrapper',
  sort: false,
  forceFallback: true
};

const SELECTED_ITEMS_CONFIG = {
  group: 'shared',
  animation: 150,
  swapThreshold: 0.2,
  draggable: '.item-wrapper',
  filter: '.remove',
  forceFallback: true
};

const PRODUCT_IMAGE_WIDTH = 864;
const PRODUCT_IMAGE_HEIGHT = 1123;

const removeAttributes = attributes => element =>
  attributes.forEach(key => element.removeAttribute(key));

export default class extends ApplicationController {
  static targets = [
    'adsLayout',
    'adsOrder',
    'adsTemplates',
    'background',
    'buttonText',
    'colorInput',
    'companyGroupId',
    'companyGroupSelectWrapper',
    'configurator',
    'layoutHintTrigger',
    'preview',
    'previewHeader',
    'previewHintTrigger',
    'selectedHintTrigger',
    'selectedItem',
    'selectedItems',
    'slideScrollSpeed',
    'sliderDestination',
    'slidesCreator',
    'slidesOrder',
    'slidesTemplates',
    'spacingInput',
    'spinnerButton',
    'templateItems',
    'templatesHintTrigger',
    'textContainer',
    'triggerButton'
  ];

  #initialItems = {};
  #items = {};
  #containerSettings = {};
  #triggerButton;
  #backgroundSettingName;
  #previousBackgroundImage;
  #currentBackgroundImage = {};
  #previousBackgroundColor;

  connect() {
    super.connect();
    this.initCreator();
    this.initApplyOrCopyModal();

    $('body').on('change', '#filters-selector', () => {
      const data = $('#filters-selector').select2('data');
      const tags = data.filter(({ selected }) => selected).map(({ id }) => id);
      this.stimulate('ImagesLibraryReflex#filter_images', { tags });
      this.decrementReflexesCount();
    });

    $('body').on('select2:selecting', '#filters-selector', event => {
      if (event.params.args.data.id === 'show_all') {
        event.preventDefault();
        $('#filters-selector')
          .val(null)
          .trigger('change')
          .trigger('select2:close');
        this.stimulate('ImagesLibraryReflex#filter_images', { tags: [] });
        this.decrementReflexesCount();
      }
    });
  }

  disconnect() {
    $('#applySlidesToOthersModal, #copyAdContainerModal').off('shown.bs.modal');
    $('body').off('change', '#filters-selector');
    $('body').off('select2:selecting', '#filters-selector');
    interact('.product-image-container').unset();
  }

  showSpinner() {
    this.spinner_html =
      '<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>';
    this.title = this.spinnerButtonTarget.getAttribute('data-spinner-title');

    this.spinnerButtonTarget.setAttribute('disabled', 'disabled');

    if (this.title != null) {
      this.spinnerButtonTarget.innerHTML = this.spinner_html + this.title;
    } else {
      this.spinnerButtonTarget.innerHTML = this.spinner_html;
    }
  }

  toggleSlidesTemplates({ target }) {
    if ($(this.slidesTemplatesTarget).is(':visible')) {
      $(this.slidesTemplatesTarget).hide();
      $(this.slidesOrderTarget).hide();
      target.classList = 'dripicons-chevron-down arrow';
    } else {
      $(this.slidesTemplatesTarget).show();
      $(this.slidesOrderTarget).show();
      target.classList = 'dripicons-chevron-up arrow';
    }
  }

  toggleAdsLayout({ target }) {
    if ($(this.adsLayoutTarget).is(':visible')) {
      $(this.adsLayoutTarget).hide();
      target.classList = 'dripicons-chevron-down arrow';
    } else {
      $(this.adsLayoutTarget).show();
      target.classList = 'dripicons-chevron-up arrow';
    }
  }

  toggleAdsTemplates({ target }) {
    if ($(this.adsTemplatesTarget).is(':visible')) {
      $(this.adsTemplatesTarget).hide();
      $(this.adsOrderTarget).hide();
      target.classList = 'dripicons-chevron-down arrow';
    } else {
      $(this.adsTemplatesTarget).show();

      if (this.#containerSettings.columns > 0) {
        $(this.adsOrderTarget).show();
      }

      target.classList = 'dripicons-chevron-up arrow';
    }
  }

  initApplyOrCopyModal() {
    $('#applySlidesToOthersModal').on('shown.bs.modal', event => {
      new ApplyToOthersModal(this.data.get('id'));
    });
  }

  initItemsState() {
    const itemStateEntries = this.selectedItemTargets
      .map(toSlideObject)
      .map(item => [item.uuid, item]);

    this.#initialItems = fromEntries(itemStateEntries);
    this.#items = { ...this.#initialItems };
  }

  initContainerSettingsState() {
    const inch = Number(this.adsLayoutTarget.getAttribute('data-inch'));
    const columns = Number(this.adsLayoutTarget.getAttribute('data-columns'));
    const spacing = this.adsLayoutTarget.getAttribute('data-spacing');

    this.templateItemsTarget.className = `template-items inch-${inch} columns-2`;
    this.selectedItemsTarget.className = `selected-items inch-${inch} columns-${columns}`;
    this.previewTarget.className = `preview inch-${inch} columns-${columns}`;

    if (columns === 0) {
      $(this.adsOrderTarget).hide();
      this.spacingInputTarget.disabled = true;
    }

    if (columns === 1) {
      this.spacingInputTarget.disabled = true;
    }

    this.#containerSettings = {
      inch,
      columns,
      spacing: spacing === 'true' ? true : false
    };
  }

  initCreator() {
    this.initItemsState();

    this.isAdCreator = Boolean(document.querySelector('.ads-builder'));

    if (this.slidesCreatorTarget.dataset.isShopPage === 'true') {
      [
        ...document.querySelectorAll('.input-wrapper.button-hidden')
      ].map(button => button.classList.remove('button-hidden'));
    }

    if (this.isAdCreator) {
      this.initContainerSettingsState();
      this.layoutHintTriggerTarget.style = 'display: inline-block';
    }

    this.hints = document.querySelector('[data-controller*="hints"]');
    this.creator = this.isAdCreator
      ? 'ads_tour_finished'
      : 'sliders_tour_finished';

    this.setHints();

    if (this.hasHintsCreatorIntro()) {
      const triggerTarget = this.isAdCreator
        ? this.layoutHintTriggerTarget
        : this.templatesHintTriggerTarget;

      triggerTarget.click();

      localStorage.removeItem('hints-selected-items-intro');
    }

    this.templatesHintTriggerTarget.style = 'display: inline-block';

    if (this.hasSelectedItemTarget) {
      this.selectedHintTriggerTarget.style = 'display: inline-block';
    }

    this.addDropZone();

    Sortable.create(this.templateItemsTarget, TEMPLATE_ITEMS_CONFIG);
    Sortable.create(this.selectedItemsTarget, {
      ...SELECTED_ITEMS_CONFIG,
      onFilter: event => this.removeItem(event),
      onAdd: ({ item: itemWrapper }) => {
        const item = getChildNode(itemWrapper)('item');
        const uuid = getId();

        setAttributes({
          ['data-slides-creator-target']: 'selectedItem',
          ['data-uuid']: uuid,
          ['data-action']: 'click->slides-creator#selectItem'
        })(item);

        const handler = getChildNode(itemWrapper)('handler');

        setAttributes(this.getStepHintArea(HINT_STEPS.handler))(handler);

        this.#items = {
          ...this.#items,
          [uuid]: { ...toSlideObject(item), uuid }
        };

        this.removeDropZone();

        if (
          !localStorage.getItem('hints-selected-items-intro') &&
          this.hasHintsCreatorIntro()
        ) {
          this.selectedHintTriggerTarget.click();
          localStorage.setItem('hints-selected-items-intro', 'true');
        }
      },
      onSort: () => {
        this.updateOrder();
      }
    });

    interact('.product-image-container')
      .on('down', event => {
        const {
          target: {
            parentElement: { parentElement: productImagesNode }
          },
          currentTarget,
          currentTarget: {
            dataset: { containerId },
            parentElement: { parentElement: itemNode }
          }
        } = event;

        const {
          dataset: { uuid }
        } = itemNode;
        if (productImagesNode.dataset.name === 'productImages') {
          const item = this.#items[uuid];
          const productImages = { ...item.productImages };

          const zIndex = Object.values(productImages).length;

          const productImage = productImages[containerId];

          productImage.zIndex = zIndex;

          const productImageContainer = Array.from(
            productImagesNode.children
          ).find(({ dataset }) => dataset.containerId === containerId);

          Object.assign(productImageContainer.style, { zIndex });
          Object.assign(productImageContainer.dataset, { zIndex });

          Array.from(Object.values(productImages))
            .sort((a, b) => parseInt(a.zIndex) - parseInt(b.zIndex))
            .forEach(({ containerId }, zIndex) => {
              this.#items[uuid].productImages[containerId].zIndex = zIndex;

              const productImageContainer = Array.from(
                productImagesNode.children
              ).find(({ dataset }) => dataset.containerId === containerId);

              productImageContainer.classList.remove('active');
              Object.assign(productImageContainer.style, { zIndex });
              Object.assign(productImageContainer.dataset, { zIndex });
              this.changeProductImageZIndex({ uuid, containerId });
            });

          currentTarget.classList.add('active');
        }

        event.preventDefault();
      })
      .on('doubletap', event => {
        const { target } = event;
        if ($(target).hasClass('product-image')) {
          const {
            target: { parentElement: productImageContainer },
            currentTarget: {
              dataset: { containerId },
              parentElement: { parentElement: itemNode }
            }
          } = event;

          const {
            dataset: { uuid }
          } = itemNode;

          const item = this.#items[uuid];
          const productImage = item.productImages[containerId];

          const { inch } = this.#containerSettings;
          const sliderHeight = 600;

          const height = this.isAdCreator ? inch * 96 : sliderHeight;

          const { positionX } = productImage;

          if (productImage.scale * PRODUCT_IMAGE_HEIGHT > height) {
            const scale = height / PRODUCT_IMAGE_HEIGHT;

            productImage.scale = scale;

            const width = PRODUCT_IMAGE_WIDTH * scale;
            productImage.positionY = 0;

            Object.assign(productImageContainer.style, {
              width: `${width}px`,
              height: `${height}px`,
              transform: `translate(${positionX}px, ${0}px)`
            });
          } else {
            const positionY =
              (height - productImage.scale * PRODUCT_IMAGE_HEIGHT) / 2;

            productImage.positionY = positionY;

            Object.assign(productImageContainer.style, {
              transform: `translate(${positionX}px, ${positionY}px)`
            });
          }

          this.changeProductImageSize({ uuid, containerId });
        }
      });

    interact('.product-image-container.active')
      .draggable({
        listeners: {
          move: ({ dx, dy, target: productImageContainer }) => {
            const {
              dataset: { containerId },
              parentElement: { parentElement: itemNode }
            } = productImageContainer;

            const {
              dataset: { uuid }
            } = itemNode;

            const item = this.#items[uuid];
            const { productImages } = item;
            const productImage = productImages[containerId];

            const x = productImage.positionX + dx;
            const y = productImage.positionY + dy;

            const positionX = x;
            const positionY = y;

            productImage.positionX = positionX;
            productImage.positionY = positionY;

            Object.assign(productImageContainer.style, {
              transform: `translate(${positionX}px, ${positionY}px)`
            });
            this.changeProductImagePosition({ uuid, containerId });
          }
        }
      })
      .resizable({
        edges: { top: true, left: true, bottom: true, right: true },
        listeners: {
          move: ({
            deltaRect: { left, top },
            rect: { width, height },
            target: productImageContainer
          }) => {
            const {
              dataset: { containerId },
              parentElement: { parentElement: itemNode }
            } = productImageContainer;

            const {
              dataset: { uuid }
            } = itemNode;

            const item = this.#items[uuid];
            const { productImages } = item;
            const productImage = productImages[containerId];

            const x = productImage.positionX + left;
            const y = productImage.positionY + top;

            const scale = width / PRODUCT_IMAGE_WIDTH;
            productImage.scale = scale;

            const positionX = x;
            const positionY = y;

            productImage.positionX = positionX;
            productImage.positionY = positionY;

            Object.assign(productImageContainer.style, {
              width: `${width}px`,
              height: `${height}px`,
              transform: `translate(${positionX}px, ${positionY}px)`
            });

            this.changeProductImageSize({ uuid, containerId });
          }
        },
        modifiers: [
          interact.modifiers.aspectRatio({
            ratio: 'preserve'
          })
        ]
      });

    this.disableSaveButton();
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    if (!this.isAdCreator) {
      this.initCreator();
    }
  }

  updateCompanyGroup() {
    // TODO: if there was some changes in slides settings display message and block
    // update links
    $('#sliderLinks a').map((index, item) => {
      const split = $(item).attr('href').split('?');
      $(item).attr(
        'href',
        split[0] + '?' + this.updateCompanyGroupSearchUrl(split[1])
      );
    });
    // update current url
    const currentUrl = window.location;
    window.history.pushState(
      '',
      '',
      currentUrl.protocol +
        '//' +
        currentUrl.host +
        currentUrl.pathname +
        '?' +
        this.updateCompanyGroupSearchUrl(currentUrl.search)
    );
    this.stimulate('SlidesCreator#update_company_group');
  }

  updateCompanyGroupSearchUrl(searchPath) {
    const searchUrl = new URLSearchParams(searchPath);
    searchUrl.set('company_group_id', this.companyGroupIdTarget.value);
    return searchUrl.toString();
  }

  updateOrder() {
    const ids = this.selectedItemTargets.map(selectedItem =>
      selectedItem.getAttribute('data-uuid')
    );
    const orders = getSlideOrders(ids);

    this.#items = map(item => ({ ...item, order: orders[item.uuid] }))(
      this.#items
    );
  }

  addPreviewPlaceholder() {
    const { firstElementChild } = this.previewTarget;

    const placeholder = document.createElement('div');
    placeholder.className = 'placeholder';

    this.previewHintTriggerTarget.style = 'display: none';
    this.previewTarget.replaceChild(placeholder, firstElementChild);
  }

  createDropZone() {
    const dropZone = document.createElement('div');
    setAttributes({ class: 'drop-zone', 'data-name': 'dropZone' })(dropZone);

    return dropZone;
  }

  disableTemplateItems() {
    let disabled = false;

    if (this.hasSelectedItemTarget) {
      disabled =
        this.selectedItemTargets.length === this.#containerSettings.columns;
    }

    this.templateItemsTarget.setAttribute('data-disabled', disabled);
  }

  disableSaveButton() {
    const numberOfSelectedItems = this.selectedItemTargets.length;
    const { columns } = this.#containerSettings;

    if (this.isAdCreator) {
      this.spinnerButtonTarget.disabled = !(
        numberOfSelectedItems !== 0 && columns === numberOfSelectedItems
      );
    } else {
      this.spinnerButtonTarget.disabled = numberOfSelectedItems === 0;
    }
  }

  addDropZone() {
    const { children } = this.selectedItemsTarget;
    const numberOfSelectedItems = children.length;

    if (this.isAdCreator) {
      const { columns } = this.#containerSettings;

      const numberOfDropZones = columns - numberOfSelectedItems;

      if (numberOfDropZones > 0) {
        range(numberOfDropZones).forEach(_ => {
          const dropZone = this.createDropZone();

          this.selectedItemsTarget.appendChild(dropZone);
        });
      } else {
        range(Math.abs(numberOfDropZones)).forEach(_ => {
          this.removeDropZone();
        });
      }

      if (numberOfSelectedItems === 0) {
        this.previewHeaderTarget.style = 'display: none';
      }
      this.disableTemplateItems();
    } else if (numberOfSelectedItems === 0) {
      const dropZone = this.createDropZone();
      this.selectedItemsTarget.appendChild(dropZone);
      this.previewHeaderTarget.style = 'display: none';
    }

    if (!this.hasSelectedItemTarget) {
      this.selectedHintTriggerTarget.style = 'display: none';
    }

    this.disableSaveButton();
  }

  removeDropZone() {
    const dropZone = getChildNode(this.selectedItemsTarget)('dropZone');

    if (dropZone) {
      this.selectedHintTriggerTarget.style = 'display: inline-block';
      this.selectedItemsTarget.removeChild(dropZone);
    }

    this.disableTemplateItems();
    this.disableSaveButton();
  }

  removeItem({ target, item: itemWrapper, from }) {
    if (Sortable.utils.is(target, '.remove')) {
      const item = getChildNode(itemWrapper)('item');
      const uuid = item.getAttribute('data-uuid');

      delete this.#items[uuid];

      from.removeChild(itemWrapper);

      const { firstElementChild } = this.previewTarget;

      if (firstElementChild.getAttribute('data-uuid') === uuid) {
        const [selectedItem] = this.selectedItemTargets;

        selectedItem
          ? this.selectItem({ target: selectedItem })
          : this.addPreviewPlaceholder();
      }

      this.updateOrder();
      this.addDropZone();
    }
  }

  setHints(position = 'left') {
    const { inch, columns } = this.#containerSettings;
    const creatorHints = this.isAdCreator ? adCreatorHints : sliderCreatorHints;
    const isIntro = this.hasHintsCreatorIntro();
    const {
      dataset: { isShopPage }
    } = this.slidesCreatorTarget;

    setAttributes({
      ['data-hints-options-value']: JSON.stringify(
        creatorHints({
          isIntro,
          inch,
          position,
          columns,
          isShopPage: isShopPage === 'true'
        })
      ),
      ['data-hints-context-value']: this.creator
    })(this.hints);
  }

  getStepHintArea(...args) {
    return getHintArea(this.isAdCreator)(...args);
  }

  hasHintsCreatorIntro = () => !(localStorage.getItem(this.creator) === 'true');

  selectItem({ target: item }) {
    this.previewHintTriggerTarget.style = 'display: inline-block';
    this.previewHeaderTarget.style = 'display: flex';

    removeAttributes(['data-action'])(this.slidesCreatorTarget);

    const { parentElement: itemWrapper } = item;
    const { firstElementChild: previewItem } = this.previewTarget;

    const prevSelectedItem = this.selectedItemTargets.find(
      ({ dataset: { uuid } }) => uuid === previewItem.getAttribute('data-uuid')
    );

    if (prevSelectedItem) {
      const { parentElement: prevSelectedItemWrapper } = prevSelectedItem;

      prevSelectedItemWrapper.classList.remove('active');
    }

    itemWrapper.classList.add('active');

    const selectedItem = item.cloneNode(true);
    const uuid = selectedItem.getAttribute('data-uuid');
    const { textContainerPosition } = this.#items[uuid];

    removeAttributes(['data-action', 'data-slides-creator-target'])(
      selectedItem
    );

    const background = getChildNode(selectedItem)('background');
    this.#backgroundSettingName = background.getAttribute('data-type');

    const textContainer = getChildNode(selectedItem)('textContainer');
    const texts = getChildNode(textContainer)('texts');

    const primaryText = getChildNode(texts)('primaryText');
    const secondaryText = getChildNode(texts)('secondaryText');
    const tertiaryText = getChildNode(texts)('tertiaryText');
    const buttonText = getChildNode(texts)('buttonText');

    const triggerButtonBackground = document.createElement('div');
    const triggerButtonTextContainer = document.createElement('div');
    const triggerButtonLink = document.createElement('div');
    const triggerButtonProductImages = document.createElement('div');

    const productImages = getChildNode(selectedItem)('productImages');

    triggerButtonBackground.innerHTML =
      '<div class="button-element" data-name="triggerButton" data-slides-creator-target="triggerButton" data-action="click->slides-creator#showConfigurator"><i class="dripicons-gear"></i></div>';
    triggerButtonTextContainer.innerHTML =
      '<div class="button-element" data-name="triggerButton" data-slides-creator-target="triggerButton" data-action="click->slides-creator#showConfigurator"><i class="dripicons-gear"></i></div>';
    triggerButtonLink.innerHTML =
      '<div class="button-element" data-name="triggerButton" data-slides-creator-target="triggerButton" data-action="click->slides-creator#showConfigurator"><i class="link"></i></div>';
    triggerButtonProductImages.innerHTML =
      '<div class="button-element" data-name="triggerButton" data-slides-creator-target="triggerButton" data-action="click->slides-creator#showConfigurator"><i class="shirt"></i></div>';

    const attributes = {
      class: 'trigger-button'
    };

    setAttributes(
      this.getStepHintArea(HINT_STEPS.triggerButtonBackground, attributes)
    )(triggerButtonBackground);
    setAttributes(
      this.getStepHintArea(HINT_STEPS.triggerButtonTextContainer, attributes)
    )(triggerButtonTextContainer);
    setAttributes(this.getStepHintArea(HINT_STEPS.texts))(texts);
    setAttributes(
      this.getStepHintArea(HINT_STEPS.triggerButtonLink, attributes)
    )(triggerButtonLink);

    setAttributes({ ['data-slides-creator-target']: 'background' })(background);
    background.appendChild(triggerButtonBackground);

    setAttributes({ ['data-slides-creator-target']: 'textContainer' })(
      textContainer
    );
    textContainer.appendChild(triggerButtonTextContainer);

    setAttributes({
      ['data-slides-creator-target']: 'productImages'
    })(productImages);
    setAttributes(attributes)(triggerButtonProductImages);
    productImages.appendChild(triggerButtonProductImages);

    const textAttributes = { contenteditable: true };

    setAttributes(textAttributes)(primaryText.firstElementChild);
    setAttributes(textAttributes)(secondaryText.firstElementChild);
    setAttributes(textAttributes)(tertiaryText.firstElementChild);
    setAttributes(textAttributes)(buttonText.firstElementChild);

    setAttributes({ ['data-slides-creator-target']: 'buttonText' })(buttonText);
    buttonText.appendChild(triggerButtonLink);

    this.previewTarget.replaceChild(selectedItem, previewItem);

    this.setHints(textContainerPosition);

    if (this.hasHintsCreatorIntro()) {
      this.previewHintTriggerTarget.click();
    }
  }

  changeProductImagePosition({ uuid, containerId }) {
    const selectedItem = this.selectedItemTargets.find(
      item => item.getAttribute('data-uuid') === uuid
    );
    const { positionX, positionY } = this.#items[uuid].productImages[
      containerId
    ];

    const productImagesNode = getChildNode(selectedItem)('productImages');

    const productImageContainer = Array.from(productImagesNode.children).find(
      ({ dataset }) => dataset.containerId === containerId
    );

    Object.assign(productImageContainer.style, {
      transform: `translate(${positionX}px, ${positionY}px)`
    });
  }

  changeProductImageSize({ uuid, containerId }) {
    const selectedItem = this.selectedItemTargets.find(
      item => item.getAttribute('data-uuid') === uuid
    );
    const { scale, positionX, positionY } = this.#items[uuid].productImages[
      containerId
    ];
    const productImagesNode = getChildNode(selectedItem)('productImages');
    const productImageContainer = Array.from(productImagesNode.children).find(
      ({ dataset }) => dataset.containerId === containerId
    );

    const width = PRODUCT_IMAGE_WIDTH * scale;
    const height = PRODUCT_IMAGE_HEIGHT * scale;

    Object.assign(productImageContainer.style, {
      width: `${width}px`,
      height: `${height}px`,
      transform: `translate(${positionX}px, ${positionY}px)`
    });
  }

  changeProductImageZIndex({ uuid, containerId }) {
    const selectedItem = this.selectedItemTargets.find(
      item => item.getAttribute('data-uuid') === uuid
    );
    const { zIndex } = this.#items[uuid].productImages[containerId];
    const productImagesNode = getChildNode(selectedItem)('productImages');
    const productImageContainer = Array.from(productImagesNode.children).find(
      ({ dataset }) => dataset.containerId === containerId
    );

    Object.assign(productImageContainer.style, { zIndex });
    Object.assign(productImageContainer.dataset, { zIndex });
  }

  changeText(event) {
    const { target } = event;
    const { innerText, parentElement } = target;

    const name = parentElement.getAttribute('data-name');
    const height = parseInt(parentElement.getAttribute('data-height'));
    const {
      parentElement: {
        parentElement: { parentElement: previewItem }
      }
    } = parentElement;

    const uuid = previewItem.getAttribute('data-uuid');

    const selectedItem = this.selectedItemTargets.find(
      item => item.getAttribute('data-uuid') === uuid
    );
    const textContainer = getChildNode(selectedItem)('textContainer');
    const texts = getChildNode(textContainer)('texts');
    const element = getChildNode(texts)(name);
    const inputText = getChildNode(element)('input');

    const isLimitReached = target.scrollHeight > height;

    if (isLimitReached) {
      const text = parentElement.dataset[name];
      target.innerText = text;

      if (window.getSelection && target.firstChild?.length === text.length) {
        window.getSelection().collapse(target.firstChild, text.length);
      }
    } else {
      let text = innerText;

      if (!/\n\n$/.test(innerText)) {
        text = innerText.replace(/\n+$/gm, '');
      }

      const { caret, line } = getSelectionCaretAndLine(target);

      element.dataset[name] = text;
      parentElement.dataset[name] = text;

      inputText.innerText = text;
      target.innerText = text;

      inputText.innerHTML = text.replace(/\n/gm, '<br>');
      target.innerHTML = text.replace(/\n/gm, '<br>');

      const textNodes = [...target.childNodes].filter(
        node => node.nodeType === Node.TEXT_NODE
      );

      const previousTextLength = textNodes
        .slice(0, line - 1)
        .reduce((acc, node) => acc + node.length, 0);

      const range = document.createRange();

      if (textNodes[line - 1]) {
        range.setStart(
          textNodes[line - 1],
          line === 1 ? caret : Math.abs(caret - previousTextLength - line)
        );
      }

      range.collapse(true);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      this.#items = {
        ...this.#items,
        [uuid]: { ...this.#items[uuid], [name]: text }
      };
    }
  }

  setConfiguratorPosition() {
    const targetRect = this.#triggerButton.getBoundingClientRect();
    const controllerRect = this.element.getBoundingClientRect();
    const point = controllerRect.width / 1.5;

    const offset =
      targetRect.left - controllerRect.left < point
        ? 46
        : -this.configuratorTarget.clientWidth - 8;

    const left = targetRect.left - controllerRect.left + offset;
    const top = targetRect.top - controllerRect.top;

    this.configuratorTarget.style = `top:${top}px;left:${left}px`;
  }

  updateCurrentImageData() {
    if (this.cropperObject) {
      const { firstElementChild: previewItem } = this.previewTarget;
      const uuid = previewItem.getAttribute('data-uuid');

      this.#currentBackgroundImage[uuid] = {
        ...this.#currentBackgroundImage[uuid],
        canvasData: this.cropperObject.getCanvasData(),
        cropBoxData: this.cropperObject.getCropBoxData(),
        data: this.cropperObject.getData()
      };
    }
  }

  showConfigurator({ target }) {
    const { firstElementChild: previewItem } = this.previewTarget;
    const uuid = previewItem.getAttribute('data-uuid');
    const item = this.#items[uuid];

    const {
      parentElement: { parentElement: editableElement }
    } = target;
    const elementName = editableElement.getAttribute('data-name');

    const isTriggerButton =
      target.getAttribute('data-name') === 'triggerButton';

    if (isTriggerButton) {
      this.closeConfigurator();

      this.#triggerButton = target;

      const type = this.isAdCreator ? 'ad' : 'slide';

      const elementsSettings = slideSettings({
        backgroundSettingName: this.#backgroundSettingName,
        containerSettings: this.#containerSettings,
        item,
        type
      });

      const { title, settings } = elementsSettings[elementName];

      const backdrop = document.createElement('div');

      setAttributes({
        ['data-action']: 'click->slides-creator#closeConfigurator',
        id: 'backdrop'
      })(backdrop);

      this.element.appendChild(backdrop);

      const configurator = document.createElement('div');

      setAttributes({
        class: 'configurator',
        ['data-name']: 'configurator',
        ['data-slides-creator-target']: 'configurator'
      })(configurator);

      configurator.innerHTML = `
        <div class="header">
          <div class="header-body">
            <span>${title}</span>
            <i class="mdi mdi-close" data-action="click->slides-creator#closeConfigurator"></i>
          </div>
        </div>
        <div class="body">
          ${settings.map(this.renderSetting).join('')}
        </div>
    `;

      this.element.appendChild(configurator);
      this.setConfiguratorPosition();

      const colorPicker = this.element.querySelector('.color-picker-icon');

      if (colorPicker) {
        const picker = new ColorPicker({
          parent: colorPicker,
          alpha: false,
          editor: false,
          popup: 'bottom',
          color: this.colorInputTarget.value,
          onChange: ({ hex }) => {
            this.updateSettings({
              target: {
                value: hex,
                pattern: this.colorInputTarget.pattern,
                name: this.colorInputTarget.name
              }
            });
          },
          onOpen: () => {
            picker.setColor(this.colorInputTarget.value);
          }
        });
      }

      this.slidesCreatorTarget.style = 'margin-bottom: 200px;';

      const cropperImage = document.getElementById('cropper-image');
      const selectedItem = this.selectedItemTargets.find(
        item => item.getAttribute('data-uuid') === uuid
      );

      const selectedItemBackground = getChildNode(selectedItem)('background');
      const selectedItemBackgroundImageSrc = getChildNode(
        selectedItemBackground
      )('backgroundImage')?.src;
      const previewItemBackground = getChildNode(previewItem)('background');

      this.cropperObject = null;

      if (cropperImage === null) return;

      const shouldBeCropperImageLoaded =
        any(this.#currentBackgroundImage) &&
        this.#backgroundSettingName === 'image' &&
        selectedItemBackgroundImageSrc !== TEMPLATE_IMAGE;

      if (shouldBeCropperImageLoaded) {
        cropperImage.src = this.#currentBackgroundImage[uuid].src;
      }

      cropperImage.onload = () => {
        if (this.cropperObject) {
          this.cropperObject.destroy();
        }

        const { width, height } = this.isAdCreator
          ? cropAdImageSize(
              this.#containerSettings.columns,
              this.#containerSettings.inch,
              item.templateType
            )
          : cropSlideImageSize[item.templateType];

        const cropperSection = document.querySelector('.cropper-section');
        cropperSection.style = 'display: block';

        this.cropperObject = new Cropper(cropperImage, {
          aspectRatio: width / height,
          cropBoxMovable: false,
          cropBoxResizable: false,
          dragMode: 'move',
          minCanvasHeight: height,
          minCanvasWidth: width,
          minContainerHeight: this.isAdCreator ? 145 : 181,
          minContainerWidth: 434,
          minCropBoxHeight: height,
          minCropBoxWidth: width,
          responsive: false,
          rotatable: false,
          toggleDragModeOnDblclick: false,
          zoomOnWheel: false,
          zoomOnTouch: false,
          cropend: () => {
            this.updateCurrentImageData();
          },
          ready: () => {
            if (this.#currentBackgroundImage[uuid]?.data) {
              const {
                canvasData,
                data,
                cropBoxData
              } = this.#currentBackgroundImage[uuid];

              this.cropperObject.setData(data);
              this.cropperObject.setCanvasData(canvasData);
              this.cropperObject.setCropBoxData(cropBoxData);
            }
          },
          crop: () => {
            const canvas = this.cropperObject.getCroppedCanvas();

            const backgroundImage = new Image();
            const backgroundImageAttributes = {
              ['data-name']: 'backgroundImage',
              ['class']: 'background-image',
              src: canvas.toDataURL()
            };
            const backgroundAttributes = { ['data-type']: 'image' };

            setAttributes(backgroundImageAttributes)(backgroundImage);
            previewItemBackground.replaceChild(
              backgroundImage.cloneNode(true),
              previewItemBackground.firstElementChild
            );

            selectedItemBackground.replaceChild(
              backgroundImage.cloneNode(true),
              selectedItemBackground.firstElementChild
            );

            setAttributes(backgroundAttributes)(previewItemBackground);
            setAttributes(backgroundAttributes)(selectedItemBackground);

            this.#items = {
              ...this.#items,
              [uuid]: {
                ...this.#items[uuid],
                backgroundImage: canvas.toDataURL(),
                backgroundColor: ''
              }
            };
          }
        });
      };
    }
  }

  rerenderConfigurator() {
    this.showConfigurator({ target: this.#triggerButton });
  }

  closeConfigurator() {
    if (this.hasConfiguratorTarget) {
      this.element.removeChild(this.configuratorTarget);

      const backdrop = document.getElementById('backdrop');
      this.element.removeChild(backdrop);
    }

    this.slidesCreatorTarget.style = '';
  }

  renderSetting({ type, inputs, title, className }) {
    const [
      {
        accept,
        action,
        checked,
        label = '',
        name,
        pattern,
        placeholder,
        type: inputType,
        value = ''
      }
    ] = inputs;

    switch (type) {
      case 1: {
        return `<div class="section-${type}">
                  <div class="section-title">${title}</div>
                    <div class="section-body">
                    ${inputs
                      .map(
                        ({
                          action,
                          checked,
                          label = '',
                          name,
                          type: inputType,
                          value
                        }) =>
                          inputType === 'radio'
                            ? `<label>
                              <input
                                ${checked ? 'checked' : ''}
                                data-action="${action}"
                                name="${name}"
                                type="${inputType}"
                                value="${value}"
                                />
                              ${label}
                            </label>`
                            : `
                             <input
                             ${checked ? 'checked' : ''}
                              type="${inputType}"
                              name="${name}"
                              id="${name}"
                              value="${value}"
                              data-switch="success"
                              data-action="${action}" />
                            <label
                              data-on-label="Yes"
                              data-off-label="No"
                              for="${name}">
                            </label>
                            `
                      )
                      .join('')}
                    </div>
                  </div>`;
      }
      case 2: {
        return `<div class="section-${type} ${className || ''}">
                  ${title ? `<div class="section-title">${title}</div>` : ''}
                  <label class="${label ? 'label' : ''}">
                    <input
                      ${checked ? 'checked' : ''}
                      data-action="${action}"
                      ${placeholder ? `placeholder="${placeholder}"` : ''}
                      name="${name}"
                      ${pattern ? `pattern="${pattern}"` : ''}
                      type="${inputType}"
                      value="${value}"
                    />
                    ${label}
                  </label>
                </div>`;
      }
      case 3: {
        return `<div class="section-${type}">
                  <div class="section-title">${title}</div>
                  <label>
                    <input
                      type="text"
                      pattern="${pattern}"
                      name="${name}"
                      data-action="${action}"
                      placeholder="${placeholder}"
                      data-slides-creator-target="colorInput"
                      value="${value}"
                      />
                      <i class="color-picker-icon"></i>
                  </label>
                </div>`;
      }
      case 4: {
        return `<div class="section-${type}">
                  ${title ? `<div class="section-title">${title}</div>` : ''}
                  <label class="upload-area">
                    <span>${placeholder}</span>
                    <input
                      accept="${accept}"
                      data-action="${action}"
                      name="${name}"
                      type="${inputType}"
                    />
                  </label>
                  <div class="cropper-section" style="display: none;">
                    <div class="action-buttons">
                      <span data-action="click->slides-creator#zoomIn">+</span>
                      <span data-action="click->slides-creator#zoomOut">-</span>
                    </div>
                    <img id="cropper-image" class="slide-image" />
                  </div>
                </div>`;
      }
      case 5: {
        const newLibraryComponent = document.createElement('div');
        newLibraryComponent.classList.add('background-images-library');

        const [libraryGrid] = document.getElementsByClassName(
          'images-library-grid'
        );
        const newLibraryGrid = libraryGrid.cloneNode(true);
        newLibraryGrid.dataset.action = 'click->slides-creator#updateSettings';

        const categoriesSelectorWrapper = document.createElement('div');
        categoriesSelectorWrapper.dataset.controller = 'select2-images-library';
        categoriesSelectorWrapper.classList.add('categories');

        const [categoriesSelector] = document.getElementsByClassName(
          'image-categories-selector'
        );
        const options = categoriesSelector.querySelectorAll('option');

        const newCategoriesSelector = document.createElement('select');
        newCategoriesSelector.id = 'filters-selector';
        newCategoriesSelector.classList.add('select2');
        newCategoriesSelector.multiple = true;

        for (const option of options) {
          newCategoriesSelector.appendChild(option.cloneNode(true));
        }

        newLibraryComponent.appendChild(categoriesSelectorWrapper);

        categoriesSelectorWrapper.appendChild(newCategoriesSelector);

        newLibraryComponent.appendChild(newLibraryGrid);

        const content = `<div class="section-${type}"><div class="section-body"></div></div>`;

        const cropperImage = document.createElement('div');
        cropperImage.className = 'cropper-section';
        cropperImage.style = 'display: none';
        cropperImage.innerHTML =
          '<div class="action-buttons"><span data-action="click->slides-creator#zoomIn">+</span><span data-action="click->slides-creator#zoomOut">-</span></div><img id="cropper-image" class="ad-image" />';

        const domParser = new DOMParser();
        const container = domParser.parseFromString(content, 'text/html');
        const body = container.body.querySelector('.section-body');
        body.appendChild(newLibraryComponent);
        body.appendChild(cropperImage);

        const { firstChild } = container.body;
        const firstChildString = new XMLSerializer().serializeToString(
          firstChild
        );

        return firstChildString;
      }
      case 6: {
        return `<div class="section-${type}">
                  <div class="section-title">${title}</div>
                  <label>
                    Super Category
                    <select id="supercategory-select"></select>
                  </label>
                  <label style="display:none">
                    Category
                    <select id="category-select"></select>
                  </label>
                  <label style="display:none">
                    Sub Category
                    <select id="subcategory-select"></select>
                  </label>
                  <label style="display:none">
                    Product
                    <select id="product-select"></select>
                  </label>
                  <div style="display:none" class="product-image-area"></div>
                  <button style="display:none" id="add-product-btn" class="btn btn-primary btn-md" data-action="click->slides-creator#addProductImage" disabled="true">Add Product</button>
                </div>`;
      }
      default:
        return '';
    }
  }

  zoomIn() {
    this.cropperObject.zoom(0.1);

    this.updateCurrentImageData();
  }

  zoomOut() {
    this.cropperObject.zoom(-0.1);

    this.updateCurrentImageData();
  }

  addProductImage({ target }) {
    const { previousElementSibling: selectedProductImage } = target;
    const {
      dataset: { id: productId, image: src }
    } = selectedProductImage;

    const { firstElementChild: previewItem } = this.previewTarget;
    const uuid = previewItem.getAttribute('data-uuid');

    const item = this.#items[uuid];
    const zIndex = Object.values(item.productImages).length;

    const containerId = getId();

    const productImage = {
      positionX: 0,
      positionY: 0,
      scale: 0.25,
      productId,
      src,
      zIndex,
      containerId,
      _destroy: false
    };

    const productImages = {
      ...item.productImages,
      [containerId]: productImage
    };

    const selectedItem = Array.from(this.selectedItemTargets).find(
      ({ dataset }) => dataset.uuid === uuid
    );

    const previewProductImagesNode = getChildNode(previewItem)('productImages');
    const productImagesNode = getChildNode(selectedItem)('productImages');

    const defaultHeight = 279;
    const sliderHeight = 600;

    const { inch, columns } = this.#containerSettings;
    const height = this.isAdCreator ? inch * 96 : sliderHeight;

    if (defaultHeight > height) {
      productImage.scale = height / PRODUCT_IMAGE_HEIGHT;
    }

    if (item.textContainerPosition === 'left') {
      const width = this.isAdCreator ? 1440 / columns : 1440;
      productImage.positionX = width - productImage.scale * PRODUCT_IMAGE_WIDTH;
    }

    const productImageContainer = document.createElement('div');
    productImageContainer.className = 'product-image-container';
    Object.assign(productImageContainer.style, {
      width: `${productImage.scale * PRODUCT_IMAGE_WIDTH}px`,
      height: `${productImage.scale * PRODUCT_IMAGE_HEIGHT}px`,
      transform: `translate(${productImage.positionX}px, ${productImage.positionY}px)`,
      zIndex: productImage.zIndex
    });
    Object.assign(productImageContainer.dataset, productImage);
    productImageContainer.innerHTML = `
        <div class="product-image-actions">
        <i class="drag-icon" draggable="false"></i>
        <i class="bin-icon" draggable="false" data-action="click->slides-creator#removeProductImage"></i>
      </div>
      <img class="product-image" src="${src}" draggable="false" />
    `;

    previewProductImagesNode.appendChild(productImageContainer);

    selectedItem.replaceChild(
      previewProductImagesNode.cloneNode(true),
      productImagesNode
    );

    this.#items = {
      ...this.#items,
      [uuid]: { ...item, productImages }
    };

    this.closeConfigurator();
  }

  removeProductImage(event) {
    const {
      target: {
        parentElement: { parentElement: productImageContainer }
      }
    } = event;
    const {
      dataset: { containerId: id },
      parentElement: { parentElement: previewItem }
    } = productImageContainer;

    const {
      dataset: { uuid }
    } = previewItem;

    this.#items[uuid].productImages[id]._destroy = true;

    const previewProductImagesNode = getChildNode(previewItem)('productImages');

    previewProductImagesNode.removeChild(productImageContainer);

    const selectedItem = Array.from(this.selectedItemTargets).find(
      ({ dataset }) => dataset.uuid === uuid
    );

    const productImagesNode = getChildNode(selectedItem)('productImages');

    selectedItem.replaceChild(
      previewProductImagesNode.cloneNode(true),
      productImagesNode
    );
  }

  changeBackgroundSettings({ target }) {
    const { firstElementChild: previewItem } = this.previewTarget;
    const uuid = previewItem.getAttribute('data-uuid');
    const selectedItem = this.selectedItemTargets.find(
      item => item.getAttribute('data-uuid') === uuid
    );

    const selectedItemBackground = getChildNode(selectedItem)('background');
    const previewItemBackground = getChildNode(previewItem)('background');

    const selectedItemTextContainer = getChildNode(selectedItem)(
      'textContainer'
    );
    const previewItemTextContainer = getChildNode(previewItem)('textContainer');

    this.#backgroundSettingName = target.value;
    let backgroundElement;

    const selectedElementImageSrc =
      selectedItemBackground.firstElementChild.src;
    if (selectedElementImageSrc) {
      this.#previousBackgroundImage = selectedElementImageSrc;
    }

    const selectedElementBackgroundColor = selectedItemBackground.firstElementChild.getAttribute(
      'data-background-color'
    );
    if (selectedElementBackgroundColor) {
      this.#previousBackgroundColor = selectedElementBackgroundColor;
    }

    if (target.value === 'color') {
      backgroundElement = document.createElement('div');
      setAttributes({
        class: 'background-color',
        ['data-name']: 'backgroundColor',
        ['data-background-color']:
          this.#previousBackgroundColor || TEMPLATE_BACKGROUND_COLOR,
        style: `background-color: ${
          this.#previousBackgroundColor || TEMPLATE_BACKGROUND_COLOR
        };`
      })(backgroundElement);
    }

    if (target.value === 'image') {
      backgroundElement = new Image();
      setAttributes({
        ['data-name']: 'backgroundImage',
        ['class']: 'background-image',
        src: this.#previousBackgroundImage || TEMPLATE_IMAGE
      })(backgroundElement);
    }

    if (target.value === 'imageFromLibrary') {
      backgroundElement = new Image();
      setAttributes({
        ['data-name']: 'backgroundImageFromLibrary',
        ['class']: 'background-image',
        src: this.#previousBackgroundImage || TEMPLATE_IMAGE
      })(backgroundElement);
    }

    selectedItemBackground.replaceChild(
      backgroundElement.cloneNode(true),
      selectedItemBackground.firstElementChild
    );

    previewItemBackground.replaceChild(
      backgroundElement.cloneNode(true),
      previewItemBackground.firstElementChild
    );

    const textContainerTheme = 'dark';

    const attributes = {
      class: `text-container ${textContainerTheme}`,
      ['data-text-container-theme']: textContainerTheme
    };

    setAttributes(attributes)(selectedItemTextContainer);
    setAttributes(attributes)(previewItemTextContainer);

    setAttributes({ ['data-type']: target.value })(selectedItemBackground);
    setAttributes({ ['data-type']: target.value })(previewItemBackground);

    const backgroundImage =
      target.value === 'image'
        ? this.#previousBackgroundImage || TEMPLATE_IMAGE
        : undefined;

    const backgroundImageFromLibrary =
      target.value === 'imageFromLibrary'
        ? this.#previousBackgroundImage || TEMPLATE_IMAGE
        : undefined;

    const backgroundColor =
      target.value === 'color'
        ? this.#previousBackgroundColor || TEMPLATE_BACKGROUND_COLOR
        : '';

    this.#items = {
      ...this.#items,
      [uuid]: {
        ...this.#items[uuid],
        textContainerTheme,
        backgroundImage: backgroundImage || backgroundImageFromLibrary,
        backgroundColor
      }
    };

    this.rerenderConfigurator();
  }

  showAlert(event) {
    $('.warning-modal').modal('show');
    $('#ads-container-warning-yes').on('click', () => {
      const columns = Number(event.target.value);
      const numberOfSelectedItems = this.selectedItemTargets.length;

      const selectedItemsToRemove = this.selectedItemTargets.slice(
        columns,
        numberOfSelectedItems
      );

      selectedItemsToRemove.forEach(({ parentElement }) => {
        this.removeItem({
          target: getChildNode(parentElement)('remove'),
          item: parentElement,
          from: this.selectedItemsTarget
        });
      });

      event.target.checked = true;

      $('.warning-modal').modal('hide');
    });
  }

  updateContainerSettings(event) {
    const { target } = event;

    switch (target.name) {
      case 'columns':
        {
          const columns = Number(target.value);
          const isIntro = this.hasHintsCreatorIntro();

          $(this.adsOrderTarget).show();

          if (this.#containerSettings.columns === 0 && isIntro) {
            setAttributes({ ['data-hints-step-value']: 1 })(this.hints);
            setAttributes({ ['data-step']: 1 })(this.layoutHintTriggerTarget);
            this.layoutHintTriggerTarget.click();
            setAttributes({ ['data-step']: 0 })(this.layoutHintTriggerTarget);
          }

          const isSpacingDisabled = columns < 2;

          if (isSpacingDisabled) {
            this.spacingInputTarget.checked = false;
            this.#containerSettings['spacing'] = false;
          }

          this.spacingInputTarget.disabled = isSpacingDisabled;

          if (this.hasSelectedItemTarget) {
            const numberOfSelectedItems = this.selectedItemTargets.length;

            if (
              columns < numberOfSelectedItems &&
              this.#containerSettings.columns !== 0
            ) {
              event.preventDefault();
              this.showAlert(event);
            }
          }

          const { inch } = this.#containerSettings;
          this.selectedItemsTarget.className = `selected-items inch-${inch} columns-${columns}`;
          this.previewTarget.className = `preview inch-${inch} columns-${columns}`;

          this.#containerSettings[target.name] = columns;
          this.addDropZone();
          this.setHints();
        }
        break;
      case 'ads_container[spacing_between_ads]':
        {
          this.#containerSettings['spacing'] = target.checked;
        }
        break;
      case 'ads_container[ads_container_height]':
        {
          const { columns } = this.#containerSettings;
          this.#containerSettings['inch'] = target.value;

          this.templateItemsTarget.className = `template-items inch-${target.value} columns-2`;
          this.selectedItemsTarget.className = `selected-items inch-${target.value} columns-${columns}`;
          this.previewTarget.className = `preview inch-${target.value} columns-${columns}`;
          this.setHints();
        }
        break;
      default:
    }
  }

  async updateSettings({ target }) {
    const { firstElementChild: previewItem } = this.previewTarget;
    const uuid = previewItem.getAttribute('data-uuid');

    const selectedItem = this.selectedItemTargets.find(
      item => item.getAttribute('data-uuid') === uuid
    );

    const selectedItemTextContainer = getChildNode(selectedItem)(
      'textContainer'
    );
    const selectedItemTexts = getChildNode(selectedItemTextContainer)('texts');
    const previewItemTextContainer = getChildNode(previewItem)('textContainer');
    const previewItemTexts = getChildNode(previewItemTextContainer)('texts');

    const selectedItemBackground = getChildNode(selectedItem)('background');
    const previewItemBackground = getChildNode(previewItem)('background');

    const selectedItemBackgroundColor = getChildNode(selectedItemBackground)(
      'backgroundColor'
    );
    const previewItemBackgroundColor = getChildNode(previewItemBackground)(
      'backgroundColor'
    );

    const selectedItemButtonText = getChildNode(selectedItemTexts)(
      'buttonText'
    );
    const previewItemButtonText = getChildNode(previewItemTexts)('buttonText');

    const targetName = target.name || target.dataset.name;

    switch (targetName) {
      case 'textContainerTheme':
        {
          const className = `text-container ${target.value}`;
          const attribute = ['data-text-container-theme', target.value];

          selectedItemTextContainer.className = className;
          selectedItemTextContainer.setAttribute(...attribute);

          previewItemTextContainer.className = `${className} active`;
          previewItemTextContainer.setAttribute(...attribute);

          const changes = { [target.name]: target.value };

          this.#items = {
            ...this.#items,
            [uuid]: { ...this.#items[uuid], ...changes }
          };
        }
        break;
      case 'textContainerPosition':
        {
          const { templateType } = this.#items[uuid];
          const { value: position } = target;
          const className = `item template-${templateType} ${position}`;
          const attribute = ['data-text-container-position', position];

          selectedItem.className = className;
          selectedItemTextContainer.setAttribute(...attribute);

          previewItem.className = className;
          previewItemTextContainer.setAttribute(...attribute);

          const item = { ...this.#items[uuid], [target.name]: position };
          this.#items = { ...this.#items, [uuid]: item };

          this.setHints(position);
          this.setConfiguratorPosition();
        }
        break;
      case 'textContainerVisibility':
        {
          const attribute = ['data-text-container-visibility', target.checked];

          selectedItemBackground.setAttribute(...attribute);
          previewItemBackground.setAttribute(...attribute);

          selectedItemTextContainer.setAttribute(...attribute);
          previewItemTextContainer.setAttribute(...attribute);

          this.#items = {
            ...this.#items,
            [uuid]: { ...this.#items[uuid], [target.name]: target.checked }
          };
        }
        break;
      case 'buttonLink':
        {
          let { value, name, pattern } = target;
          const regex = new RegExp(pattern, 'i');
          const isValidLink = regex.test(value);

          target.className = isValidLink ? '' : 'error';

          const [, errorMessage] = target.parentElement.children;

          if (errorMessage) {
            target.parentElement.removeChild(errorMessage);
          }

          if (!isValidLink) {
            const errorMessage = document.createElement('span');
            errorMessage.innerText = 'Invalid URL';
            errorMessage.className = 'error';

            target.after(errorMessage);
            value = '';
          }

          const attribute = ['data-button-link', value];
          selectedItemButtonText.setAttribute(...attribute);
          previewItemButtonText.setAttribute(...attribute);

          this.#items = {
            ...this.#items,
            [uuid]: { ...this.#items[uuid], [name]: value }
          };
        }
        break;
      case 'buttonLinkTarget':
        {
          const attribute = ['data-button-link-target', target.value];

          selectedItemButtonText.setAttribute(...attribute);
          previewItemButtonText.setAttribute(...attribute);

          this.#items = {
            ...this.#items,
            [uuid]: { ...this.#items[uuid], [target.name]: target.checked }
          };
        }
        break;
      case 'buttonLinkHidden':
        {
          const attribute = ['data-button-link-hidden', target.value];

          selectedItemButtonText.setAttribute(...attribute);
          previewItemButtonText.setAttribute(...attribute);

          this.#items = {
            ...this.#items,
            [uuid]: { ...this.#items[uuid], [target.name]: target.checked }
          };
        }
        break;
      case 'textContainerBackgroundColor':
        {
          const regex = new RegExp(target.pattern, 'i');
          const isValidHexColor = regex.test(target.value);

          if (isValidHexColor) {
            const contrastRatio = getContrastRatio(target.value);
            const ratio = 186;

            const textContainerTheme =
              contrastRatio >= ratio ? 'light' : 'dark';
            const className = `text-container ${textContainerTheme}`;
            const style = `background-color: ${target.value};`;

            const attributes = {
              ['data-text-container-background-color']: target.value,
              ['data-text-container-theme']: textContainerTheme,
              style
            };

            this.colorInputTarget.value = target.value;

            setAttributes({ style })(selectedItemBackground);
            setAttributes({ style })(previewItemBackground);

            selectedItemTextContainer.className = className;
            setAttributes(attributes)(selectedItemTextContainer);

            previewItemTextContainer.className = className;
            setAttributes(attributes)(previewItemTextContainer);

            this.#items = {
              ...this.#items,
              [uuid]: {
                ...this.#items[uuid],
                [target.name]: target.value,
                textContainerTheme
              }
            };
          }
        }
        break;
      case 'backgroundColor':
        {
          const regex = new RegExp(target.pattern, 'i');
          const isValidHexColor = regex.test(target.value);

          if (isValidHexColor) {
            const contrastRatio = getContrastRatio(target.value);
            const ratio = 186;

            const textContainerTheme =
              contrastRatio >= ratio ? 'light' : 'dark';
            const className = `text-container ${textContainerTheme}`;

            const backgroundAttributes = {
              ['data-background-color']: target.value,
              style: `background-color: ${target.value};`
            };

            const textContainerAttributes = {
              ['data-text-container-theme']: textContainerTheme
            };

            this.colorInputTarget.value = target.value;

            setAttributes(backgroundAttributes)(selectedItemBackgroundColor);
            setAttributes(backgroundAttributes)(previewItemBackgroundColor);

            selectedItemTextContainer.className = className;
            setAttributes(textContainerAttributes)(selectedItemTextContainer);

            previewItemTextContainer.className = className;
            setAttributes(textContainerAttributes)(previewItemTextContainer);

            this.#items = {
              ...this.#items,
              [uuid]: {
                ...this.#items[uuid],
                [target.name]: target.value,
                textContainerTheme
              }
            };
          }
        }
        break;
      case 'backgroundImage':
        {
          const [file] = target.files;

          const reader = new FileReader();

          reader.onload = () => {
            const cropperImage = document.getElementById('cropper-image');
            cropperImage.src = reader.result;

            const { firstElementChild: previewItem } = this.previewTarget;
            const uuid = previewItem.getAttribute('data-uuid');

            this.#currentBackgroundImage[uuid] = {
              src: reader.result
            };
          };

          reader.readAsDataURL(file);
        }
        break;
      case 'backgroundImageFromLibrary':
        {
          this.unselectAllImagesFromLibrary();
          target.classList.add('selected');

          const img = target.querySelector('img');
          const { src: url } = img;

          const toDataURL = url =>
            fetch(url, { cache: 'no-cache' })
              .then(response => response.blob())
              .then(
                blob =>
                  new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  })
              );

          const imageSrc = await toDataURL(url);

          const cropperImage = document.getElementById('cropper-image');
          cropperImage.src = imageSrc;
        }
        break;
      default:
    }
  }

  updateDestination(event) {
    event.preventDefault();

    const destination = event.target.getAttribute('data-destination');

    // change tabs active classes
    $('#sliderLinks li').removeClass('active');
    event.target.parentElement.classList.add('active');
    // set destination
    this.sliderDestinationTarget.value = destination;

    if (destination === 'corporate_page_1') {
      // show all sections
      $('.destination-dependent-section').removeClass('d-none');
      // hide company group section
      this.companyGroupSelectWrapperTarget.classList.add('d-none');
      // hide groups empty state
      $('.empty-groups-state').addClass('d-none');
    } else {
      if (this.companyGroupIdTarget.options.length === 0) {
        // hide all sections
        $('.destination-dependent-section').addClass('d-none');
        // show groups empty state
        $('.empty-groups-state').removeClass('d-none');
      } else {
        // show company group section
        this.companyGroupSelectWrapperTarget.classList.remove('d-none');
      }
    }
  }

  async save(event) {
    event.preventDefault();
    this.showSpinner();

    try {
      const destination = this.hasSliderDestinationTarget
        ? this.sliderDestinationTarget.value
        : undefined;

      const companyGroupId =
        this.hasCompanyGroupIdTarget && destination !== 'corporate_page_1'
          ? this.companyGroupIdTarget.value
          : undefined;

      const slideScrollSpeed = this.hasSlideScrollSpeedTarget
        ? this.slideScrollSpeedTarget.value
        : undefined;

      const authenticityToken = this.getCSRFtoken();
      const removedItems = filter(({ uuid }) => !this.#items[uuid])(
        this.#initialItems
      );
      const attributes = {
        ...map(slide => ({ ...slide, _destroy: true }))(removedItems),
        ...this.#items
      };

      const props = {
        attributes,
        authenticityToken,
        companyGroupId,
        destination
      };

      const data = this.isAdCreator
        ? adCreatorAdapter({
            ...props,
            containerSettings: this.#containerSettings
          })
        : slideCreatorAdapter({ ...props, slideScrollSpeed });

      // This is a quick, temporary way, to change keys for ads creator for the feature to work
      // TODO: remove and refactor adapters
      if (this.isAdCreator) {
        for (let i = 0; i < data.ads_container.ads_attributes.length; i++) {
          data.ads_container.ads_attributes[i]['ad_template_id'] =
            data.ads_container.ads_attributes[i]['slide_template_id'];
          data.ads_container.ads_attributes[i]['ad_template_type'] =
            data.ads_container.ads_attributes[i]['slide_template_type'];

          delete data.ads_container.ads_attributes[i]['slide_template_id'];
          delete data.ads_container.ads_attributes[i]['slide_template_type'];
        }
      }

      const endpoint = this.isAdCreator
        ? this.spinnerButtonTarget.getAttribute('data-form-endpoint')
        : `/style_builder/sliders/upsert?company_group_id=${companyGroupId}&slider_destination=${destination}`;

      const fetchMethod = this.isAdCreator
        ? this.spinnerButtonTarget.getAttribute('data-form-action')
        : 'POST';

      const response = await fetch(endpoint, {
        method: fetchMethod,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Response not OK: ${response.status}!`);
      }

      if (this.isAdCreator) {
        if (fetchMethod === 'POST') {
          window.location = this.spinnerButtonTarget.getAttribute(
            'data-form-redirect'
          );
        } else {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  }

  unselectAllImagesFromLibrary() {
    const imageContainers = document.getElementsByClassName('image-container');
    for (const container of imageContainers) {
      container.classList.remove('selected');
    }
  }
}
