/* eslint-disable */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['completed', 'output', 'outputdate', 'allbox'];

  connect() {
    this.setAllCheckboxesUnselect();
  }

  toggleMain() {
    if (this.allboxTarget.checked) {
      this.setAllCheckboxes();
    } else {
      this.setAllCheckboxesUnselect();
    }
  }

  toggle(event) {
    this.orders_id = this.completedTargets
      .filter(completed => completed.checked)
      .map(completed => completed.value);
    this.set_params();
  }

  set_params() {
    this.passed_ids;
  }

  get passed_ids() {
    return this.orders_id;
  }

  checkAll() {
    if (this.allboxTarget.checked) {
      this.setAllCheckboxes(true);
    } else {
      this.setAllCheckboxes(false);
    }
  }

  setAllCheckboxes(checked) {
    this.allboxTargets.checked = true;
    this.completedTargets.forEach((el) => {
      const completedTargets = el;

      if (!completedTargets.disabled) {
        completedTargets.checked = checked;
      }
    });
    this.orders_id = this.completedTargets
      .filter(completed => completed.checked)
      .map(completed => completed.value);
    this.set_params();
  }

  setAllCheckboxesUnselect(checked) {
    this.completedTargets.forEach((el) => {
      const completedTargets = el;
      completedTargets.checked = false;
    });
    this.orders_id = this.completedTargets
      .filter(completed => completed.checked)
      .map(completed => completed.value);
    this.set_params();
  }

  showModal() {
    this.outputTarget.value = this.passed_ids;
    $('#updateDateModal').modal('show');
  }

  update(event) {
    this.inputDateVal = this.outputdateTarget.value;
    this.inputIdsVal = this.outputTarget.value;

    if (this.inputIdsVal === null || this.inputIdsVal === 'undefined') {
      alert('Check if the order ids are selected');
    } else {
      if (this.inputDateVal === null || this.inputDateVal === '') {
        alert('Please select a date');
      } else {
        this.updateOrder();
      }
    }
  }

  updateOrder() {
    $.ajax({
      url: '/manage/open_orders_reports/back_orders/update_order',
      data: {
        ids: this.outputTarget.value,
        new_due_date: this.outputdateTarget.value
      },
      method: 'POST',
      success: function (res) {

      }
    });
  }
}
