import { fabric } from 'fabric';

import {
  ApplySettingsModal,
  getQuadraticBezierXYatT,
  loadImage,
  uploadFile
} from '../utils';
import ApplicationController from './application_controller';

const LOGO_CONTAINER_BORDER_COLOR = '#D6467A';
const PRODUCT_AREA_BORDER_COLOR = 'blue';

const CONTAINER_WIDTH = 554;
const CONTAINER_HEIGHT = 725;

export default class extends ApplicationController {
  static targets = [
    'areaLinesToggler',
    'areaPointsInput',
    'backgroundCanvas',
    'backgroundImage',
    'containerPercentHeightInput',
    'containerPercentHeightRange',
    'fileInput',
    'logoAspectDegreesInput',
    'logoAspectDegreesRange',
    'logoCanvas',
    'logoHeightInput',
    'logoParameters',
    'logoPercentWrapInput',
    'logoPercentWrapRange',
    'logoPositionXInput',
    'logoPositionYInput',
    'logoRotationDegreesInput',
    'logoRotationDegreesRange',
    'logoSkewXDegreesInput',
    'logoSkewXDegreesRange',
    'logoSkewYDegreesInput',
    'logoSkewYDegreesRange',
    'logoTiltDegreesInput',
    'logoTiltDegreesRange',
    'logoWidthInput',
    'logoWrapAxisInput',
    'logoWrapShapeInput',
    'previewLogos',
    'productWidthInput'
  ];

  index = 0;

  async initialize() {
    this.sanitizeIndex();
    await this.initializePreview();

    document.addEventListener('stimulus-reflex:after', async ({ target }) => {
      try {
        this.parentAfterReflex(target);

        this.sanitizeIndex();

        await this.initializePreview();
      } catch (error) {
        console.error(error);
      }
    });

    this.fileInputTarget.addEventListener('change', async event => {
      event.stopPropagation();
      try {
        if (this.hasFileInputTarget) {
          const {
            dataset: { directUploadUrl }
          } = this.fileInputTarget;

          this.forceShowLoadingMessage();
          const upload = uploadFile(directUploadUrl);

          this.id = parseInt(this.element.getAttribute('data-items'), 10) - 1;

          const promises = Array.from(this.fileInputTarget.files).map(upload);
          const blobs = await Promise.all(promises);

          const images = blobs.map(blob => ({
            ...blob,
            logo_parameter_id: ++this.id
          }));

          this.stimulate('MasterBlankReflex#add_images', { images });

          this.fileInputTarget.value = null;
        }
      } catch (error) {
        console.error(error);
      }
    });

    this.initializeTabs();
  }

  connect() {
    super.connect();

    this.tabsLink = $('.tabs .master-blank-image-container');
    this.tabsContent = $('.forms > div');
    this.initApplyModal();
  }

  sanitizeIndex() {
    const isTabExists = document.getElementById(
      `image-thumbnail-${this.index}`
    );

    if (!isTabExists) {
      this.lastOpenedTab = null;
      this.index = 0;
    }
  }

  initApplyModal() {
    $('#applySettingsModal').on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);
      const logoParameterId = target.data('logo-parameter-id');
      const logoLocationId = target.data('logo-location-id');

      $('#applySettingsModal #apply_settings_logo_parameter_id').val(
        logoParameterId
      );
      $('#applySettingsModal #apply_settings_logo_location_id').val(
        logoLocationId
      );

      // default selected items are color sets. We do it after modal is shown
      const select_field = $('#selectedMasterBlank');
      select_field
        .val(JSON.parse(select_field.attr('data-master-blanks-in-color-set')))
        .trigger('change');

      new ApplySettingsModal();
    });
  }

  async handleReplaceImage(event) {
    event.stopPropagation();

    const { target } = event;
    const { index } = target.dataset;

    const { directUploadUrl } = target.dataset;

    this.forceShowLoadingMessage();
    const upload = uploadFile(directUploadUrl);

    const blob = await upload(target.files[0]);

    const image = {
      ...blob,
      index: index
    };

    this.stimulate('MasterBlankReflex#update_image', JSON.stringify(image));
  }

  initializeTabs() {
    $(this.element).on('click', '.tabs .tab-link', event => {
      event.preventDefault();

      const link = $(event.currentTarget);
      this.openTab(link);
    });
  }

  openTab(linkObject) {
    const hrefId = linkObject.attr('href');
    this.tabsLink.removeClass('outline-strong');
    linkObject.parent().addClass('outline-strong');

    if (hrefId.charAt(0) === '#') {
      this.tabsContent.addClass('d-none');
      $(hrefId).removeClass('d-none');
      this.lastOpenedTab = linkObject;
    }
  }

  afterReflex() {
    this.tabsLink = $('.tabs .master-blank-image-container');
    this.tabsContent = $('.forms > div');

    this.sanitizeIndex();

    if (this.lastOpenedTab) {
      this.openTab(this.lastOpenedTab);
    }
  }

  parentAfterReflex(target) {
    if (target.length === 0) return;

    const invalidElement = $(target)
      .find('.is-invalid')
      .closest("[id^='image-tab-']");

    if (invalidElement.length > 0) {
      const tabWithInvalidField = $(target).find(
        `[href='#${invalidElement.attr('id')}']`
      );

      if (tabWithInvalidField.length > 0) {
        this.openTab(tabWithInvalidField);
      }
    }
  }

  async initializePreview() {
    const isImagesTab =
      document.getElementById('logo-parameters-controller')?.offsetHeight > 0;

    if (!this.hasBackgroundImageTarget || !isImagesTab) return;

    if (this.backgroundCanvas) {
      const objects = this.backgroundCanvas.getObjects();

      if (objects.length === 0) {
        return;
      }

      this.backgroundCanvas.dispose();
    }

    this.logoParametersTarget.classList = 'logo-parameters';

    this.backgroundCanvas = await this.createBackgroundCanvas();
    this.container = this.createContainer();

    this.backgroundCanvas.add(this.container);

    this.handleSelectInitialPreviewLogo();
    this.drawProductArea();

    this.backgroundCanvas.renderAll();

    this.backgroundCanvas.setActiveObject(this.container);
  }

  async createBackgroundCanvas() {
    const backgroundImage = await loadImage(
      this.backgroundImageTargets[this.index].src
    );

    this.image = new fabric.Image(backgroundImage);

    this.ratio = 1;

    if (
      this.image.width > CONTAINER_WIDTH ||
      this.image.height > CONTAINER_HEIGHT
    ) {
      if (
        this.image.width / CONTAINER_WIDTH >
        this.image.height / CONTAINER_HEIGHT
      ) {
        this.ratio = CONTAINER_WIDTH / this.image.width;
      } else {
        this.ratio = CONTAINER_HEIGHT / this.image.height;
      }
    }

    this.maxContainerPercentHeight = Math.round(
      (this.ratio * this.image.height * 100) / CONTAINER_HEIGHT
    );

    if (this.containerPercentHeight === 0) {
      this.containerPercentHeightInput.value = this.maxContainerPercentHeight;
      this.containerPercentHeightRange.value = this.maxContainerPercentHeight;
    }

    this.containerPercentHeightInput.max = this.maxContainerPercentHeight;
    this.containerPercentHeightRange.max = this.maxContainerPercentHeight;

    const ratio = this.ratio * (this.containerPercentHeight / 100);

    const backgroundCanvas = new fabric.Canvas(this.backgroundCanvasTarget, {
      width: this.image.width * ratio,
      height: this.image.height * ratio
    });

    backgroundCanvas.setBackgroundImage(
      this.image,
      backgroundCanvas.renderAll.bind(backgroundCanvas),
      { scaleX: ratio, scaleY: ratio }
    );

    this.areaPoints = this.productAreaPoints.map(({ x, y }) => {
      const areaPoint = this.createPoint(x, y);
      backgroundCanvas.add(areaPoint);
      return areaPoint;
    });

    backgroundCanvas.on('mouse:dblclick', options => {
      if (options.target && options.target.type === 'circle') {
        const index = this.areaPoints.findIndex(
          areaPoint => areaPoint === options.target
        );

        backgroundCanvas.remove(this.areaPoints[index]);
        this.areaPoints.splice(index, 1);

        const value = JSON.parse(this.areaPointsInput.value);
        value.splice(index, 1);
        this.areaPointsInput.value = JSON.stringify(value);
        this.rerenderLogo();
      } else {
        const { x, y } = backgroundCanvas.getPointer(options.e);
        const areaPoint = this.createPoint(x, y);

        this.areaPoints.push(areaPoint);

        const value = JSON.parse(this.areaPointsInput.value);
        value.push({
          x: +parseFloat(
            (areaPoint.left / this.backgroundImageWidth) * 100
          ).toFixed(2),
          y: +parseFloat(
            (areaPoint.top / this.backgroundImageHeight) * 100
          ).toFixed(2)
        });
        this.areaPointsInput.value = JSON.stringify(value);

        backgroundCanvas.add(areaPoint);
      }

      this.drawProductArea();
    });

    backgroundCanvas.on('selection:created', event => {
      if (event.selected.length > 1) {
        backgroundCanvas.discardActiveObject();
      }
    });

    return backgroundCanvas;
  }

  createPoint(x, y) {
    const point = new fabric.Circle({
      left: x,
      top: y,
      strokeWidth: 2,
      radius: 3,
      fill: this.isAreaVisible ? '#ffffff' : 'transparent',
      stroke: this.isAreaVisible ? 'blue' : 'transparent',
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      selectable: true,
      lockMovementX: false,
      lockMovementY: false
    });

    point.on('moving', this.onMovedProductAreaPoint.bind(this, point));

    return point;
  }

  drawProductArea() {
    if (this.productArea) {
      this.backgroundCanvas.remove(this.productArea);
      this.productArea = null;
    }

    if (this.areaPoints.length > 2) {
      this.productArea = new fabric.Polygon(
        this.areaPoints.map(({ left, top }) => ({ x: left, y: top })),
        {
          fill: this.isAreaVisible ? 'rgba(0,0,255,0.1)' : 'transparent',
          objectCaching: false,
          selectable: false,
          stroke: this.isAreaVisible
            ? PRODUCT_AREA_BORDER_COLOR
            : 'transparent',
          strokeWidth: 1
        }
      );
      this.backgroundCanvas.add(this.productArea);
      this.backgroundCanvas.sendToBack(this.productArea);

      this.rerenderLogo();
    }
  }

  createContainer() {
    const { left, top, width, height } = this;

    const borderColor = this.isAreaVisible
      ? LOGO_CONTAINER_BORDER_COLOR
      : 'transparent';

    const container = new fabric.Rect({
      cornerColor: 'red',
      cornerSize: 4,
      fill: 'transparent',
      hasBorders: false,
      height,
      left,
      originX: 'center',
      originY: 'center',
      stroke: borderColor,
      strokeDashArray: [5, 5],
      top,
      transparentCorners: true,
      width,
      angle: this.rotationDegrees
    });

    ['tl', 'bl', 'tr', 'br', 'ml', 'mb', 'mr', 'mt', 'mtr'].forEach(control =>
      container.setControlVisible(control, false)
    );

    container.set('strokeUniform', true);
    container.on('moving', () => this.onMovedContainer());

    return container;
  }

  createLogoCanvas() {
    this.logoCanvasContext = this.logoCanvasTarget.getContext('2d');

    this.logoCanvasTarget.width = this.selectedLogo.naturalWidth;
    this.logoCanvasTarget.height = this.selectedLogo.naturalHeight;

    this.logoCanvasContext.drawImage(this.selectedLogo, 0, 0);
  }

  rerenderLogo() {
    const { left, top } = this;

    if (this.isVerticalWrapping) {
      this.onWarpVertically();
    } else {
      this.onWarpHorizontally();
    }

    this.onChangeLogoAspectDegrees();
    this.onChangeLogoTiltDegrees();

    if (this.logo) {
      this.backgroundCanvas.remove(this.logo);
    }

    this.logo = new fabric.Image(this.logoCanvasTarget, {
      selectable: false,
      originX: 'center',
      originY: 'center',
      left,
      top,
      centeredScaling: true
    });

    this.logo.set('skewX', this.logoSkewXDegrees);
    this.logo.set('skewY', this.logoSkewYDegrees);

    this.onScaleLogo();

    this.logo.set('angle', this.rotationDegrees);
    this.logo.setCoords();

    if (this.areaPoints && this.areaPoints.length > 2) {
      const clipPath = new fabric.Polygon(
        this.areaPoints.map(({ left, top }) => ({ x: left, y: top })),
        {
          originX: 'left',
          originY: 'top',
          objectCaching: false,
          absolutePositioned: true
        }
      );

      this.logo.clipPath = clipPath;
      this.logo.dirty = true;
    }

    this.backgroundCanvas.add(this.logo);
    this.backgroundCanvas.requestRenderAll();
  }

  onMovedProductAreaPoint(areaPoint) {
    const index = this.areaPoints.findIndex(point => point === areaPoint);

    const x = (areaPoint.left / this.backgroundImageWidth) * 100;
    const y = (areaPoint.top / this.backgroundImageHeight) * 100;

    const value = JSON.parse(this.areaPointsInput.value);
    value[index] = {
      x: +parseFloat(x).toFixed(2),
      y: +parseFloat(y).toFixed(2)
    };
    this.areaPointsInput.value = JSON.stringify(value);
    this.drawProductArea();
  }

  onMovedContainer() {
    const { left, top, width, height } = this.container;
    this.logoPositionXInput.value = +parseFloat(
      ((left - width / 2) * 100) / this.backgroundImageWidth
    ).toFixed(2);
    this.logoPositionYInput.value = +parseFloat(
      ((top - height / 2) * 100) / this.backgroundImageHeight
    ).toFixed(2);

    this.rerenderLogo();
  }

  onMovedProductArea() {
    this.productAreaPoints.forEach(({ x, y }, index) => {
      const areaPoint = this.areaPoints[index];
      areaPoint.set('left', x).set('top', y).setCoords();
    });

    this.drawProductArea();
  }

  handleChangePositionX() {
    this.container.set('left', this.left).setCoords();

    this.rerenderLogo();

    this.backgroundCanvas.requestRenderAll();
  }

  handleChangePositionY() {
    this.container.set('top', this.top).setCoords();

    this.rerenderLogo();

    this.backgroundCanvas.requestRenderAll();
  }

  handleChangeLogoWidth() {
    this.container.set('width', this.width).setCoords();
    this.handleChangePositionX();

    this.rerenderLogo();

    this.backgroundCanvas.requestRenderAll();
  }

  handleChangeLogoHeight() {
    this.container.set('height', this.height).setCoords();
    this.handleChangePositionY();

    this.rerenderLogo();

    this.backgroundCanvas.requestRenderAll();
  }

  handleChangeLogoRotationDegrees({ target: { value } }) {
    this.logoRotationDegreesInput.value = value;
    this.logoRotationDegreesRange.value = value;

    this.container.set('angle', this.rotationDegrees).setCoords();

    this.rerenderLogo();

    this.backgroundCanvas.requestRenderAll();
  }

  onChangeLogoAspectDegrees() {
    const logoTemporaryCanvas = document.createElement('canvas');
    const logoTemporaryCanvasContext = logoTemporaryCanvas.getContext('2d');

    const { width: logoWidth } = this.logoCanvasContext.canvas;
    const { height: logoHeight } = this.logoCanvasContext.canvas;

    logoTemporaryCanvas.width = logoWidth;
    logoTemporaryCanvas.height = logoHeight;

    logoTemporaryCanvasContext.clearRect(0, 0, logoWidth, logoHeight);

    const t = parseInt(Math.PI * 360);

    const top = parseInt(logoHeight * (Math.abs(this.aspectDegrees) / t), 10);
    const bottom =
      parseInt(logoHeight * (1 - Math.abs(this.aspectDegrees) / t), 10) - top;

    const { canvas } = this.logoCanvasContext;

    for (let i = 0; i < logoWidth; i++) {
      const sx = this.isRightAspect ? logoWidth - i : i;
      const dx = sx;
      const dy = (top * (logoWidth - i)) / logoWidth;
      const dh = (bottom * (logoWidth - i) + logoHeight * i) / logoWidth;

      logoTemporaryCanvasContext.drawImage(
        canvas,
        sx,
        0,
        1,
        logoHeight,
        dx,
        dy,
        1,
        dh
      );
    }

    const logo = logoTemporaryCanvasContext.getImageData(
      0,
      0,
      this.logoCanvasTarget.width,
      this.logoCanvasTarget.height
    );

    this.logoCanvasContext.putImageData(logo, 0, 0);
  }

  handleChangeLogoAspectDegrees({ target: { value } }) {
    this.logoAspectDegreesInput.value = value;
    this.logoAspectDegreesRange.value = value;

    this.rerenderLogo();
  }

  onChangeLogoTiltDegrees() {
    const logoTemporaryCanvas = document.createElement('canvas');
    const logoTemporaryCanvasContext = logoTemporaryCanvas.getContext('2d');

    const { width: logoWidth } = this.logoCanvasContext.canvas;
    const { height: logoHeight } = this.logoCanvasContext.canvas;

    logoTemporaryCanvas.width = logoWidth;
    logoTemporaryCanvas.height = logoHeight;

    logoTemporaryCanvasContext.clearRect(0, 0, logoWidth, logoHeight);

    const t = parseInt(Math.PI * 360);

    const left = parseInt(logoWidth * (Math.abs(this.tiltDegrees) / t), 10);
    const right =
      parseInt(logoWidth * (1 - Math.abs(this.tiltDegrees) / t), 10) - left;

    const { canvas } = this.logoCanvasContext;

    for (let i = 0; i < logoHeight; i++) {
      const sy = this.isBackTilt ? logoHeight - i : i;
      const dy = sy;
      const dx = (left * (logoHeight - i)) / logoHeight;
      const dw = (right * (logoHeight - i) + logoWidth * i) / logoHeight;

      logoTemporaryCanvasContext.drawImage(
        canvas,
        0,
        sy,
        logoWidth,
        1,
        dx,
        dy,
        dw,
        1
      );
    }

    const logo = logoTemporaryCanvasContext.getImageData(
      0,
      0,
      this.logoCanvasTarget.width,
      this.logoCanvasTarget.height
    );

    this.logoCanvasContext.putImageData(logo, 0, 0);
  }

  handleChangeLogoTiltDegrees({ target: { value } }) {
    this.logoTiltDegreesInput.value = value;
    this.logoTiltDegreesRange.value = value;

    this.rerenderLogo();
  }

  handleChangeLogoSkewXDegrees({ target: { value } }) {
    this.logoSkewXDegreesInput.value = value;
    this.logoSkewXDegreesRange.value = value;

    this.rerenderLogo();
  }

  handleChangeLogoSkewYDegrees({ target: { value } }) {
    this.logoSkewYDegreesInput.value = value;
    this.logoSkewYDegreesRange.value = value;

    this.rerenderLogo();
  }

  handleChangeLogoWrapShape({ target: { value } }) {
    this.logoWrapShapeInput.value = value;

    this.rerenderLogo();
  }

  handleChangeLogoWrapAxis({ target: { value } }) {
    this.logoWrapAxisInput.value = value;

    this.rerenderLogo();
  }

  onWarpHorizontally() {
    const logoTemporaryCanvas = document.createElement('canvas');
    const logoTemporaryCanvasContext = logoTemporaryCanvas.getContext('2d');

    const logoWidth = this.selectedLogo.naturalWidth;
    const logoHeight = this.selectedLogo.naturalHeight;

    const warpXoffset = (this.logoPercentWrap / 100) * logoHeight;

    logoTemporaryCanvas.width = logoWidth + Math.ceil(warpXoffset * 2);
    logoTemporaryCanvas.height = logoHeight;

    const startPoint = { x: 0, y: 0 };
    const controlPoint = {
      x: this.isConcaveWrapping ? warpXoffset : -warpXoffset,
      y: logoHeight / 2
    };
    const endPoint = { x: 0, y: logoHeight };

    const offsetXPoints = [];

    for (let t = 0; t < logoHeight; t++) {
      const xyAtT = getQuadraticBezierXYatT({
        startPoint,
        controlPoint,
        endPoint,
        t: t / logoHeight
      });
      const x = parseInt(xyAtT.x, 10);

      offsetXPoints.push(x);
    }

    logoTemporaryCanvasContext.clearRect(
      0,
      0,
      logoTemporaryCanvas.width,
      logoTemporaryCanvas.height
    );

    for (let y = 0; y < logoHeight; y++) {
      logoTemporaryCanvasContext.drawImage(
        this.selectedLogo,
        0,
        y,
        logoWidth + warpXoffset,
        1,
        warpXoffset + offsetXPoints[y],
        y,
        logoWidth + warpXoffset,
        1
      );
    }

    const centerPointIndex = Math.round(offsetXPoints.length / 2);
    const centerPoint = Math.ceil(Math.abs(offsetXPoints[centerPointIndex]));

    this.logoCanvasTarget.width = logoWidth + centerPoint;
    this.logoCanvasTarget.height = logoHeight;

    const imageData = logoTemporaryCanvasContext.getImageData(
      this.isConcaveWrapping ? centerPoint * 2 : centerPoint,
      0,
      this.logoCanvasTarget.width,
      this.logoCanvasTarget.height
    );

    this.logoCanvasContext.putImageData(imageData, 0, 0);
  }

  onWarpVertically() {
    const logoTemporaryCanvas = document.createElement('canvas');
    const logoTemporaryCanvasContext = logoTemporaryCanvas.getContext('2d');

    const logoWidth = this.selectedLogo.naturalWidth;
    const logoHeight = this.selectedLogo.naturalHeight;

    const warpYoffset = (this.logoPercentWrap / 100) * logoWidth;

    logoTemporaryCanvas.width = logoWidth;
    logoTemporaryCanvas.height = logoHeight + Math.ceil(warpYoffset * 2);

    const startPoint = { x: 0, y: 0 };
    const controlPoint = {
      x: logoWidth / 2,
      y: this.isConcaveWrapping ? warpYoffset : -warpYoffset
    };
    const endPoint = { x: logoWidth, y: 0 };

    const offsetYPoints = [];

    for (let t = 0; t < logoWidth; t++) {
      const xyAtT = getQuadraticBezierXYatT({
        startPoint,
        controlPoint,
        endPoint,
        t: t / logoWidth
      });
      const y = parseInt(xyAtT.y, 10);

      offsetYPoints.push(y);
    }

    logoTemporaryCanvasContext.clearRect(
      0,
      0,
      logoTemporaryCanvas.width,
      logoTemporaryCanvas.height
    );

    for (let x = 0; x < logoWidth; x++) {
      logoTemporaryCanvasContext.drawImage(
        this.selectedLogo,
        x,
        0,
        1,
        logoHeight + warpYoffset,
        x,
        warpYoffset + offsetYPoints[x],
        1,
        logoHeight + warpYoffset
      );
    }

    const centerPointIndex = Math.round(offsetYPoints.length / 2);
    const centerPoint = Math.ceil(Math.abs(offsetYPoints[centerPointIndex]));

    this.logoCanvasTarget.width = logoWidth;
    this.logoCanvasTarget.height = logoHeight + centerPoint;

    const imageData = logoTemporaryCanvasContext.getImageData(
      0,
      this.isConcaveWrapping ? centerPoint * 2 : centerPoint,
      this.logoCanvasTarget.width,
      this.logoCanvasTarget.height
    );

    this.logoCanvasContext.putImageData(imageData, 0, 0);
  }

  handleChangeLogoPercentWrap({ target: { value } }) {
    this.logoPercentWrapInput.value = value;
    this.logoPercentWrapRange.value = value;

    this.rerenderLogo();
  }

  handleChangeProductWidth() {
    this.handleChangeLogoWidth();
    this.handleChangeLogoHeight();
  }

  onScaleLogo() {
    this.logo.set('angle', 0);

    const ratioX = this.logo.width / this.logo.getScaledWidth();
    const ratioY = this.logo.height / this.logo.getScaledHeight();

    this.logo.scaleToWidth(this.width * ratioX);

    if (this.logo.height * this.logo.scaleY > this.height * ratioY) {
      this.logo.scaleToHeight(this.height * ratioY);
    }

    const widthDegree = this.logo.scaleX / 90;
    const heightDegree = this.logo.scaleY / 90;

    const precision = 1000000;
    const subtract = (a, b) =>
      (parseInt(a * precision, 10) - parseInt(b * precision, 10)) / precision;

    const scaleX = Math.abs(
      subtract(this.logo.scaleX, Math.abs(this.aspectDegrees) * widthDegree)
    );
    const scaleY = Math.abs(
      subtract(this.logo.scaleY, Math.abs(this.tiltDegrees) * heightDegree)
    );

    this.logo.set('scaleX', scaleX);
    this.logo.set('scaleY', scaleY);

    this.logo.setCoords();

    this.logo.set('angle', this.rotationDegrees);
  }

  handleChangeContainerPercentHeight({ target }) {
    let { value } = target;

    if (value === '' || parseFloat(value) <= 0) {
      value = 1;
    } else if (parseFloat(value) > this.maxContainerPercentHeight) {
      value = this.maxContainerPercentHeight;
    }

    this.containerPercentHeightInput.value = value;
    this.containerPercentHeightRange.value = value;

    this.handleChangeLogoWidth();
    this.handleChangeLogoHeight();

    const ratio = this.ratio * (this.containerPercentHeight / 100);

    this.backgroundCanvas.setHeight(this.image.height * ratio);
    this.backgroundCanvas.setWidth(this.image.width * ratio);

    this.image.set('scaleX', ratio);
    this.image.set('scaleY', ratio);

    this.handleChangePositionX();
    this.handleChangePositionY();

    this.onMovedContainer();
    this.onMovedProductArea();

    this.backgroundCanvas.renderAll();
  }

  handleSelectInitialPreviewLogo() {
    const selectedInput = Array.from(this.previewLogosTarget.children).find(
      ({ firstChild: { firstChild: input } }) => input.checked
    );

    const { lastChild: selectedLogo } = selectedInput;

    this.selectedLogo = selectedLogo;

    this.createLogoCanvas();
    this.rerenderLogo();
  }

  handleAreaLinesToggle() {
    if (this.isAreaVisible) {
      this.container.set('stroke', LOGO_CONTAINER_BORDER_COLOR);

      if (this.productArea) {
        this.productArea
          .set('stroke', PRODUCT_AREA_BORDER_COLOR)
          .set('fill', 'rgba(0,0,255,0.1)');
        this.areaPoints.forEach(areaPoint => {
          areaPoint.set('fill', '#ffffff').set('stroke', '#333333');
        });
      }
    } else {
      this.container.set('stroke', 'transparent');

      if (this.productArea) {
        this.productArea
          .set('stroke', 'transparent')
          .set('fill', 'transparent');
        this.areaPoints.forEach(areaPoint => {
          areaPoint.set('fill', 'transparent').set('stroke', 'transparent');
        });
      }
    }

    this.backgroundCanvas.renderAll();
  }

  handleAreaPointsReset() {
    this.areaPoints.forEach(areaPoint =>
      this.backgroundCanvas.remove(areaPoint)
    );
    this.backgroundCanvas.remove(this.productArea);

    this.areaPoints = [];
    this.productArea = null;
    this.areaPointsInput.value = JSON.stringify([]);

    this.backgroundCanvas.renderAll();
    this.rerenderLogo();
  }

  handleSelectPreviewLogo(event) {
    event.stopPropagation();

    const {
      target: {
        parentElement: { nextSibling: selectedLogo }
      }
    } = event;

    this.selectedLogo = selectedLogo;

    this.createLogoCanvas();
    this.rerenderLogo();
  }

  async drawImageToCanvas({ target }) {
    try {
      const {
        dataset: { index }
      } = target;

      this.index = parseInt(index, 10);
      await this.initializePreview();
    } catch (error) {
      console.error(error);
    }
  }

  disconnect() {
    $('#applySettingsModal').off('shown.bs.modal');
  }

  get logoWidthInput() {
    return this.logoWidthInputTargets[this.index];
  }

  get logoHeightInput() {
    return this.logoHeightInputTargets[this.index];
  }

  get logoPositionXInput() {
    return this.logoPositionXInputTargets[this.index];
  }

  get logoPositionYInput() {
    return this.logoPositionYInputTargets[this.index];
  }

  get logoRotationDegreesInput() {
    return this.logoRotationDegreesInputTargets[this.index];
  }

  get logoRotationDegreesRange() {
    return this.logoRotationDegreesRangeTargets[this.index];
  }

  get logoAspectInput() {
    return this.logoAspectInputTargets[this.index];
  }

  get logoAspectDegreesInput() {
    return this.logoAspectDegreesInputTargets[this.index];
  }

  get logoAspectDegreesRange() {
    return this.logoAspectDegreesRangeTargets[this.index];
  }

  get logoTiltInput() {
    return this.logoTiltInputTargets[this.index];
  }

  get logoTiltDegreesInput() {
    return this.logoTiltDegreesInputTargets[this.index];
  }

  get logoTiltDegreesRange() {
    return this.logoTiltDegreesRangeTargets[this.index];
  }

  get logoSkewXDegreesInput() {
    return this.logoSkewXDegreesInputTargets[this.index];
  }

  get logoSkewXDegreesRange() {
    return this.logoSkewXDegreesRangeTargets[this.index];
  }

  get logoSkewYDegreesInput() {
    return this.logoSkewYDegreesInputTargets[this.index];
  }

  get logoSkewYDegreesRange() {
    return this.logoSkewYDegreesRangeTargets[this.index];
  }

  get logoWrapShapeInput() {
    return this.logoWrapShapeInputTargets[this.index];
  }

  get logoWrapAxisInput() {
    return this.logoWrapAxisInputTargets[this.index];
  }

  get logoPercentWrapInput() {
    return this.logoPercentWrapInputTargets[this.index];
  }

  get areaPointsInput() {
    return this.areaPointsInputTargets[this.index];
  }

  get logoPercentWrapRange() {
    return this.logoPercentWrapRangeTargets[this.index];
  }

  get productWidthInput() {
    return this.productWidthInputTargets[this.index];
  }

  get containerPercentHeightInput() {
    return this.containerPercentHeightInputTargets[this.index];
  }

  get containerPercentHeightRange() {
    return this.containerPercentHeightRangeTargets[this.index];
  }

  get productWidth() {
    return parseFloat(this.productWidthInput.value);
  }

  get productHeight() {
    return (
      parseFloat(this.productWidthInput.value) *
      (this.backgroundImageHeight / this.backgroundImageWidth)
    );
  }

  get width() {
    const ratio = this.productWidth ? this.logoWidth / this.productWidth : 0;
    return parseFloat(this.backgroundImageWidth * ratio);
  }

  get height() {
    const ratio = this.productHeight ? this.logoHeight / this.productHeight : 0;
    return parseFloat(this.backgroundImageHeight * ratio);
  }

  get backgroundImageWidth() {
    return (this.image.width * this.ratio * this.containerPercentHeight) / 100;
  }

  get backgroundImageHeight() {
    return (this.image.height * this.ratio * this.containerPercentHeight) / 100;
  }

  get left() {
    return parseFloat(
      (this.logoPositionXInput.value * this.backgroundImageWidth) / 100 +
        this.width / 2
    );
  }

  get top() {
    return parseFloat(
      (this.logoPositionYInput.value * this.backgroundImageHeight) / 100 +
        this.height / 2
    );
  }

  get logoWidth() {
    return parseFloat(this.logoWidthInput.value);
  }

  get logoHeight() {
    return parseFloat(this.logoHeightInput.value);
  }

  get rotationDegrees() {
    return parseFloat(this.logoRotationDegreesInput.value);
  }

  get isLeftAspect() {
    return this.aspectDegrees <= 0;
  }

  get isRightAspect() {
    return this.aspectDegrees > 0;
  }

  get aspectDegrees() {
    return parseFloat(this.logoAspectDegreesInput.value);
  }

  get isForthTilt() {
    return this.tiltDegrees <= 0;
  }

  get isBackTilt() {
    return this.tiltDegrees > 0;
  }

  get tiltDegrees() {
    return parseFloat(this.logoTiltDegreesInput.value);
  }

  get logoSkewXDegrees() {
    return parseFloat(this.logoSkewXDegreesInput.value);
  }

  get logoSkewYDegrees() {
    return parseFloat(this.logoSkewYDegreesInput.value);
  }

  get isConcaveWrapping() {
    const { value } = this.logoWrapShapeInput;
    return value === 'concave';
  }

  get isConvexWrapping() {
    const { value } = this.logoWrapShapeInput;
    return value === 'convex';
  }

  get isVerticalWrapping() {
    const { value } = this.logoWrapAxisInput;
    return value === 'vertical';
  }

  get isHorizontalWrapping() {
    const { value } = this.logoWrapAxisInput;
    return value === 'horizontal';
  }

  get logoPercentWrap() {
    const { value } = this.logoPercentWrapInput;
    return parseFloat(value);
  }

  get productAreaPoints() {
    const { value } = this.areaPointsInput;
    return JSON.parse(value).map(({ x, y }) => ({
      x: +parseFloat((x * this.backgroundImageWidth) / 100).toFixed(2),
      y: +parseFloat((y * this.backgroundImageHeight) / 100).toFixed(2)
    }));
  }

  get containerPercentHeight() {
    const { value } = this.containerPercentHeightInput;
    const containerPercentHeight = +value;

    return parseInt(
      (containerPercentHeight / this.maxContainerPercentHeight) * 100,
      10
    );
  }

  get isAreaVisible() {
    return this.areaLinesTogglerTarget.checked;
  }
}
