import Sortable from 'sortablejs';

import ApplicationController from './application_controller';

const ORDER_CONTAINERS_CONFIG = {
  animation: 150,
  swapThreshold: 0.2,
  draggable: '.layout-container',
  forceFallback: true
};

export default class extends ApplicationController {
  static targets = ['containers', 'spinnerButton'];
  static values = { settings: Object };

  connect() {
    super.connect();

    Sortable.create(this.containersTarget, {
      ...ORDER_CONTAINERS_CONFIG,
      onSort: ({ to }) => {
        const containersMap = Object.fromEntries(
          this.settingsValue.store_layout_containers_attributes.map(
            container => [container.id, container]
          )
        );

        const sortedIds = Array.from(to.children)
          .map(({ dataset: { id } }) => id)
          .filter(Boolean); // remove undefined values from array

        this.settingsValue = {
          ...this.settingsValue,
          store_layout_containers_attributes: sortedIds
            .map(id => containersMap[id])
            .map((container, position) => ({ ...container, position }))
        };
      }
    });
  }

  updateSpacing({ target: { checked } }) {
    this.settingsValue = { ...this.settingsValue, spacing: checked };
  }

  showSpinner() {
    this.spinner_html =
      '<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>';
    this.title = this.spinnerButtonTarget.getAttribute('data-spinner-title');

    this.spinnerButtonTarget.setAttribute('disabled', 'disabled');

    if (this.title != null) {
      this.spinnerButtonTarget.innerHTML = this.spinner_html + this.title;
    } else {
      this.spinnerButtonTarget.innerHTML = this.spinner_html;
    }
  }

  redirect(e) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('company_group_id', e.target.value);
    history.pushState(null, null, `?${queryParams}`);
    this.stimulate();
  }

  async save(event) {
    try {
      event.preventDefault();
      this.showSpinner();

      const {
        company_group_id,
        destination,
        id,
        spacing,
        company_id,
        store_layout_containers_attributes
      } = this.settingsValue;

      const authenticityToken = this.getCSRFtoken();

      const store_layout = {
        company_group_id,
        id: id || undefined,
        spacing,
        destination,
        company_id,
        store_layout_containers_attributes: store_layout_containers_attributes.map(
          ({ id, ...rest }) => ({ ...rest, id: id || undefined })
        )
      };

      const body = JSON.stringify({
        store_layout,
        authenticity_token: authenticityToken
      });
      const endpoint = `/style_builder/store_layout/${id}`;

      const response = await fetch(endpoint, {
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Response not OK: ${response.status}!`);
      }

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
}
