import Turbolinks from 'turbolinks';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['form'];

  connect() {
    super.connect();
  }

  formUrl() {
    return $(this.formTarget).attr('action');
  }

  addFilter() {
    const { filter, multiple } = event.target.dataset;

    const queryParams = new URLSearchParams(location.search);

    const filterParam =
      multiple === 'true' ? `filters[${filter}][]` : `filters[${filter}]`;
    queryParams.set(filterParam, '');

    const url = new URL(this.formUrl(), location.origin);

    Turbolinks.visit(
      `${url.pathname}?${url.searchParams.toString()}${queryParams.toString()}`,
      {
        action: 'replace'
      }
    );
  }

  removeFilter(event) {
    const { filter, dependentFields } = event.target.dataset;

    const params = new URLSearchParams(location.search)
      .toString()
      .split('&')
      .filter(el => el)
      .filter(el => !decodeURIComponent(el).startsWith(`filters[${filter}`))
      .filter(el =>
        dependentFields
          ? dependentFields
              .split(' ')
              .indexOf(decodeURIComponent(el).split('=')[0]) < 0
          : el
      );
    const query =
      params.length > 0 ? `?${params.join('&')}` : '?clean_filters=true';

    const url = new URL(this.formUrl(), location.origin);

    Turbolinks.visit(`${url.pathname}${query}`, {
      action: 'replace'
    });
  }

  phraseChanged(event) {
    if (event.keyCode === 13) {
      event.preventDefault();

      const params = new URLSearchParams(location.search)
        .toString()
        .split('&')
        .filter(el => !decodeURIComponent(el).startsWith('filters[phrase]'));
      params.push(`filters[phrase]=${encodeURIComponent(event.target.value)}`);

      const url = new URL(this.formUrl(), location.origin);

      Turbolinks.visit(`${url.pathname}?${params.join('&')}`, {
        action: 'replace'
      });
    }
  }

  submit(event) {
    const { dependentFields } = event.target.dataset;
    const queryParams = new URLSearchParams(window.location.search);
    const fields = this.formTarget.querySelectorAll('[role="filter"]');

    fields.forEach(field => {
      queryParams.delete(field.name);

      if (
        dependentFields &&
        dependentFields.split(' ').indexOf(field.name) >= 0
      ) {
        return;
      }

      if (field.multiple) {
        const isAllOptionSelected =
          Array.from(field.selectedOptions).filter(
            option => option.value === ''
          ).length > 0;

        if (field.selectedOptions.length > 0 && !isAllOptionSelected) {
          [].forEach.call(field.selectedOptions, option => {
            if (queryParams.get(field.name) === '') {
              queryParams.set(field.name, option.value);
            } else {
              queryParams.append(field.name, option.value);
            }
          });
        } else {
          queryParams.set(field.name, '');
        }
      } else {
        queryParams.set(field.name, field.value);
      }
    });

    const url = new URL(this.formUrl(), location.origin);

    Turbolinks.visit(
      `${url.pathname}?${url.searchParams.toString()}${queryParams.toString()}`,
      {
        action: 'replace'
      }
    );
  }
}
