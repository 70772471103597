import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      $('.select2').off('select2:select');

      super.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });

    super.initialize();
  }

  connect() {
    super.connect();

    $('.select2').selectpicker('destroy');
    $('.select2').select2({
      minimumInputLength: 0,
      ajax: {
        delay: 250,
        url: '/manage/part_exceptions/parts',
        dataType: 'json',
        data: params => {
          return {
            filters: {
              phrase: params.term
            }
          };
        },
        processResults: function (data) {
          console.log(data.items);
          return {
            results: $.map(data.items, item => {
              return {
                text: item['description'],
                id: item['id']
              };
            })
          };
        }
      },
      placeholder: 'Select'
    });

    $('.select2').on('select2:select', e => {
      this.stimulate('PartExceptionTool#set_part_number');
    });
  }
}
