import Turbolinks from 'turbolinks';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  filterByCompanyGroup({ target: companyGroupSelector }) {
    const companyGroupID = companyGroupSelector.value;
    const url = companyGroupSelector.getAttribute('data-url');

    Turbolinks.visit(`${url}?company_group_id=${companyGroupID}`, {
      action: 'replace'
    });
  }
}
