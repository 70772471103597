/* eslint-disable */
import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['card'];
  static values = { url: String };

  connect() {
    useClickOutside(this)
  }

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove('hidden');
    } else {
      fetch(this.urlValue)
        .then(r => r.text())
        .then(html => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);
          this.element.appendChild(fragment);
        });
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add('hidden');
    }
  }
}
