import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  submit(e) {
    const params = $(this.element).serializeJSON();
    e.target.setAttribute('disabled', 'disabled');
    e.preventDefault();

    this.stimulate('AdsContainerReflex#submit', params).then(result => {
      $('.select2').val(null).trigger('change');
      $(`#copyAdContainerModal${this.data.get('id')}`).modal('hide');
      $(`#copyAdContainerModalSuccess${this.data.get('id')}`).modal('show');
      this.decrementReflexesCount();
      this.forceHideLoadingMessage();
    });
  }
}
