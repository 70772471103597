import ApplicationController from './application_controller';

export default class extends ApplicationController {
  reflexWasHalted = false;

  connect() {
    super.connect();
  }

  submit(e) {
    const params = $(this.element).serializeJSON();
    e.target.setAttribute('disabled', 'disabled');
    e.preventDefault();

    this.stimulate('FeatureContainerReflex#submit', params).then(result => {
      if (!this.reflexWasHalted) {
        $('#copyFeatureContainerModal').modal('hide');
        $('#copyFeatureContainerModalSuccess').modal('show');
      }
      $('.select2').val(null).trigger('change');
      document.getElementById('applyModalButton').removeAttribute('disabled');
    });
    this.decrementReflexesCount();
  }

  reflexHalted() {
    this.reflexWasHalted = true;
    $('#copyFeatureContainerModal').modal('hide');
    $('#copyFeatureContainerModalFail').modal('show');
  }
}
