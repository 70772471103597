// Left Topbar
class Topbar {
  constructor() {
    this.body = $('body');
    this.window = $(window);
  }

  /**
     * Initilizes the menu
     */
  initMenu() {
    if ($('.topnav-menu').length) {
      $('.topnav-menu li a').each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        if (this.href == pageUrl) {
          $(this).addClass('active');
          $(this).parent().parent().addClass('active'); // add active to li of the current link
          $(this).parent().parent().parent().parent().addClass('active');
          $(this).parent().parent().parent().parent().parent().parent().addClass('active');
        }
      });

      // Topbar - main menu
      $('.navbar-toggle').on('click', function () {
        $(this).toggleClass('open');
        $('#navigation').slideToggle(400);
      });
    }
  }

  // init search
  initSearch() {
    // Serach Toggle
    var navDropdowns = $('.navbar-custom .dropdown:not(.app-search)');

    // hide on other click
    $(document).on('click', function (e) {
      if ($(e.target).closest('#search-dropdown').length === 0) {
        $('#search-dropdown').removeClass('d-block');
      }
      return true;
    });

    // Serach Toggle
    $('#top-search').on('click', function (e) {
      e.preventDefault();
      navDropdowns.children('.dropdown-menu.show').removeClass('show');
      $('#search-dropdown').addClass('d-block');
      return false;
    });

    // hide search on opening other dropdown
    navDropdowns.on('show.bs.dropdown', function () {
      $('#search-dropdown').removeClass('d-block');
    });
  }

  /**
   * Initilizes the menu
   */
  init() {
    this.initMenu();

    this.initSearch();
  }
}

export default Topbar;
