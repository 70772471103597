export const range = n =>
  Array(n)
    .fill(0)
    .map((_, index) => index);

export const swapSortableItems = ([...array], index1, index2) => {
  if (index1 < index2) {
    const item = array[index1];
    for (let i = index1; i < index2; i++) {
      array[i] = array[i + 1];
    }
    array[index2] = item;
  } else {
    const item = array[index1];
    for (let i = index1; i > index2; i--) {
      array[i] = array[i - 1];
    }
    array[index2] = item;
  }
  return array;
};

export const lengthDiffers = (a, b) => a.length !== b.length;

export const areArrays = (...xs) =>
  xs.every(x => (x ? Array.isArray(x) : false));
