import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    $('#deleteMasterPartModal').on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);
      const destroyPath = target.data('destroy-link');
      const destroyId = target.data('destroy-id');

      $('#deleteConfirmModalLink').attr('href', destroyPath);

      this.forceShowLoadingMessage();
      this.stimulate('MasterPartToolReflex#delete_body_modal', {
        destroyId: destroyId
      }).then(result => {
        this.forceHideLoadingMessage();
      });
    });
  }

  disconnect() {
    $('#deleteMasterPartModal').off('shown.bs.modal');
  }
}
