import Flatpickr from 'stimulus-flatpickr';

export default class extends Flatpickr {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      super.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      super.connect();
    });

    super.initialize();
  }

  connect() {
    this.config = {
      altInput: true,
      altFormat: 'n/j/y',
      altInputClass: 'flatpickr-alt-field',
      dateFormat: 'Y-m-d'
    };
    super.connect();
  }
}
