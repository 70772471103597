import { isBlobImage } from 'helpers';

import ApplicationController from './application_controller';
import uploadFile from '../utils/uploadFile';

const fileDisplayTypes = {
  image: 'image',
  text: 'text'
};

const fileUploadEvent = new CustomEvent('file-uploaded');
const fileDeleteEvent = new CustomEvent('file-deleted');
const fileUploadStartEvent = new CustomEvent('file-upload-start');

export default class extends ApplicationController {
  static targets = [
    'uploadArea',
    'fileInput',
    'placeholder',
    'img',
    'fileName',
    'deleteButton',
    'fileValueField'
  ];

  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      this.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });

    super.initialize();
  }

  connect() {
    if (this.hasFileInputTarget) {
      this.initFile();
      this.bindChangeEvent();
    }
    this.bindRemoveEvent();
  }

  disconnect() {
    if (this.hasFileInputTarget) {
      this.fileInputTarget.removeEventListener(
        'change',
        this.fileInputChangeAction
      );
    }

    this.deleteButtonTarget.removeEventListener(
      'click',
      this.deleteButtonClickAction
    );
  }

  initFile() {
    const { blob } = this.fileInputTarget.dataset;

    if (blob) {
      const blobJSON = JSON.parse(blob);
      this.addFilePreview(blobJSON);
    }
  }

  bindChangeEvent() {
    this.fileInputTarget.addEventListener('change', this.fileInputChangeAction);
  }

  fileInputChangeAction = () => {
    this.forceShowLoadingMessage();
    window.dispatchEvent(fileUploadStartEvent);

    const uploadedFilesPromises = this.uploadFiles();
    this.resolveUploadedFilesPreviews(uploadedFilesPromises);
  };

  bindRemoveEvent() {
    this.deleteButtonTarget.addEventListener(
      'click',
      this.deleteButtonClickAction
    );
  }

  deleteButtonClickAction = event => {
    event.stopPropagation();
    this.toggleFileDisplay();
    this.clearInputs();
    window.dispatchEvent(fileDeleteEvent);
  };

  clearInputs() {
    this.fileInputTarget.value = null;
    this.fileValueFieldTarget.value = null;
  }

  uploadFiles() {
    const url = this.fileInputTarget.dataset.directUploadUrl;

    return Array.from(this.fileInputTarget.files).map(file =>
      uploadFile(url)(file)
    );
  }

  resolveUploadedFilesPreviews(uploadedFilesPromises) {
    Promise.all(uploadedFilesPromises).then(blobsJSON => {
      blobsJSON.map(blobJSON => {
        this.addFilePreview(blobJSON);
      });
      this.forceHideLoadingMessage();
      window.dispatchEvent(fileUploadEvent);
    });
  }

  addFilePreview(blobJSON) {
    this.fileValueFieldTarget.value = blobJSON.signed_id;

    if (isBlobImage(blobJSON)) {
      this.imgTarget.src = `/rails/active_storage/blobs/redirect/${blobJSON.signed_id}/${blobJSON.filename}`;
      this.toggleFileDisplay(fileDisplayTypes.image);
    } else {
      this.fileNameTarget.innerHTML = blobJSON.filename;
      this.toggleFileDisplay(fileDisplayTypes.text);
    }
  }

  toggleFileDisplay(type) {
    this.fileInputTarget.disabled = !!type;
    this.fileInputTarget.style.display = type ? 'none' : 'block';
    this.placeholderTarget.style.display = type ? 'none' : 'block';
    this.imgTarget.style.display = type === 'image' ? 'block' : 'none';
    this.fileNameTarget.style.display = type === 'text' ? 'block' : 'none';
    this.deleteButtonTarget.style.display = type ? 'block' : 'none';
    this.uploadAreaTarget.classList.toggle('uploaded');
  }
}
