class ApplyToOthersModal {
  constructor(resource_id) {
    $('.select2').selectpicker('destroy');
    $('.select2')
      .select2()
      .on('select2:select', e => {
        this.handleSelectChange($(e.target).val());
      })
      .on('select2:unselect', e => {
        this.handleSelectChange($(e.target).val());
      });
    this.resourceId = resource_id;
  }

  handleSelectChange(selectedItems = []) {
    selectedItems.length > 0
      ? document
          .getElementById(`applyModalButton${this.resourceId}`)
          .removeAttribute('disabled')
      : document
          .getElementById(`applyModalButton${this.resourceId}`)
          .setAttribute('disabled', 'disabled');
  }
}
export default ApplyToOthersModal;
