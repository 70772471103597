import Sortable from 'sortablejs';
import Turbolinks from 'turbolinks';

import ApplicationController from './application_controller';
import { ApplyToOthersModal, getChildNode, swapSortableItems } from '../utils';
import { equalsDeeply } from '../utils/object';

const toCamelCaseAdapter = ({ company_group_id, ...rest }) => ({
  companyGroupID: company_group_id,
  ...rest
});

const toSnakeCaseAdapter = ({ companyGroupID, ...rest }) => ({
  company_group_id: companyGroupID,
  ...rest
});

export default class extends ApplicationController {
  static targets = [
    'cardBody',
    'templateItems',
    'featuredButton',
    'customButton',
    'bestSellersTab',
    'featuredTab',
    'customTab',
    'custom2Tab',
    'customSectionName',
    'customSectionLabel',
    'customSaveButton',
    'customSectionInput',
    'publishButton',
    'incompleteAlert',
    'copyButton'
  ];

  static values = {
    customSection: String,
    custom2Section: String,
    initial: Object
  };

  SECTIONS = {
    featured: 'featured',
    bestSellers: 'best_sellers',
    custom: 'custom',
    custom2: 'custom2'
  };

  featuredProductsSections;
  initObject;

  currentSection = {
    type: this.SECTIONS.bestSellers,
    id: 1
  };

  initialOrder;

  TEMPLATE_ITEMS_CONFIG = {
    animation: 150,
    handle: '.draggable',
    ghostClass: 'sortable-ghost',
    onEnd: event => this.updateItemsPosition(event)
  };

  connect() {
    const {
      initialValue: { featured_container }
    } = this;

    super.connect();
    this.init();

    this.featuredProductsSections = toCamelCaseAdapter(featured_container);
    this.initObject = JSON.parse(
      JSON.stringify(toCamelCaseAdapter(featured_container))
    );

    if (featured_container.id) {
      this.loadData();
    }
    this.initCopyModal();
  }

  disconnect() {
    $('#copyFeatureContainerModal').off('shown.bs.modal');
  }

  init() {
    const [
      featuredSection,
      bestSellersSection,
      customSection,
      custom2Section
    ] = this.templateItemsTargets;

    featuredSection.style = 'display: none';
    customSection.style = 'display: none';
    custom2Section.style = 'display: none';
    this.hideCustomNames();
    this.initialOrder = bestSellersSection.innerHTML;
    new Sortable(bestSellersSection, this.TEMPLATE_ITEMS_CONFIG);
  }

  initCopyModal() {
    $('#copyFeatureContainerModal').on('shown.bs.modal', event => {
      new ApplyToOthersModal();
    });
  }

  loadData() {
    const {
      SECTIONS: { bestSellers, featured, custom, custom2 },
      templateItemsTargets,
      customSectionLabelTargets,
      featuredProductsSections
    } = this;

    const [customSectionLabel, custom2SectionLabel] = customSectionLabelTargets;

    const [, , customSection] = templateItemsTargets;

    featuredProductsSections.sections.forEach(({ products, type, name }) => {
      const event = {
        target: {
          dataset: {
            value: type
          }
        }
      };

      switch (type) {
        case bestSellers:
          products.map(product => {
            this.addPhotoToTab({ ...product, sectionID: 1 });
          });
          break;

        case featured:
          this.addSection(event, true);
          products.map(product => {
            this.addPhotoToTab({ ...product, sectionID: 0 });
          });
          break;

        case custom:
          if (customSection.offsetParent) {
            this.addSection(event, true);
            const sectionn = featuredProductsSections.sections.find(
              section => section.name === name
            );
            sectionn.type = custom2;
            custom2SectionLabel.textContent = name;
            products.map(product => {
              this.addPhotoToTab({ ...product, sectionID: 3 });
            });
          } else {
            this.addSection(event, true);

            customSectionLabel.textContent = name;
            products.map(product => {
              this.addPhotoToTab({ ...product, sectionID: 2 });
            });
          }
          break;
      }
    });
    this.handleDisablePublishButton();
    this.handleSectionButtonsDisabling();
  }

  findSection(sectionName) {
    const { sections } = this.featuredProductsSections;

    return sections.find(({ type }) => type === sectionName);
  }

  hideCustomNames() {
    const [
      customSectionName,
      custom2SectionName
    ] = this.customSectionNameTargets;

    customSectionName.style = 'display:none';
    custom2SectionName.style = 'display:none';
  }

  updateItemsPosition({ oldIndex, newIndex }) {
    const {
      currentSection: { type }
    } = this;

    const section = this.findSection(type);
    const { products } = section;

    const sorted = [...products].sort((a, b) => a.position - b.position);
    const swapped = swapSortableItems(sorted, oldIndex, newIndex);

    swapped.forEach((product, index) => (product.position = index));
    section.products = swapped;

    this.detectChanges();
  }

  addSection(
    {
      target: {
        dataset: { value }
      }
    },
    load
  ) {
    const sectionName = value;

    const [
      featuredSection,
      ,
      customSection,
      custom2Section
    ] = this.templateItemsTargets;

    const {
      SECTIONS: { featured, custom, custom2 },
      TEMPLATE_ITEMS_CONFIG
    } = this;

    if (load) {
      switch (sectionName) {
        case featured:
          new Sortable(featuredSection, TEMPLATE_ITEMS_CONFIG);
          this.switchSection(featured);
          break;

        case custom:
          if (customSection.offsetParent) {
            new Sortable(custom2Section, TEMPLATE_ITEMS_CONFIG);
            this.switchSection(custom2);
          } else {
            new Sortable(customSection, TEMPLATE_ITEMS_CONFIG);
            this.switchSection(custom);
          }
      }
    } else {
      if (sectionName === featured) {
        new Sortable(featuredSection, TEMPLATE_ITEMS_CONFIG);
        this.switchSection(featured);
        this.addSectionToObject(featured);
      } else {
        const customExists = this.findSection(custom);

        if (customExists) {
          new Sortable(custom2Section, TEMPLATE_ITEMS_CONFIG);
          this.switchSection(custom2);
          this.addSectionToObject(custom2);
        } else {
          new Sortable(customSection, TEMPLATE_ITEMS_CONFIG);
          this.switchSection(custom);
          this.addSectionToObject(custom);
        }
      }
    }
    this.handleDisablePublishButton();
    this.detectChanges();
  }

  showBestSellers() {
    const {
      SECTIONS: { bestSellers },
      bestSellersTabTarget
    } = this;

    bestSellersTabTarget.classList.add('active');
    this.currentSection = {
      type: bestSellers,
      id: 1
    };
  }

  removeSection({
    target: {
      dataset: { value }
    }
  }) {
    const sectionName = value;
    const {
      currentSection: { type },
      SECTIONS: { bestSellers }
    } = this;

    this[`${[sectionName]}TabTarget`].style = 'display: none';

    if (sectionName === type) {
      this.showBestSellers();
    }

    this.switchSection(bestSellers);
    this.removeSectionFromObject(sectionName);
    this.clearSectionData(sectionName);
    this.handleDisablePublishButton();
    this.detectChanges();
  }

  addSectionToObject(sectionName) {
    const {
      SECTIONS: { custom, custom2 },
      featuredProductsSections: { sections }
    } = this;

    const newSection = {
      type: sectionName,
      products: [
        { position: 0 },
        { position: 1 },
        { position: 2 },
        { position: 3 }
      ]
    };

    if (sectionName === custom) {
      newSection.name = 'Custom 1';
    } else if (sectionName === custom2) {
      newSection.name = 'Custom 2';
    }

    sections.push(newSection);
    this.handleSectionButtonsDisabling();
  }

  removeSectionFromObject(sectionName) {
    const {
      featuredProductsSections: { sections }
    } = this;

    const sectionIndex = sections.findIndex(({ type }) => type === sectionName);

    sections.splice(sectionIndex, 1);
    this.handleSectionButtonsDisabling();
  }

  clearSectionData(type) {
    const {
      SECTIONS: { featured, custom, custom2 },
      initialOrder
    } = this;

    const [
      customSectionLabel,
      custom2SectionLabel
    ] = this.customSectionLabelTargets;

    const [
      featuredSection,
      ,
      customSection,
      custom2Section
    ] = this.templateItemsTargets;

    const [customInput, custom2Input] = this.customSectionInputTargets;

    switch (type) {
      case featured:
        featuredSection.innerHTML = initialOrder;
        break;

      case custom:
        customSection.innerHTML = initialOrder;
        customSectionLabel.textContent = 'Custom 1';
        customInput.value = '';
        break;

      case custom2:
        custom2Section.innerHTML = initialOrder;
        custom2SectionLabel.textContent = 'Custom 2';
        custom2Input.value = '';
        break;
    }
  }

  handleSectionButtonsDisabling() {
    const {
      featuredProductsSections: { sections },
      SECTIONS: { featured, custom, custom2 },
      featuredButtonTarget,
      customButtonTarget
    } = this;

    if (sections.length === 3) {
      featuredButtonTarget.setAttribute('disabled', true);
      customButtonTarget.setAttribute('disabled', true);
    } else {
      const featuredExists = this.findSection(featured);
      const customExists = this.findSection(custom);
      const custom2Exists = this.findSection(custom2);

      featuredButtonTarget.removeAttribute('disabled');
      customButtonTarget.removeAttribute('disabled');

      if (featuredExists) {
        featuredButtonTarget.setAttribute('disabled', true);
        customButtonTarget.removeAttribute('disabled');
      }
      if (customExists || custom2Exists) {
        featuredButtonTarget.removeAttribute('disabled');
        customButtonTarget.removeAttribute('disabled');
      }
    }
  }

  switchSection(sectionName) {
    const {
      SECTIONS: { featured, bestSellers, custom, custom2 }
    } = this;

    const [
      featuredSection,
      bestSellersSection,
      customSection,
      custom2Section
    ] = this.templateItemsTargets;

    const [
      customSectionName,
      custom2SectionName
    ] = this.customSectionNameTargets;

    switch (sectionName) {
      case featured:
        this.currentSection = {
          type: featured,
          id: 0
        };

        featuredSection.style = 'display:flex';
        bestSellersSection.style = 'display:none';
        customSection.style = 'display:none';
        custom2Section.style = 'display:none';
        this.featuredTabTarget.style = 'display: flex';

        this.hideCustomNames();

        this.featuredTabTarget.classList.add('active');
        this.bestSellersTabTarget.classList.remove('active');
        this.customTabTarget.classList.remove('active');
        this.custom2TabTarget.classList.remove('active');
        break;

      case bestSellers:
        this.currentSection = {
          type: bestSellers,
          id: 1
        };

        featuredSection.style = 'display:none';
        bestSellersSection.style = 'display:flex';
        customSection.style = 'display:none';
        custom2Section.style = 'display:none';
        this.bestSellersTabTarget.style = 'display: flex';

        this.hideCustomNames();

        this.featuredTabTarget.classList.remove('active');
        this.bestSellersTabTarget.classList.add('active');
        this.customTabTarget.classList.remove('active');
        this.custom2TabTarget.classList.remove('active');
        break;

      case custom:
        this.currentSection = {
          type: custom,
          id: 2
        };

        featuredSection.style = 'display:none';
        bestSellersSection.style = 'display:none';
        customSection.style = 'display:flex';
        custom2Section.style = 'display:none';
        this.customTabTarget.style = 'display: flex';

        customSectionName.style = 'display:flex';
        custom2SectionName.style = 'display:none';

        this.featuredTabTarget.classList.remove('active');
        this.bestSellersTabTarget.classList.remove('active');
        this.customTabTarget.classList.add('active');
        this.custom2TabTarget.classList.remove('active');
        break;

      case custom2:
        this.currentSection = {
          type: custom2,
          id: 3
        };

        featuredSection.style = 'display:none';
        bestSellersSection.style = 'display:none';
        customSection.style = 'display:none';
        custom2Section.style = 'display:flex';
        this.custom2TabTarget.style = 'display: flex';

        customSectionName.style = 'display:none';
        custom2SectionName.style = 'display:flex';

        this.featuredTabTarget.classList.remove('active');
        this.bestSellersTabTarget.classList.remove('active');
        this.customTabTarget.classList.remove('active');
        this.custom2TabTarget.classList.add('active');
        break;
    }
  }

  handleSwitchSection(event) {
    const tabName = event.target.dataset.value;
    this.switchSection(tabName);
  }

  clear({ selectorID }) {
    const {
      currentSection: { id: sectionId, type }
    } = this;

    const section = this.findSection(type);
    const { products } = section;

    const itemWrappers = this.templateItemsTargets[sectionId].children;
    let position;
    [...itemWrappers].forEach((item, index) => {
      if (parseInt(item.id) === selectorID) {
        position = index;
      }
    });

    products[position] = { position };

    const itemWrapper = itemWrappers.namedItem(selectorID);
    const imageContainer = getChildNode(itemWrapper)('imageContainer');
    imageContainer.innerHTML = `<div class="drop-zone" id="${
      selectorID + 1
    }" data-name="dropZone"></div>`;
    this.handleDisablePublishButton();
    this.detectChanges();
  }

  select({ id, selectorID, image, name }) {
    const {
      currentSection: { id: sectionID, type },
      templateItemsTargets
    } = this;

    const section = this.findSection(type);
    const { products } = section;
    const itemWrappers = templateItemsTargets[sectionID].children;

    let itemWrapperPosition;

    Array.from(itemWrappers).forEach(({ id }, index) => {
      if (parseInt(id) === selectorID) {
        itemWrapperPosition = index;
      }
    });

    const product = products.find(
      ({ position }) => position === itemWrapperPosition
    );

    product.id = id;
    product.image = image;
    product.name = name;
    product.position = itemWrapperPosition;

    const itemWrapper = itemWrappers.namedItem(selectorID);
    const imageContainer = getChildNode(itemWrapper)('imageContainer');
    imageContainer.innerHTML = `<img class="selected-image" src=${image} />`;
    this.handleDisablePublishButton();
    this.detectChanges();
  }

  addPhotoToTab({ id, image, name, position, sectionID }) {
    const itemWrappers = this.templateItemsTargets[sectionID].children;
    const itemWrapper = itemWrappers.namedItem(position);
    const imageContainer = getChildNode(itemWrapper)('imageContainer');
    const itemSelector = getChildNode(itemWrapper)('productSelector');

    itemSelector.setAttribute(
      'data-item-selector-option-value',
      JSON.stringify({ id, image, name, position })
    );

    imageContainer.innerHTML = `<img class="selected-image" src=${image} />`;
  }

  changeSectionName({ target }) {
    const {
      value,
      dataset: { value: dataValue }
    } = target;

    const {
      SECTIONS: { custom }
    } = this;

    const [customSaveButton, custom2SaveButton] = this.customSaveButtonTargets;

    if (dataValue === custom) {
      customSaveButton.removeAttribute('disabled');
      !value.length && customSaveButton.setAttribute('disabled', true);
      this.customSectionValue = value;
    } else {
      custom2SaveButton.removeAttribute('disabled');
      !value.length && custom2SaveButton.setAttribute('disabled', true);
      this.custom2SectionValue = value;
    }
  }

  handleChangeSectionName({
    target: {
      dataset: { value }
    }
  }) {
    const {
      currentSection: { type },
      SECTIONS: { custom }
    } = this;

    const [
      customSectionLabel,
      custom2SectionLabel
    ] = this.customSectionLabelTargets;

    const section = this.findSection(type);

    if (value === custom) {
      customSectionLabel.textContent = this.customSectionValue;
      section.name = this.customSectionValue;
    } else {
      custom2SectionLabel.textContent = this.custom2SectionValue;
      section.name = this.custom2SectionValue;
    }
  }

  handleDisablePublishButton() {
    const {
      featuredProductsSections: { sections },
      publishButtonTarget,
      incompleteAlertTarget
    } = this;

    publishButtonTarget.removeAttribute('disabled');
    incompleteAlertTarget.style = 'display: none !important';

    const products = sections.flatMap(section => [...section.products]);
    products.forEach(({ id }) => {
      if (!id) {
        publishButtonTarget.setAttribute('disabled', true);
        incompleteAlertTarget.style = 'display: flex';
      }
    });
  }

  detectChanges() {
    if (
      this.featuredProductsSections.id &&
      this.featuredProductsSections.destination !== 'corporate_page_1'
    ) {
      const {
        SECTIONS: { custom, custom2 },
        featuredProductsSections,
        copyButtonTarget,
        initObject
      } = this;

      const copy = JSON.parse(JSON.stringify(featuredProductsSections));
      const custom2Section = copy.sections.find(({ type }) => type === custom2);

      if (custom2Section) {
        custom2Section.type = custom;
      }

      const equals = equalsDeeply(featuredProductsSections, initObject);

      if (!equals) {
        copyButtonTarget.setAttribute('disabled', true);
      } else {
        copyButtonTarget.removeAttribute('disabled');
      }
    }
  }

  async publish() {
    try {
      const {
        featuredProductsSections,
        SECTIONS: { custom, custom2 }
      } = this;

      const custom2Section = this.findSection(custom2);
      if (custom2Section) {
        custom2Section.type = custom;
      }

      const featured_container = toSnakeCaseAdapter(featuredProductsSections);

      const { origin } = window.location;

      const endpoint = `${origin}/style_builder/featured_containers/${
        featured_container.id || ''
      }`;
      const authenticityToken = this.getCSRFtoken();

      const response = await fetch(endpoint, {
        method: featured_container.id ? 'PUT' : 'POST',
        body: JSON.stringify({
          authenticity_token: authenticityToken,
          featured_container
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const {
        meta: { location }
      } = await response.json();
      const url = `${origin}/${location}`;
      Turbolinks.visit(url || window.location.href, { action: 'replace' });
    } catch (error) {
      console.error(error);
    }
  }
}
