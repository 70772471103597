import ApplicationController from './application_controller';

export default class extends ApplicationController {
  RETURN_OPTION = {
    OPEN_RETURNS: 'open_returns',
    ALL_RETURNS_DATE_RANGE: 'all_returns_date_range',
    ORDER_NUMBER: 'order_number',
    CUSTOMER_EMAIL: 'customer_email'
  };

  static targets = [
    'returnsOptionSelect',
    'dateRange',
    'orderNumber',
    'emailAddress',
    'dateRangeFromInput',
    'dateRangeToInput',
    'orderNumberInput',
    'emailAddressInput'
  ];

  connect() {
    const returnOption = this.returnsOptionSelectTarget.value;
    this.showFieldByOption(returnOption);

    document.addEventListener('stimulus-reflex:after', event => {
      const returnOption = this.returnsOptionSelectTarget.value;
      this.showFieldByOption(returnOption);
    });
  }

  toggleFilters(e) {
    this.clearAllFields();
    const returnOption = e.target.value;
    this.showFieldByOption(returnOption);
  }

  hideAllFields() {
    this.dateRangeTarget.style.display = 'none';
    this.orderNumberTarget.style.display = 'none';
    this.emailAddressTarget.style.display = 'none';
  }

  clearAllFields() {
    this.dateRangeFromInputTarget.value = '';
    this.dateRangeToInputTarget.value = '';
    this.orderNumberInputTarget.value = '';
    this.emailAddressInputTarget.value = '';
  }

  showFieldByOption(option) {
    this.hideAllFields();

    switch (option) {
      case this.RETURN_OPTION.ALL_RETURNS_DATE_RANGE:
        this.dateRangeTarget.style.display = 'block';
        break;
      case this.RETURN_OPTION.ORDER_NUMBER:
        this.orderNumberTarget.style.display = 'flex';
        break;
      case this.RETURN_OPTION.CUSTOMER_EMAIL:
        this.emailAddressTarget.style.display = 'flex';
        break;
    }
  }
}
