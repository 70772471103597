import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['form'];

  connect() {
    super.connect();
  }

  send(event) {
    event.preventDefault();

    if (this.checkIfFormValid()) {
      this.formTarget.submitForm();
    }
  }

  checkIfFormValid() {
    return (
      $(this.formTarget).children().find('.is-invalid:not(.hidden)').length ===
      0
    );
  }
}
