import ApplicationController from './application_controller';

export default class extends ApplicationController {
  removeOrder(event) {
    console.log('te');
    const { orderId } = event.target.dataset;
    $.ajax({
      url: `/manage/open_orders_reports/open_orders/${orderId}`,
      method: 'DELETE',
      error: data => {
        console.log(data);
      }
    });
  }
}
