import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  destroy() {
    this.element.remove();
  }
}
