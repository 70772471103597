// Left Sidebar
class LeftSideBar {
  constructor() {
    this.body = $('body');
    this.window = $(window);
    this.menuContainer = $('#left-side-menu-container');
  }

  /**
     * Reset the theme
     */
  _reset() {
    this.body.removeAttr('data-leftbar-theme');
  }

  /**
   * Activates the condensed side bar
   */
  activateCondensedSidebar() {
    this.body.attr('data-leftbar-compact-mode', 'condensed');
  }

  /**
   * Deactivates the condensed side bar
   */
  deactivateCondensedSidebar() {
    this.body.removeAttr('data-leftbar-compact-mode');
  }

  /**
   * Activates the scrollable sidenar
   */
  activateScrollableSidebar() {
    this.body.attr('data-leftbar-compact-mode', 'scrollable');
  }

  /**
   * Deactivates the scrollbar
   */
  deactivateScrollableSidebar() {
    this.body.removeAttr('data-leftbar-compact-mode');
  }

  /**
   * Activates the default theme
   */
  activateDefaultTheme() {
    this._reset();
  }

  /**
   * Activates the light theme
   */
  activateLightTheme() {
    this._reset();
    this.body.attr('data-leftbar-theme', 'light');
  }

  /**
   * Activates the dark theme
   */
  activateDarkTheme() {
    this._reset();
    this.body.attr('data-leftbar-theme', 'dark');
  }

  /**
   * Initilizes the menu
   */
  initMenu() {
    var self = this;

    // resets everything
    this._reset();

    // sidebar - main menu
    $('.side-nav').metisMenu();

    // click events
    // Left menu collapse
    $(document).on('click', '.button-menu-mobile', function (e) {
      e.preventDefault();
      self.body.toggleClass('sidebar-enable');

      // if (self.window.width() >= 768) {
      //   self.body.toggleClass('enlarged');
      // } else {
      //   self.body.removeClass('enlarged');
      // }
    });

    // activate the menu in left side bar based on url
    $('.side-nav a').each(function () {
      var pageUrl = window.location.href.split(/[?#]/)[0];
      if (this.href == pageUrl) {
        $(this).addClass('active');
        $(this)
          .parent()
          .parent()
          .addClass('mm-show');
        $(this)
          .parent()
          .parent()
          .prev()
          .addClass('active'); // add active class to an anchor
        $(this)
          .parent()
          .parent()
          .parent()
          .addClass('mm-active');
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass('mm-show'); // add active to li of the current link
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass('mm-active');
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass('mm-show');
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass('mm-active'); // add active to li of the current link
      }
    });
  }

  /**
   * Initilizes the menu
   */
  init() {
    this.initMenu();
  }
}

export default LeftSideBar;
