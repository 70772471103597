import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  disconnect() {}

  submit(e) {
    const params = $(this.element).serializeJSON();
    e.target.setAttribute('disabled', 'disabled');

    e.preventDefault();
    this.stimulate('DisapproveReasonsReflex#update_body_modal', params).then(
      result => {
        $('#editDisapproveReasonModal').modal('hide');
        location.reload();
      }
    );
  }
}
