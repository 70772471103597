import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chevron'];

  connect() {
    const chevronController = this;

    $(this.element).on('show.bs.collapse', event => {
      chevronController.toggle_chevron(this.chevronTarget);
      event.stopPropagation();
    });
    $(this.element).on('hide.bs.collapse', event => {
      chevronController.toggle_chevron(this.chevronTarget);
      event.stopPropagation();
    });
  }

  disconnect() {
    $(this.element).off('show.bs.collapse');
    $(this.element).off('hide.bs.collapse');
  }

  toggle_chevron(target) {
    target.classList.toggle('dripicons-chevron-down');
    target.classList.toggle('dripicons-chevron-up');
  }
}
