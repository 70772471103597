import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  static targets = ['selectAllCheckbox', 'itemCheckbox', 'actionButton'];

  static values = {
    url: String
  };

  connect() {
    super.connect();
  }

  toggleAll() {
    if (this.isSelectAllChecked()) {
      this.enabledActionButton();
      this.selectAllItemCheckbox();
    } else {
      this.unselectAllItemCheckbox();
      this.disableActionButton();
    }
  }

  toggleItemCheck({ currentTarget }) {
    this.unselectSelectAllCheckbox();

    if(currentTarget.checked === true) {
      this.enabledActionButton();
    } else {
      if(this.getCheckedItemCheckbox().length === 0 ) {
        this.disableActionButton();
      }
    }
  }

  refreshSelectedItems() {
    if (this.isSelectAllChecked()) {
      this.toggleAll();
    }
  }

  isSelectAllChecked() {
    return this.selectAllCheckboxTarget.checked === true;
  }

  enabledActionButton(){
    this.actionButtonTarget.classList.remove('disabled');
  }

  disableActionButton(){
    this.actionButtonTarget.classList.add('disabled');
  }

  getCheckedItemCheckbox() {
    return this.itemCheckboxTargets.filter(checkbox => checkbox.checked);
  }

  selectAllItemCheckbox() {
    this.itemCheckboxTargets.forEach(checkbox => {
      if (checkbox.disabled === false) {
        checkbox.checked = true;
      }
    });
  }

  unselectAllItemCheckbox() {
    this.itemCheckboxTargets.forEach(checkbox => (checkbox.checked = false));
  }

  unselectSelectAllCheckbox() {
    this.selectAllCheckboxTarget.checked = false;
  }

  getCheckedItemCheckbox() {
    return this.itemCheckboxTargets.filter(checkbox => checkbox.checked);
  }

  getSelectedValues() {
    return this.getCheckedItemCheckbox().map(checkbox => checkbox.value);
  }

  saveData(blob, fileName) {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
  };

  async downloadFile() {
    const authenticityToken = this.getCSRFtoken();
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const selectedItems = this.getSelectedValues();
    let fileName = `file-${new Date().toLocaleDateString("en-US")}.pdf`;

    try {
      this.disableActionButton();
      this.forceShowLoadingMessage();

      // Fetch the file from the server
      const response = await fetch(this.urlValue, {
        method: 'POST',
        body: JSON.stringify({ selected_items: selectedItems }),
        headers: {
          'X-CSRF-Token': authenticityToken,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        // Convert the response to a blob and get fileName
        const blob = await response.blob();

        const fileNameMatches = filenameRegex.exec(await response.headers.get('content-disposition'));

        if (fileNameMatches != null && fileNameMatches[1]) {
          fileName = fileNameMatches[1].replace(/['"]/g, '');
        }

        this.saveData(blob, fileName);

        this.unselectAllItemCheckbox();
        this.unselectSelectAllCheckbox();
        this.forceHideLoadingMessage();
      } else {
        window.location.reload();
      }
    } catch (error) {
      this.forceHideLoadingMessage();
      console.error('Error downloading file:', error);
    }
  }

  disconnect() {
    super.disconnect();
  }
}
