const disableSubmitButtons = formButton => {
  const submitButtons = formButton.form?.querySelectorAll(
    'button[type="submit"]'
  );

  submitButtons?.forEach(submitButton => {
    submitButton.setAttribute('disabled', true);
  });
};

export default disableSubmitButtons;
