import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['updateButton', 'alert'];
  changedItems = [];

  connect() {
    super.connect();
  }

  disconnect() {
    super.disconnect();
  }

  refreshUpdateButton(orderItemId) {
    const item = this.changedItems.find(
      item => item.order_item_id === orderItemId
    );
    const disableButton = Object.values(item).includes('');

    this.setUpdateButtonDisabled(item.order_item_id, disableButton);
  }

  setUpdateButtonDisabled(orderItemId, disabled) {
    this.updateButtonTargets.find(
      element => element.dataset.orderItemId === orderItemId
    ).disabled = disabled;
  }

  changeNewTargetDate({ currentTarget }) {
    this.changeField('new_target_date', currentTarget);
  }

  changeQty({ currentTarget }) {
    this.changeField('quantity', currentTarget);
  }

  changeBackOrderNotesQty({ currentTarget }) {
    this.changeField('back_order_notes', currentTarget);
  }

  changeField(field, currentTarget) {
    const { orderItemId } = currentTarget.dataset;
    const itemIndex = this.findOrderItemIndex(orderItemId);

    if (field === 'quantity') this.parseNumber(currentTarget);

    if (itemIndex === -1) {
      const item = { order_item_id: orderItemId };
      item[field] = currentTarget.value;
      this.changedItems.push(item);
    } else {
      this.changedItems[itemIndex][field] = currentTarget.value;
    }

    this.refreshUpdateButton(orderItemId);
  }

  parseNumber(currentTarget) {
    const { value } = currentTarget;
    const parsedValue = parseInt(value);

    if (isNaN(parsedValue)) {
      currentTarget.value = '';
    } else if (parsedValue < 0) {
      currentTarget.value = 0;
    } else {
      currentTarget.value = parsedValue;
    }
  }

  changeVisibility({ currentTarget }) {
    const { orderItemId } = currentTarget.dataset;
    const itemIndex = this.findOrderItemIndex(orderItemId);

    if (itemIndex === -1) {
      this.changedItems.push({
        order_item_id: orderItemId,
        visible_in_store: currentTarget.checked
      });
    } else {
      const item = this.changedItems[itemIndex];
      item.visible_in_store = currentTarget.checked;
      this.changedItems[itemIndex] = item;
    }

    this.refreshUpdateButton(orderItemId);
  }

  updateOrderItem({ currentTarget }) {
    const { orderItemId } = currentTarget.dataset;

    const orderItemIndex = this.changedItems.findIndex(
      element => element.order_item_id === orderItemId
    );
    const orderItemParams = this.changedItems[orderItemIndex];
    delete orderItemParams.order_item_id;

    if (orderItemIndex === -1) return;

    $.ajax({
      url: `/manage/open_orders_reports/order_details/${orderItemId}`,
      data: { order_item: orderItemParams },
      method: 'PATCH',
      success: () => {
        this.changedItems.splice(orderItemIndex, 1);
        this.setUpdateButtonDisabled(orderItemId, true);
        this.showSuccessMessage();
      },
      error: data => {
        console.log(data);
        this.showErrorMessage();
      }
    });
  }

  findOrderItemIndex(orderItemId) {
    return this.changedItems.findIndex(
      item => item.order_item_id === orderItemId
    );
  }

  showSuccessMessage() {
    this.alertTarget.classList.remove('d-none');
    if (this.alertTarget.classList.contains('alert-danger')) {
      this.changeAlertType('success');
    }
    this.alertTarget.innerHTML = 'Order line item was updated successfully.';
  }

  showErrorMessage() {
    this.alertTarget.classList.remove('d-none');
    if (this.alertTarget.classList.contains('alert-success')) {
      this.changeAlertType('danger');
    }
    this.alertTarget.innerHTML =
      'Something went wrong while updating order line item. Update was aborted.';
  }

  changeAlertType(type) {
    if (type === 'success') {
      this.alertTarget.classList.add('alert-success');
      this.alertTarget.classList.remove('alert-danger');
    } else {
      this.alertTarget.classList.add('alert-danger');
      this.alertTarget.classList.remove('alert-success');
    }
  }
}
