import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'completed',
    'orderNumber',
    'openSelectedButton',
    'orderNumberButton',
    'allbox'
  ];

  connect() {
    super.connect();
  }

  toggle() {
    this.po_numbers = this.completedTargets
      .filter(completed => completed.checked)
      .map(completed => completed.value);
    this.enableOpenSelectedButton(this.po_numbers.length !== 0);
    this.set_params();
  }

  toggleAll() {
    if (this.allboxTarget.checked) {
      this.setAllCheckboxes(true);
    } else {
      this.setAllCheckboxes(false);
    }
  }

  setAllCheckboxes(checked) {
    this.allboxTargets.checked = true;
    this.completedTargets.forEach(el => {
      const completedTargets = el;

      if (!completedTargets.disabled) {
        completedTargets.checked = checked;
      }
    });
    this.po_numbers = this.completedTargets
      .filter(completed => completed.checked)
      .map(completed => completed.value);
    this.enableOpenSelectedButton(checked);
    this.set_params();
  }

  set_params() {
    this.passed_numbers;
  }

  get passed_numbers() {
    return this.po_numbers;
  }

  enableOpenSelectedButton(enable) {
    if (enable === true) {
      this.openSelectedButtonTarget.classList.remove('disabled');
    } else {
      this.openSelectedButtonTarget.classList.add('disabled');
    }
  }

  redirectMultiplePoOrders() {
    // It is required to have a separate status param because setting filters[po_number] key
    // as an array in the URLSearchParams is providing wrong format of param
    const statusParam = new URLSearchParams(window.location.search);
    statusParam.set('filters[status]', 'In Progress');
    const poNumberKey = '&filters[po_number][]=';
    const poNumbersParams = poNumberKey.concat(
      this.passed_numbers.join(poNumberKey)
    );
    window.location.href = `/manage/receiving_purchase_orders/items?${statusParam}&${poNumbersParams}`;
  }

  searchByOrderNumber() {
    const queryParams = new URLSearchParams(window.location.search);
    if (this.orderNumberTarget.value) {
      queryParams.set('filters[po_number]', this.orderNumberTarget.value);
    } else {
      queryParams.delete('filters[po_number]');
    }
    history.pushState(null, null, `?${queryParams}`);
    this.stimulate();
  }

  searchByVendor(e) {
    const queryParams = new URLSearchParams(window.location.search);
    if (e.target.value) {
      queryParams.set('filters[vendor]', e.target.value);
    } else {
      queryParams.delete('filters[vendor]');
    }
    history.pushState(null, null, `?${queryParams}`);
    this.stimulate();
  }
}
