import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    $('#deleteModal').on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);
      const destroyPath = target.data('destroy-link');

      $('#deleteConfirmModalLink').attr('href', destroyPath);
    });
  }

  disconnect() {
    $('#deleteModal').off('shown.bs.modal');
  }
}
