import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    $('#deleteMasterBlankLogoLocationModal').on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);
      const masterBlankId = target.data('master-blank-id');
      const logoLocationId = target.data('logo-location-id');
      const index = target.data('index');
      this.forceShowLoadingMessage();
      this.stimulate('MasterBlankReflex#delete_logo_location_modal_body', {
        masterBlankId: masterBlankId,
        logoLocationId: logoLocationId,
        index: index
      }).then(result => {
        this.forceHideLoadingMessage();
      });
    });
  }

  disconnect() {
    $('#deleteMasterBlankLogoLocationModal').off('shown.bs.modal');
  }
}
