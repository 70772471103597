const getQuadraticBezierXYatT = ({ startPoint, controlPoint, endPoint, t }) => {
  const pow1minusTSquared = Math.pow(1 - t, 2);
  const powTSquared = Math.pow(t, 2);

  const x =
    pow1minusTSquared * startPoint.x +
    2 * (1 - t) * t * controlPoint.x +
    powTSquared * endPoint.x;
  const y =
    pow1minusTSquared * startPoint.y +
    2 * (1 - t) * t * controlPoint.y +
    powTSquared * endPoint.y;

  return { x, y };
};

export default getQuadraticBezierXYatT;
