// Left RightBar
class RightBar {
  constructor() {
    this.body = $('body');
    this.window = $(window);
  }

  _selectOptionsFromConfig() {
    var config = window.hyperApp.getLayoutConfig();
    if (config) {
      // sideBarTheme
      switch (config.sideBarTheme) {
        case 'default':
          $('#default-check').prop('checked', true);
          break;
        case 'light':
          $('#light-check').prop('checked', true);
          break;
        case 'dark':
          $('#dark-check').prop('checked', true);
          break;
      }

      if (config.isBoxed) {
        $('#boxed-check').prop('checked', true);
      } else {
        $('#fluid-check').prop('checked', true);
      }
      if (config.isCondensed) $('#condensed-check').prop('checked', true);
      if (config.isScrollable) $('#scrollable-check').prop('checked', true);
      if (!config.isScrollable && !config.isCondensed) $('#fixed-check').prop('checked', true);

      // overall color scheme
      if (!config.isDarkModeEnabled) {
        $('#light-mode-check').prop('checked', true);
        if (config.layout === 'vertical')
          $('input[type=radio][name=theme]').prop('disabled', false);
      }
      if (config.isDarkModeEnabled) {
        $('#dark-mode-check').prop('checked', true);
        if (config.layout === 'vertical')
          $('input[type=radio][name=theme]').prop('disabled', false);
      }
    }
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSideBar() {
    var self = this;
    self.body.toggleClass('right-bar-enabled');
    self._selectOptionsFromConfig();
  }

  /**
   * Initilizes the right side bar
   */
  init() {
    var self = this;

    // right side-bar toggle
    $(document).on('click', '.right-bar-toggle', function () {
      self.toggleRightSideBar();
    });

    $(document).on('click', 'body', function (e) {
      if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
        return;
      }

      if (
        $(e.target).closest('.left-side-menu, .side-nav').length > 0 ||
        $(e.target).hasClass('button-menu-mobile') ||
        $(e.target).closest('.button-menu-mobile').length > 0
      ) {
        return;
      }
      $('body').removeClass('right-bar-enabled');
      $('body').removeClass('sidebar-enable');
      return;
    });

    // width mode
    $('input[type=radio][name=width]').change(function () {
      switch ($(this).val()) {
        case 'fluid':
          window.hyperApp.activateFluid();
          break;
        case 'boxed':
          window.hyperApp.activateBoxed();
          break;
      }
    });

    // theme
    $('input[type=radio][name=theme]').change(function () {
      switch ($(this).val()) {
        case 'default':
          window.hyperApp.activateDefaultSidebarTheme();
          break;
        case 'light':
          window.hyperApp.activateLightSidebarTheme();
          break;
        case 'dark':
          window.hyperApp.activateDarkSidebarTheme();
          break;
      }
    });

    // compact
    $('input[type=radio][name=compact]').change(function () {
      switch ($(this).val()) {
        case 'fixed':
          window.hyperApp.deactivateCondensedSidebar();
          window.hyperApp.deactivateScrollableSidebar();
          break;
        case 'scrollable':
          window.hyperApp.activateScrollableSidebar();
          break;
        case 'condensed':
          window.hyperApp.activateCondensedSidebar();
          break;
      }
    });

    // overall color scheme
    $('input[type=radio][name=color-scheme-mode]').change(function () {
      switch ($(this).val()) {
        case 'light':
          window.hyperApp.deactivateDarkMode();
          window.hyperApp.activateDefaultSidebarTheme();
          $('#default-check').prop('checked', true);
          $('input[type=radio][name=theme]').prop('disabled', false);
          break;
        case 'dark':
          window.hyperApp.activateDarkMode();
          $('#dark-check').prop('checked', true);
          // $('input[type=radio][name=theme]').prop('disabled', true);
          break;
      }
    });

    // reset
    $('#resetBtn').on('click', function (e) {
      e.preventDefault();
      // reset to default
      window.hyperApp.resetLayout(function () {
        self._selectOptionsFromConfig();
      });
    });
  }
}

export default RightBar;
