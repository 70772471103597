import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    $(this.element).on('show.bs.dropdown', function () {
      $(this).find('.dropdown-menu').first().slideDown(700);
    });

    $(this.element).on('hide.bs.dropdown', function (e) {
      e.preventDefault();
      $(this)
        .find('.dropdown-menu')
        .first()
        .slideUp(700, () => {
          $(e.target).find('div.dropdown-menu').removeClass('show');
          $(e.target).removeClass('show');
        });
    });
  }
}
