import $ from 'jquery';

import getChildNode from './getChildNode';
import getId from './uuid';
import { fromEntries } from './object';

export const TEMPLATE_BACKGROUND_COLOR = '#bb9e62';

const buttonLinkSetting = buttonLink => ({
  type: 2,
  title: 'Link',
  inputs: [
    {
      type: 'text',
      placeholder: 'https://example.com/',
      pattern: '^(https?)://[^\\s$.?#].[^\\s]*$',
      name: 'buttonLink',
      action: 'input->slides-creator#updateSettings',
      value: buttonLink
    }
  ]
});

const buttonLinkTargetSetting = buttonLinkTarget => {
  const checked = buttonLinkTarget === 'true' || buttonLinkTarget === true;
  return {
    type: 2,
    className: 'link-target-section',
    inputs: [
      {
        type: 'checkbox',
        label: 'Open Link in New Tab',
        checked,
        name: 'buttonLinkTarget',
        action: 'input->slides-creator#updateSettings',
        value: checked ? 'on' : 'off'
      }
    ]
  };
};

const buttonLinkHiddenSetting = buttonLinkHidden => {
  const checked = buttonLinkHidden === 'true' || buttonLinkHidden === true;
  return {
    type: 2,
    className: 'link-target-section',
    inputs: [
      {
        type: 'checkbox',
        label: 'Hidden',
        checked,
        name: 'buttonLinkHidden',
        action: 'input->slides-creator#updateSettings',
        value: checked ? 'on' : 'off'
      }
    ]
  };
};

const textContainerThemeSetting = textContainerTheme => ({
  type: 1,
  title: 'Container Style',
  inputs: [
    {
      label: 'Light Mode',
      value: 'light',
      type: 'radio',
      checked: textContainerTheme === 'light',
      action: 'input->slides-creator#updateSettings',
      name: 'textContainerTheme'
    },
    {
      label: 'Dark Mode',
      value: 'dark',
      type: 'radio',
      checked: textContainerTheme === 'dark',
      action: 'input->slides-creator#updateSettings',
      name: 'textContainerTheme'
    }
  ]
});

const textContainerPositionSettings = (
  textContainerPosition,
  withCenterPosition = false
) => {
  const left = {
    label: 'Left',
    value: 'left',
    type: 'radio',
    checked: textContainerPosition === 'left',
    action: 'input->slides-creator#updateSettings',
    name: 'textContainerPosition'
  };
  const center = {
    label: 'Middle',
    value: 'center',
    type: 'radio',
    checked: textContainerPosition === 'center',
    action: 'input->slides-creator#updateSettings',
    name: 'textContainerPosition'
  };
  const right = {
    label: 'Right',
    value: 'right',
    type: 'radio',
    checked: textContainerPosition === 'right',
    action: 'input->slides-creator#updateSettings',
    name: 'textContainerPosition'
  };

  return {
    type: 1,
    title: 'Positioning',
    inputs: withCenterPosition ? [left, center, right] : [left, right]
  };
};

const backgroundColorSetting = (
  elementName = 'backgroundColor'
) => backgroundColor => ({
  type: 3,
  title: 'Background Color',
  inputs: [
    {
      pattern: '#[a-f0-9]{6}',
      placeholder: 'HEX Color',
      value: backgroundColor || TEMPLATE_BACKGROUND_COLOR,
      action: 'input->slides-creator#updateSettings',
      name: elementName
    }
  ]
});

const textContainerBackgroundColorSetting = backgroundColorSetting(
  'textContainerBackgroundColor'
);

const textContainerVisibilitySettings = textContainerVisibility => {
  return {
    type: 1,
    title: 'Visibility',
    inputs: [
      {
        type: 'checkbox',
        checked: textContainerVisibility === 'false' ? false : true,
        action: 'input->slides-creator#updateSettings',
        name: 'textContainerVisibility'
      }
    ]
  };
};

const backgroundImageSetting = {
  type: 4,
  title: 'Upload Image',
  inputs: [
    {
      type: 'file',
      accept: 'image/png',
      placeholder: 'Upload PNG Image',
      action: 'input->slides-creator#updateSettings',
      name: 'backgroundImage'
    }
  ]
};

const backgroundImageFromLibrarySetting = {
  type: 5,
  inputs: [{}]
};

const backgroundSetting = ({
  isBackgroundImageSetting,
  isImageFromLibrarySetting = false,
  withBackgroundColor = false
}) => {
  const imageUploader = {
    label: 'Upload Image',
    value: 'image',
    type: 'radio',
    checked: isBackgroundImageSetting,
    action: 'input->slides-creator#changeBackgroundSettings',
    name: 'background'
  };

  const imageLibrary = {
    label: 'Image from Library',
    value: 'imageFromLibrary',
    type: 'radio',
    checked: isImageFromLibrarySetting,
    action: 'input->slides-creator#changeBackgroundSettings',
    name: 'background'
  };

  const colorPicker = {
    label: 'Solid Color',
    value: 'color',
    type: 'radio',
    checked: !(isBackgroundImageSetting || isImageFromLibrarySetting),
    action: 'input->slides-creator#changeBackgroundSettings',
    name: 'background'
  };

  return {
    type: 1,
    title: 'Background',
    inputs: withBackgroundColor
      ? [imageUploader, imageLibrary, colorPicker]
      : [imageUploader, imageLibrary]
  };
};

const templateResult = ({ loading, text }) =>
  loading
    ? text
    : $(`
          <div class="product-option clearfix">
            <div class="product-name-option">${text}</div>
          </div>
        `);
const templateSelection = data => {
  const { id, text } = data;
  $(data.element).attr('data-id', id);
  return text;
};

const initialSettings = {
  minimumResultsForSearch: Infinity,
  placeholder: 'Select',
  templateResult,
  templateSelection
};

const reinitialize = (element, data) => {
  if ($(element).hasClass('select2-hidden-accessible')) {
    $(element).html('');
    $(element).select2('destroy');
  }

  $(element).select2({ ...initialSettings, data });
  $(element).val(null).trigger('change');
};

const productImagesSettings = () => {
  $(() => {
    $('#supercategory-select').select2({
      ...initialSettings,
      ajax: {
        url: '/style_builder/categories',
        dataType: 'json',
        processResults: ({ supercategories }) => ({
          results: JSON.parse(
            JSON.stringify(supercategories).replaceAll('"name":', '"text":')
          )
        })
      }
    });

    $('#supercategory-select').on('select2:select', event => {
      const {
        params: { data }
      } = event;

      reinitialize(
        $('#category-select'),
        data.categories.map(({ text, id, subcategories }) => ({
          text,
          id,
          subcategories
        }))
      );

      $('#subcategory-select').val(null).trigger('change');
      $('#category-select').parent().show();
    });

    $('#category-select').on('select2:select', ({ params: { data } }) => {
      reinitialize(
        $('#subcategory-select'),
        data.subcategories.map(({ text, id }) => ({ text, id }))
      );

      $('#subcategory-select').parent().show();
    });

    $('#subcategory-select').on('select2:select', ({ params: { data } }) => {
      const categoryId =
        $('#subcategory-select').find(':selected').data('id') ||
        $('#category-select').find(':selected').data('id');

      $('#product-select').select2({
        placeholder: 'Select',
        minimumInputLength: 3,
        templateResult: ({ loading, text, image, name }) =>
          loading
            ? text
            : $(`
      <div class="product-option clearfix">
        <div class="product-image-option">
          <img width="${21}" height="${27}" src="${image}" />
        </div>
        <div class="product-name-option">${name}</div>
      </div>
    `),
        templateSelection: data => {
          const { id, name, text } = data;
          $(data.element).attr('data-id', id);

          return name || text;
        },
        width: 'resolve',
        dropdownParent: $('.section-6'),
        ajax: {
          url: `/style_builder/products${
            categoryId ? `?category_id=${categoryId}` : ''
          }`,
          dataType: 'json',
          data: params => ({ query: params.term }),
          processResults: results => ({ results })
        }
      });

      const productImageArea = $('.product-image-area');

      $('#product-select').parent().show();
      $('#add-product-btn').show();
      productImageArea.show();

      $('#product-select').on('select2:select', ({ params: { data } }) => {
        const productImage = document.createElement('div');

        const { image, id } = data;

        productImage.className = 'selected-product-image';
        productImage.innerHTML = `<img src=${image} width="215" height="279" />`;

        Object.assign(productImage.dataset, { id: id, image });

        if (document.querySelector('.selected-product-image')) {
          document
            .querySelector('.selected-product-image')
            .replaceWith(productImage);
        } else {
          productImageArea.replaceWith(productImage);
          $('#add-product-btn').prop('disabled', false);
        }
      });
    });
  });

  return {
    type: 6,
    title: 'Select product from the list.',
    inputs: [{}]
  };
};

export const slideSettings = ({
  backgroundSettingName,
  containerSettings: { inch },
  item: {
    backgroundColor,
    backgroundImage,
    buttonLink,
    buttonLinkHidden,
    buttonLinkTarget,
    templateType,
    textContainerBackgroundColor,
    textContainerPosition,
    textContainerTheme,
    textContainerVisibility
  },
  type
}) => {
  const hasBackgroundImage = Boolean(backgroundImage) && !backgroundColor;
  const isBackgroundImageSetting = backgroundSettingName === 'image';
  const isImageFromLibrarySetting =
    backgroundSettingName === 'imageFromLibrary';
  const isAd = type === 'ad';

  const widthLinkSetting = isAd && inch < 3;

  const settings = {
    1: {
      textContainer: {
        title: 'Container Settings',
        settings:
          hasBackgroundImage && !isAd
            ? [
                textContainerVisibilitySettings(textContainerVisibility),
                textContainerThemeSetting(textContainerTheme),
                textContainerPositionSettings(textContainerPosition, true)
              ]
            : [
                textContainerVisibilitySettings(textContainerVisibility),
                textContainerPositionSettings(textContainerPosition, true)
              ]
      },
      buttonText: {
        title: 'Button Link',
        settings: [
          buttonLinkSetting(buttonLink),
          buttonLinkTargetSetting(buttonLinkTarget),
          buttonLinkHiddenSetting(buttonLinkHidden)
        ]
      },
      background: {
        title: 'Background Settings',
        settings: widthLinkSetting
          ? [
              backgroundSetting({
                isBackgroundImageSetting,
                isImageFromLibrarySetting,
                withBackgroundColor: true
              }),
              isBackgroundImageSetting
                ? backgroundImageSetting
                : isImageFromLibrarySetting
                ? backgroundImageFromLibrarySetting
                : backgroundColorSetting()(backgroundColor),
              buttonLinkSetting(buttonLink),
              buttonLinkTargetSetting(buttonLinkTarget),
              buttonLinkHiddenSetting(buttonLinkHidden)
            ]
          : [
              backgroundSetting({
                isBackgroundImageSetting,
                isImageFromLibrarySetting,
                withBackgroundColor: true
              }),
              isBackgroundImageSetting
                ? backgroundImageSetting
                : isImageFromLibrarySetting
                ? backgroundImageFromLibrarySetting
                : backgroundColorSetting()(backgroundColor)
            ]
      },
      productImages: {
        title: 'Add product',
        settings: [
          productImagesSettings({
            backgroundSettingName,
            containerSettings: { inch },
            item: {
              backgroundColor,
              backgroundImage,
              buttonLink,
              buttonLinkTarget,
              buttonLinkHidden,
              templateType,
              textContainerBackgroundColor,
              textContainerPosition,
              textContainerTheme,
              textContainerVisibility
            },
            type
          })
        ]
      }
    },
    2: {
      textContainer: {
        title: 'Container Settings',
        settings: [
          textContainerVisibilitySettings(textContainerVisibility),
          textContainerPositionSettings(textContainerPosition),
          textContainerBackgroundColorSetting(textContainerBackgroundColor)
        ]
      },
      buttonText: {
        title: 'Button Link',
        settings: [
          buttonLinkSetting(buttonLink),
          buttonLinkTargetSetting(buttonLinkTarget),
          buttonLinkHiddenSetting(buttonLinkHidden)
        ]
      },
      background: {
        title: 'Background Settings',
        settings: widthLinkSetting
          ? [
              backgroundSetting({
                isBackgroundImageSetting,
                isImageFromLibrarySetting
              }),
              isBackgroundImageSetting
                ? backgroundImageSetting
                : backgroundImageFromLibrarySetting,
              buttonLinkSetting(buttonLink),
              buttonLinkTargetSetting(buttonLinkTarget),
              buttonLinkHiddenSetting(buttonLinkHidden)
            ]
          : [
              backgroundSetting({
                isBackgroundImageSetting,
                isImageFromLibrarySetting
              }),
              isBackgroundImageSetting
                ? backgroundImageSetting
                : backgroundImageFromLibrarySetting
            ]
      },
      productImages: {
        title: 'Add product',
        settings: [
          productImagesSettings({
            backgroundSettingName,
            containerSettings: { inch },
            item: {
              backgroundColor,
              backgroundImage,
              buttonLink,
              buttonLinkTarget,
              buttonLinkHidden,
              templateType,
              textContainerBackgroundColor,
              textContainerPosition,
              textContainerTheme,
              textContainerVisibility
            },
            type
          })
        ]
      }
    },
    3: {
      textContainer: {
        title: 'Container Settings',
        settings: [
          textContainerVisibilitySettings(textContainerVisibility),
          textContainerPositionSettings(textContainerPosition),
          textContainerBackgroundColorSetting(textContainerBackgroundColor)
        ]
      },
      buttonText: {
        title: 'Button Link',
        settings: [
          buttonLinkSetting(buttonLink),
          buttonLinkTargetSetting(buttonLinkTarget),
          buttonLinkHiddenSetting(buttonLinkHidden)
        ]
      },
      background: {
        title: 'Background Settings',
        settings: widthLinkSetting
          ? [
              backgroundSetting({
                isBackgroundImageSetting,
                isImageFromLibrarySetting
              }),
              isBackgroundImageSetting
                ? backgroundImageSetting
                : backgroundImageFromLibrarySetting,
              buttonLinkSetting(buttonLink),
              buttonLinkTargetSetting(buttonLinkTarget),
              buttonLinkHiddenSetting(buttonLinkHidden)
            ]
          : [
              backgroundSetting({
                isBackgroundImageSetting,
                isImageFromLibrarySetting
              }),
              isBackgroundImageSetting
                ? backgroundImageSetting
                : backgroundImageFromLibrarySetting
            ]
      },
      productImages: {
        title: 'Add product',
        settings: [
          productImagesSettings({
            backgroundSettingName,
            containerSettings: { inch },
            item: {
              backgroundColor,
              backgroundImage,
              buttonLink,
              buttonLinkTarget,
              buttonLinkHidden,
              templateType,
              textContainerBackgroundColor,
              textContainerPosition,
              textContainerTheme,
              textContainerVisibility
            },
            type
          })
        ]
      }
    }
  };

  return settings[templateType];
};

const productImageAdapter = ({
  id,
  positionX,
  positionY,
  scale,
  zIndex,
  productId,
  _destroy
}) => {
  return {
    id,
    master_part_parent_id: productId,
    position_x: positionX,
    position_y: positionY,
    scale,
    z_index: zIndex,
    _destroy
  };
};

export const slideAdapter = (
  {
    _destroy,
    backgroundColor,
    backgroundImage,
    buttonLink,
    buttonLinkTarget,
    buttonLinkHidden,
    buttonText,
    id,
    order,
    primaryText,
    secondaryText,
    slideTemplateId,
    templateType,
    tertiaryText,
    textContainerBackgroundColor,
    textContainerPosition,
    textContainerTheme,
    textContainerVisibility,
    productImages,
    uuid
  },
  isAd
) => {
  const target =
    buttonLinkTarget === 'true' ||
    buttonLinkTarget === true ||
    buttonLinkTarget === 'on';
  const hidden =
    buttonLinkHidden === 'true' ||
    buttonLinkHidden === true ||
    buttonLinkHidden === 'on';

  return {
    uuid,
    id,
    slide_template_id: slideTemplateId,
    order: order,
    slide_template_type: templateType,
    background_color: backgroundColor,
    background_image: backgroundImage ? { data: backgroundImage } : undefined,
    [isAd ? 'ad_images_attributes' : 'slide_images_attributes']: productImages
      ? Object.values(productImages).map(productImageAdapter)
      : [],
    config: {
      container: {
        is_visible:
          textContainerVisibility === ''
            ? true
            : textContainerVisibility === 'true' ||
              textContainerVisibility === true,
        theme: textContainerTheme,
        position: textContainerPosition,
        background: textContainerBackgroundColor,
        content: {
          primary: primaryText,
          secondary: secondaryText,
          tertiary: tertiaryText
        },
        button: {
          text: buttonText,
          link: buttonLink,
          target,
          hidden
        }
      }
    },
    _destroy
  };
};

export const slideCreatorAdapter = ({
  attributes,
  authenticityToken,
  companyGroupId,
  destination,
  slideScrollSpeed
}) => ({
  authenticity_token: authenticityToken,
  company_group_id: companyGroupId,
  slider: {
    slider_destination: destination,
    slide_scroll_speed: slideScrollSpeed,
    slides_attributes: Object.values(attributes).map(attribite =>
      slideAdapter(attribite, false)
    )
  }
});

export const adCreatorAdapter = ({
  attributes,
  authenticityToken,
  companyGroupId,
  containerSettings: { inch, columns, spacing },
  destination
}) => ({
  authenticity_token: authenticityToken,
  ads_container: {
    company_group_id: companyGroupId,
    ads_container_destination: destination,
    ads_container_height: inch,
    ads_container_layout: columns,
    spacing_between_ads: spacing,
    ads_attributes: Object.values(attributes).map(attribute =>
      slideAdapter(attribute, true)
    )
  }
});

export const toSlideObject = item => {
  const textContainer = getChildNode(item)('textContainer');
  const texts = getChildNode(textContainer)('texts');
  const productImagesNode = getChildNode(item)('productImages');
  const backgroundNode = getChildNode(item)('background');
  const backgroundImageNode = getChildNode(backgroundNode)('backgroundImage');
  const backgroundColorNode = getChildNode(backgroundNode)('backgroundColor');
  const primaryTextNode = getChildNode(texts)('primaryText');
  const secondaryTextNode = getChildNode(texts)('secondaryText');
  const tertiaryTextNode = getChildNode(texts)('tertiaryText');
  const buttonTextNode = getChildNode(texts)('buttonText');

  const id = item.getAttribute('data-id');
  const uuid = item.getAttribute('data-uuid');
  const order = item.getAttribute('data-order');
  const slideTemplateId = item.getAttribute('data-slide-template-id');
  const templateType = item.getAttribute('data-template-type');
  const textContainerBackgroundColor = textContainer.getAttribute(
    'data-text-container-background-color'
  );
  const textContainerPosition = textContainer.getAttribute(
    'data-text-container-position'
  );
  const textContainerTheme = textContainer.getAttribute(
    'data-text-container-theme'
  );
  const textContainerVisibility = textContainer.getAttribute(
    'data-text-container-visibility'
  );

  const backgroundImage = backgroundImageNode?.src;
  const backgroundColor = backgroundColorNode?.getAttribute(
    'data-background-color'
  );

  const primaryText = primaryTextNode.getAttribute('data-primary-text');
  const secondaryText = secondaryTextNode.getAttribute('data-secondary-text');
  const tertiaryText = tertiaryTextNode.getAttribute('data-tertiary-text');
  const buttonText = buttonTextNode.getAttribute('data-button-text');
  const buttonLink = buttonTextNode.getAttribute('data-button-link');
  const buttonLinkTarget = buttonTextNode.getAttribute(
    'data-button-link-target'
  );
  const buttonLinkHidden = buttonTextNode.getAttribute(
    'data-button-link-hidden'
  );

  const productImagesEntries = Array.from(productImagesNode.children).map(
    element => {
      const {
        dataset: {
          id,
          positionX,
          positionY,
          scale,
          src,
          zIndex,
          isVisible,
          partParentId
        }
      } = element;

      const containerId = getId();

      element.setAttribute('data-container-id', containerId);

      return [
        containerId,
        {
          containerId,
          id,
          isVisible: isVisible === 'true',
          positionX: parseInt(positionX),
          positionY: parseInt(positionY),
          productId: partParentId,
          scale: parseFloat(scale),
          src,
          zIndex: parseInt(zIndex),
          _destroy: false
        }
      ];
    }
  );

  const productImages = fromEntries(productImagesEntries);

  return {
    backgroundColor,
    backgroundImage,
    buttonLink,
    buttonLinkTarget,
    buttonLinkHidden,
    buttonText,
    id,
    order,
    primaryText,
    productImages,
    secondaryText,
    slideTemplateId,
    templateType,
    tertiaryText,
    textContainerBackgroundColor,
    textContainerPosition,
    textContainerTheme,
    textContainerVisibility,
    uuid
  };
};

export const getSlideOrders = ids =>
  ids.reduce((acc, id, index) => ({ ...acc, [id]: index }), {});

export const HINT_STEPS = {
  handler: { adHintStep: 4, sliderHintStep: 1 },
  triggerButtonBackground: {
    adHintStep: 5,
    sliderHintStep: 2
  },
  triggerButtonTextContainer: {
    adHintStep: 6,
    sliderHintStep: 3
  },
  texts: { adHintStep: 7, sliderHintStep: 4 },
  triggerButtonLink: { adHintStep: 8, sliderHintStep: 5 }
};

export const getHintArea = isAdCreator => (
  { adHintStep, sliderHintStep },
  attributes = {}
) => ({
  ...attributes,
  ['data-hints-target']: 'area',
  ['data-step']: isAdCreator ? adHintStep : sliderHintStep
});

export const cropSlideImageSize = {
  1: { width: 434, height: 181 },
  2: { width: 259, height: 181 },
  3: { width: 259, height: 181 }
};

export const cropAdImageSize = (columns, inch, type) => {
  let width = 0;
  const textContainerBottomWidths = { 1: 456, 2: 376, 3: 336 };
  const adWidth = 1440 / columns;

  if (type === '1') {
    width = adWidth;
  } else if (type === '2') {
    width = adWidth - textContainerBottomWidths[columns];
  } else if (type === '3') {
    const textContainerTopWidth =
      textContainerBottomWidths[columns] - 25.2 * inch;
    width = adWidth - textContainerTopWidth;
  }

  const ratio = 434 / 1440;

  return {
    width: width * ratio,
    height: inch * 96 * ratio
  };
};
