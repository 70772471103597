import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['checkAllCheckbox', 'itemCheckbox', 'placeOrderButton'];
  checkedItems = [];

  connect() {
    super.connect();
  }

  toggleCheckAll({ currentTarget }) {
    if (currentTarget.checked) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }
    this.refreshOrderButton();
  }

  checkAll() {
    this.itemCheckboxTargets.forEach(item => {
      if (item.checked) return;
      item.checked = true;
    });
  }

  uncheckAll() {
    this.itemCheckboxTargets.forEach(item => {
      if (!item.checked) return;
      item.checked = false;
    });
  }

  toggleItemCheck({ currentTarget }) {
    this.refreshOrderButton();
  }

  refreshOrderButton() {
    const checkedItemsCount = $(
      "input[name='striven_item_id'][type='checkbox']:checked"
    ).length;
    this.placeOrderButtonTarget.disabled = checkedItemsCount <= 0;
  }

  createPurchaseOrder() {
    this.forceShowLoadingMessage();

    $("input[name='striven_item_id'][type='checkbox']:checked")
      .get()
      .forEach(item => {
        const inputObject = $(item);

        this.checkedItems.push({
          striven_item_identifier: inputObject.val(),
          vendor_name: inputObject.data('vendor-name'),
          part_number: inputObject.data('part-number'),
          order_qty: inputObject.data('staged-qty')
        });
      });

    $.ajax({
      url: '/manage/materials_orderings/staged_items/purchase_orders',
      data: JSON.stringify({ purchase_order_items: this.checkedItems }),
      contentType: 'application/json; charset=uft-8',
      method: 'POST',
      success: () => {
        this.forceHideLoadingMessage();
      },
      error: data => {
        console.log(data);
        this.forceHideLoadingMessage();
      }
    });
  }

  disconnect() {
    super.disconnect();
  }
}
