import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      super.disconnect();
    });
    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });
    super.initialize();
  }
  connect() {
    $('.select2-master-blanks').selectpicker('destroy');
    $('.select2-master-blanks').select2({
      minimumInputLength: 3,
      ajax: {
        delay: 250,
        url: '/manage/master_blanks/search',
        dataType: 'json',
        data: params => {
          return {
            filters: {
              phrase: params.term,
              exclude: this.selectedItems()
            }
          };
        },
        processResults: function (data) {
          return {
            results: $.map(data, item => {
              return {
                text: item['description'],
                id: item.master_blank_parent_id
              };
            })
          };
        }
      },
      placeholder: 'Select'
    });
  }
  selectedItems() {
    return $('#relate_blank_parent_id')
      .select2('data')
      .map(({ id }) => Number(id))
      .concat($('#relate_blank_parent_id').data('exclude'))
      .filter(x => x !== undefined);
  }
}
