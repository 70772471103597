import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  more_options(event) {
    const { index } = event.currentTarget.dataset;

    const moreOptions = document.getElementById(`more-options-${index}`);
    const moreOptionsIcon = document.getElementById(
      `dripicons-chevron-${index}`
    );

    moreOptions.classList.toggle('hidden');
    moreOptionsIcon.classList.toggle('dripicons-chevron-down');
    moreOptionsIcon.classList.toggle('dripicons-chevron-up');
  }
}
