import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    const selector = $('.select2');

    const galleryFilters = $('#filters-selector');

    selector.selectpicker('destroy');
    selector.select2({
      closeOnSelect: false,
      placeholder: 'Select'
    });

    galleryFilters.select2({
      placeholder: 'Select'
    });

    selector
      .on('select2:unselecting', () => {
        $(this).data('unselecting', true);
      })
      .on('select2:opening', event => {
        if ($(this).data('unselecting')) {
          $(this).removeData('unselecting');
          event.preventDefault();
        }
      });
  }

  disconnect() {
    $('.select2').off('select2:unselecting');
    $('.select2').off('select2:opening');
  }
}
