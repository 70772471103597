import SimpleBar from 'simplebar';

export const saveScrollPosition = () => {
  const simpleBarScroll = getSimpleBarScroll();
  if (!simpleBarScroll) return;

  const scrollPosition = simpleBarScroll.scrollTop;
  sessionStorage.setItem('scrollPosition', scrollPosition);
};

export const restoreScrollPosition = () => {
  const simpleBarScroll = getSimpleBarScroll();
  if (!simpleBarScroll) return;

  simpleBarScroll.scrollTop = sessionStorage.getItem('scrollPosition');
};

const getSimpleBarScroll = () => {
  return SimpleBar.instances
    .get(document.querySelector('[data-simplebar]'))
    ?.getScrollElement();
};
