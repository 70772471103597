import Rails from '@rails/ujs';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['entries', 'pagination'];

  initialize() {
    const options = {
      rootMargin: '100px'
    };

    this.intersectionObserver = new IntersectionObserver(
      entries => this.processIntersectionEntries(entries),
      options
    );
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  emitSuccessfullyPaginationLoadingEvent() {
    const event = new Event('successfullyPaginationLoading', {
      bubbles: true,
      cancelable: true
    });
    document.dispatchEvent(event);
  }

  loadMore() {
    const next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (next_page == null) {
      return;
    }
    const url = next_page.href;

    this.paginationTarget.classList.add('infinite-scroll-pagination');

    this.forceShowLoadingMessage();
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: data => {
        this.forceHideLoadingMessage();
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        this.emitSuccessfullyPaginationLoadingEvent();
      },
      error: data => {
        this.forceHideLoadingMessage();
      }
    });
  }
}
