import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  static targets = ['selectAllCheckbox', 'itemCheckbox', 'urlResource'];

  static values = {
    selectAllDisabled: Boolean
  };

  connect() {
    super.connect();
  }

  toggleAll() {
    if (this.isSelectAllChecked()) {
      this.selectAllItemCheckbox();
      if (this.selectAllOptionEnabled()) {
        this.setUrlParamsSelectAll(true);
        this.setUrlParamsIds([]);
      } else {
        this.addSelectedValuesToUrlResource();
      }
    } else {
      this.unselectAllItemCheckbox();
      this.setUrlParamsSelectAll(false);
      this.setUrlParamsIds([]);
    }

    this.refreshUrlResource();
  }

  toggleItemCheck({ currentTarget }) {
    this.unselectSelectAllCheckbox();
    this.setUrlParamsSelectAll(false);
    this.addSelectedValuesToUrlResource();

    this.refreshUrlResource();
  }

  refreshSelectedItems() {
    if (this.isSelectAllChecked()) {
      this.toggleAll();
    }
  }

  isSelectAllChecked() {
    return this.selectAllCheckboxTarget.checked === true;
  }

  refreshUrlResource() {
    const url = this.getResourceUrl();

    if (this.isSelectAllChecked() || url.searchParams.has('ids')) {
      this.urlResourceTarget.classList.remove('disabled');
    } else {
      this.urlResourceTarget.classList.add('disabled');
    }
  }

  setUrlParamsSelectAll(value) {
    const url = this.getResourceUrl();
    url.searchParams.set('select_all', value);
    this.urlResourceTarget.href = url;
  }

  setUrlParamsIds(ids) {
    const url = this.getResourceUrl();

    if (ids.length > 0) {
      url.searchParams.set('ids', ids.join(','));
    } else {
      url.searchParams.delete('ids');
    }
    this.urlResourceTarget.href = url;
  }

  getCheckedItemCheckbox() {
    return this.itemCheckboxTargets.filter(checkbox => checkbox.checked);
  }

  addSelectedValuesToUrlResource() {
    const selectedItems = this.getCheckedItemCheckbox().map(
      checkbox => checkbox.value
    );

    this.setUrlParamsIds(selectedItems);
  }

  selectAllItemCheckbox() {
    this.itemCheckboxTargets.forEach(checkbox => {
      if (checkbox.disabled === false) {
        checkbox.checked = true;
      }
    });
  }

  unselectAllItemCheckbox() {
    this.itemCheckboxTargets.forEach(checkbox => (checkbox.checked = false));
  }

  unselectSelectAllCheckbox() {
    this.selectAllCheckboxTarget.checked = false;
  }

  clearAllSelection() {
    this.unselectSelectAllCheckbox();
    this.unselectAllItemCheckbox();
  }

  getResourceUrl() {
    return new URL(this.urlResourceTarget.href);
  }

  disconnect() {
    super.disconnect();
  }

  selectAllOptionEnabled() {
    return this.selectAllDisabledValue === false;
  }
}
