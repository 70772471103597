import ColorPicker from 'vanilla-picker';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  showColorPicker({ currentTarget }) {
    let pickerEnabled = false;
    let colorChanged = false;

    const colorPicker = new ColorPicker({
      parent: currentTarget.parentElement,
      alpha: false,
      editor: true,
      popup: 'bottom',
      color: currentTarget.value,
      onOpen: () => {
        pickerEnabled = true;
      },
      onChange: ({ hex }) => {
        if (pickerEnabled) {
          currentTarget.value = this.formatHex(hex);
          colorChanged = true;
        }
      },
      onClose: () => {
        colorPicker.destroy();
        if (colorChanged) currentTarget.dispatchEvent(new Event('change'));
      }
    });
  }

  formatHex(hex) {
    return hex.substring(1, 7).toUpperCase();
  }
}
