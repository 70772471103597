import Sortable from 'sortablejs';

import { uploadFile } from '../utils';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['list'];

  connect() {
    super.connect();

    this.sortable = Sortable.create(this.listTarget, {
      animation: 150,
      onEnd: evt => this.handlePositionChange(evt)
    });
  }

  async handleFileSelect(event) {
    event.preventDefault();
    this.forceShowLoadingMessage();

    const fileInput = event.target;

    const {
      dataset: { directUploadUrl },
      files
    } = fileInput;

    const upload = uploadFile(directUploadUrl);

    let secondary_image_id =
      parseInt(this.element.getAttribute('data-items'), 10) - 1;

    const promises = Array.from(files).map(upload);
    const blobs = await Promise.all(promises);
    const images = blobs.map(blob => ({
      ...blob,
      secondary_image_id: ++secondary_image_id
    }));

    this.stimulate('MasterBlankReflex#add_secondary_images', { images });

    fileInput.value = null;
  }

  handlePositionChange({ newIndex, oldIndex }) {
    if (newIndex === oldIndex) return;

    this.stimulate('MasterBlankReflex#reposition_secondary_image', {
      old_position: oldIndex,
      new_position: newIndex
    });
  }
}
