import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    $('#updateLineItemQuantityModal').on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);
      const orderItemId = target.data('order-item-id');

      this.forceShowLoadingMessage();
      this.stimulate('OrderReflex#update_item_quantity', {
        orderItemId: orderItemId
      }).then(result => {
        this.forceHideLoadingMessage();
      });
    });
  }

  disconnect() {
    $('#updateLineItemQuantityModal').off('shown.bs.modal');
  }
}
