import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.changeContainerPreviewWidth();
    window.addEventListener('resize', this.changeContainerPreviewWidth);
  }

  changeContainerPreviewWidth() {
    const defaultContainerWidth = 522;
    const adContainerWrapper = document.querySelector('.ad-container_wrapper');
    if (!adContainerWrapper) return;
    const offset = adContainerWrapper.offsetWidth;

    const containerPreviewWidth = (offset * 1440) / defaultContainerWidth;

    document.documentElement.style.setProperty(
      '--container-preview-width',
      `${containerPreviewWidth}px`
    );

    document.documentElement.style.setProperty(
      '--container-preview-scale',
      (offset - 32) / containerPreviewWidth
    );
  }

  redirect(e) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('company_group_id', e.target.value);
    history.pushState(null, null, `?${queryParams}`);
    this.stimulate();
  }

  disconnect() {
    window.removeEventListener('resize', this.changeContainerPreviewWidth);
  }
}
