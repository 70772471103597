import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      super.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });

    super.initialize();
  }

  connect() {
    $('.select2').selectpicker('destroy');
    $('.select2').select2({
      placeholder: 'Select',
      templateResult: function (data) {
        return $('<div class="select-option"></div>').html(data.text);
      },
      templateSelection: function (data) {
        return $('<div class="select-option"></div>').html(data.text);
      }
    });
  }
}
