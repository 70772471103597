const sliderCreatorHints = ({ isIntro, position, isShopPage }) => {
  const hints = [
    {
      action: 'close',
      title: 'Drag and Drop',
      description:
        'Drag chosen Template and drop it below in the Order section.',
      position: 'top-right',
      ...(isIntro ? { startStep: 1, endStep: isShopPage ? 6 : 5 } : {})
    },
    {
      action: 'close',
      title: 'Click on Slide',
      description:
        'Click on the chosen element to start edition in Preview below.',
      position: 'top-right',
      ...(isIntro ? { startStep: 2, endStep: isShopPage ? 6 : 5 } : {})
    },
    {
      action: 'next',
      description: 'Click on the background to edit it.',
      title: 'Edit Background',
      position: `top-${
        position === 'center' || position === 'left' ? 'left' : 'right'
      }`,
      ...(isIntro
        ? { startStep: 3, endStep: isShopPage ? 6 : 5 }
        : { startStep: 1, endStep: isShopPage ? 4 : 3 })
    },
    {
      title: 'Edit Box',
      description: 'Click on the box to edit it.',
      action: 'next',
      position: `top-${position === 'left' ? 'right' : 'left'}`,
      ...(isIntro
        ? { startStep: 4, endStep: isShopPage ? 6 : 5 }
        : { startStep: 2, endStep: isShopPage ? 4 : 3 })
    },
    {
      title: 'Edit Headers',
      description: 'Click on the text to edit it',
      action: isShopPage ? 'next' : 'save',
      position: 'top-right',
      ...(isIntro
        ? { startStep: 5, endStep: isShopPage ? 6 : 5 }
        : { startStep: 3, endStep: isShopPage ? 4 : 3 })
    },
    {
      title: 'Adding Hyperlink',
      description: 'Click on the button to add hyperlink.',
      action: isIntro ? 'save' : 'close',
      position: 'bottom-right',
      ...(isIntro
        ? { startStep: isShopPage ? 6 : 5, endStep: isShopPage ? 6 : 5 }
        : { startStep: isShopPage ? 4 : 3, endStep: isShopPage ? 4 : 3 })
    }
  ];

  return hints;
};

export default sliderCreatorHints;
