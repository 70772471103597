export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }
}

export function findElement(root, selector) {
  if (typeof root == 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function appendChild(el, referenceNode) {
  return referenceNode.parentNode.appendChild(el);
}

export function isBlobImage(blob) {
  return blob && blob['content_type'].split('/')[0] === 'image';
}
