// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import debounced from 'debounced';

import consumer from '../channels/consumer';
import controller from './application_controller';

require('flatpickr/dist/flatpickr.css');

debounced.initialize();
const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
StimulusReflex.initialize(application, { consumer, controller, isolate: true });
StimulusReflex.debug =
  process.env.RAILS_ENV === 'development' ||
  process.env.RAILS_ENV === 'sandbox';
