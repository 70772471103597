import ApplicationController from './application_controller';

const isVisible = element => {
  return window.getComputedStyle(element).display !== 'none';
};

const isHidden = element => element.offsetParent === null;

export default class extends ApplicationController {
  static targets = ['search', 'options'];
  static values = {
    options: Array,
    option: Object,
    id: String,
    endpoint: String,
    companyGroupId: String
  };

  connect() {
    const query = this.option.name || '';

    this.element.innerHTML = `
    <div class="item-selector">
        <div class="input-wrapper" data-action="click->item-selector#open">
            <input type="text" value="${query}" placeholder="Choose Product" data-item-selector-target="search" data-action="input->item-selector#search" />
            <i class="arrow"></i>
        </div>
        <div style="display: none" class="items" data-item-selector-target="options"></div>
    </div>
    `;
  }

  async fetchOptions(query) {
    const { origin } = window.location;

    const requestUrl = () => {
      const url = new URL(`${origin}${this.endpointValue}`);
      if (query && query !== '') url.searchParams.append('query', query);
      if (this.companyGroupIdValue && this.companyGroupIdValue !== '')
        url.searchParams.append('company_group_id', this.companyGroupIdValue);

      return url.href;
    };
    const response = await fetch(requestUrl());
    const result = await response.json();

    const selectors = document.querySelectorAll(
      '[data-controller=item-selector]'
    );

    const byOptionId = ({ id }) => id;

    const visibleSelectors = [...selectors].filter(
      selector => !isHidden(selector)
    );

    const selectedOptions = visibleSelectors
      .map(({ dataset: { itemSelectorOptionValue } }) =>
        JSON.parse(itemSelectorOptionValue)
      )
      .filter(byOptionId)
      .map(byOptionId);

    this.optionsValue = result.filter(
      ({ id }) => !selectedOptions.includes(id)
    );

    this.renderOptions();
  }

  renderOptions() {
    const anyOptions = this.optionsValue.length > 0;
    this.optionsTarget.innerHTML = anyOptions
      ? this.optionsValue
          .map(
            option =>
              `<div class="item" data-id="${option.id}" data-action="click->item-selector#select">
              <img src="${option.image}" width="32" height="33" />
              <span>${option.name}</span>
            </div>`
          )
          .join('')
      : '<div class="item"><span>No products found</span></div>';
  }

  addBackdrop() {
    const globalBackdrop = document.querySelector('.backdrop');

    if (globalBackdrop) {
      globalBackdrop.click();
    }

    const backdrop = document.createElement('div');

    backdrop.classList.add('backdrop');
    backdrop.setAttribute('data-action', 'click->item-selector#close');
    backdrop.setAttribute('data-item-selector-target', 'backdrop');

    this.element.appendChild(backdrop);
  }

  async open() {
    await this.fetchOptions();

    if (isVisible(this.optionsTarget)) {
      this.close();
      return;
    }

    this.optionsTarget.style = 'display: block';
    this.optionsTarget.parentElement.classList.add('open');
    this.searchTarget.focus();
    this.searchTarget.select();

    this.addBackdrop();
  }

  close() {
    this.optionsTarget.style = 'display: none';
    this.optionsTarget.parentElement.classList.remove('open');
    this.searchTarget.blur();
    document.querySelector('.backdrop').remove();
  }

  async search({ target: { value } }) {
    const selectorId = parseInt(this.idValue);

    this.optionValue = {};
    this.parentController.clear({
      selectorID: selectorId
    });

    await this.fetchOptions(value);
  }

  select({
    target: {
      dataset: { id }
    }
  }) {
    const option = this.optionsValue.find(option => option.id === parseInt(id));

    this.optionValue = option;
    this.input.value = option.name;

    this.parentController.select({
      ...option,
      selectorID: parseInt(this.idValue)
    });

    this.close();
  }

  get option() {
    return this.optionValue;
  }

  get input() {
    return this.searchTarget;
  }

  get parentController() {
    const {
      dataset: { parentController }
    } = this.element;

    return this.application.controllers.find(controller => {
      return controller.context.identifier === parentController;
    });
  }
}
