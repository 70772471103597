import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      super.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });

    super.initialize();
  }

  connect() {
    // TODO: This is a workaround for a bug causing to add multiple event listeners, we should refactor template selector functionality
    $('.select2').off('select2:select');
    $('.select2').off('select2:unselect');

    $('.select2').selectpicker('destroy');
    $('.select2').select2({
      placeholder: 'Select'
    });

    $('.select2').on('select2:select', () => {
      this.stimulate('MasterBlankReflex#update_templates');
    });
    $('.select2').on('select2:unselect', () => {
      this.stimulate('MasterBlankReflex#update_templates');
    });

    super.connect();
  }
}
