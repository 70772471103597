import disableSubmitButtons from '../utils/disableSubmitButtons';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  validate_and_submit(e) {
    e.preventDefault();
    disableSubmitButtons(e.target);

    this.stimulate('InventoryCodeFormReflex#validate').then(() => {
      if (this.element.getAttribute('data-form-valid') === 'true') {
        this.element.submitForm();
      }
    });
  }
}
