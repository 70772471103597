import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static classes = ['hiding'];

  initialize() {
    this.setHiddenStatus();

    document.addEventListener('cable-ready:before-morph', () => {
      this.setHiddenStatus();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      if (this.hidden) {
        this.hide();
      }
    });
  }

  connect() {
    super.connect();

    const identifier = this.identifier.replace(/-./g, x => x[1].toUpperCase());
    this.element[identifier] = this;
  }

  changePosition(event) {
    event.preventDefault();

    if (this.hidden) {
      this.show();
    } else {
      this.hide();
    }
  }

  setHiddenStatus() {
    this.hidden = this.element.classList.contains(this.hidingClass);
  }

  hide() {
    this.element.classList.add(this.hidingClass);
    this.hidden = true;
  }

  show() {
    this.element.classList.remove(this.hidingClass);
    this.hidden = false;
  }
}
