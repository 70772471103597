import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    $('#editReturnReasonModal').on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);
      const editId = target.data('edit-id');

      this.forceShowLoadingMessage();

      this.stimulate('ReturnReasonsReflex#edit_body_modal', {
        editId: editId
      }).then(result => {
        this.forceHideLoadingMessage();
      });
    });
  }

  disconnect() {
    $('#editReturnReasonModal').off('shown.bs.modal');
  }
}
