import $ from 'jquery';

import { ApplyToOthersModal } from '../utils';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.initApplyOrCopyModal();
  }

  disconnect() {
    $(`#copyAdContainerModal${this.data.get('id')}`).off('shown.bs.modal');
  }

  initApplyOrCopyModal() {
    $(`#copyAdContainerModal${this.data.get('id')}`).on(
      'shown.bs.modal',
      event => {
        new ApplyToOthersModal(this.data.get('id'));
      }
    );
  }
}
