import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    // full morph
    document.addEventListener('cable-ready:before-morph', () => {
      $('select')
        .not('.flatpickr-monthDropdown-months')
        .selectpicker('destroy');
    });

    document.addEventListener('cable-ready:after-morph', () => {
      $('select')
        .not('.flatpickr-monthDropdown-months')
        .selectpicker('refresh');
    });
  }

  connect() {
    super.connect();
    $('select').not('.flatpickr-monthDropdown-months').selectpicker({
      dropupAuto: false
    });
  }
}
