import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    document.addEventListener('cable-ready:before-morph', () => {
      super.disconnect();
    });

    document.addEventListener('cable-ready:after-morph', () => {
      this.connect();
    });

    document.addEventListener('successfullyPaginationLoading', () => {
      this.connect();
    });

    super.initialize();
  }

  connect() {
    $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip();
    $.fn.popover &&
      $('[data-toggle="popover"]').popover({
        animated: 'fade',
        placement: 'left'
      });
  }
}
