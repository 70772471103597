import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  redirect(e) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('company_group_id', e.target.value);
    history.pushState(null, null, `?${queryParams}`);
    this.stimulate();
  }
}
