import ApplicationController from './application_controller';
import eyeSvg from '../../assets/images/eye_icon.svg';
import eyeCrossedSvg from '../../assets/images/eye_crossed_icon.svg';

export default class extends ApplicationController {
  static targets = ['input', 'button'];

  toggle(evt) {
    evt.preventDefault();

    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text';
      this.buttonTarget.firstElementChild.src = eyeCrossedSvg;
    } else {
      this.inputTarget.type = 'password';
      this.buttonTarget.firstElementChild.src = eyeSvg;
    }
  }
}
