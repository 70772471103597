import ApplicationController from './application_controller';
import { uploadFile } from '../utils';

export default class extends ApplicationController {
  formParams = {};

  connect() {
    $('#selectedMasterBlank').on('change', e => {
      this.showListFromParams();
    });
    super.connect();
  }

  disconnect() {
    $('#selectedMasterBlank').off('change');
  }

  removeElement(e) {
    const elementToRemove = $(e.target).parent().attr('data-master-blank-id');
    const formParams = $(this.element).serializeJSON();
    const allSelected = formParams.apply_settings.master_blanks_ids.filter(
      mb => mb !== elementToRemove
    );
    $('#selectedMasterBlank').val(allSelected).trigger('change');
  }

  async handleReplaceImage(event) {
    event.stopPropagation();
    const { target } = event;
    const { index } = target.dataset;
    const { directUploadUrl } = target.dataset;
    const upload = uploadFile(directUploadUrl);
    const blob = await upload(target.files[0]);

    this.formParams = $(this.element).serializeJSON();
    this.formParams.apply_settings.logo_parameters[index].image =
      blob['signed_id'];
    this.formParams.apply_settings.logo_parameters[index].image_key =
      blob['signed_id'];
    this.showListFromParams(this.formParams);
  }

  showListFromParams(formParams = null) {
    this.formParams = formParams ? formParams : $(this.element).serializeJSON();
    this.stimulate(
      'ApplySettingsModalReflex#show_master_blanks_table',
      this.formParams
    ).then(result => {});
  }

  submit(e) {
    const params = $(this.element).serializeJSON();
    e.target.setAttribute('disabled', 'disabled');

    e.preventDefault();
    this.stimulate('ApplySettingsModalReflex#apply_settings', params).then(
      result => {
        $('.select2').val(null).trigger('change');
        $('#applySettingsModal').modal('hide');
        $('#apply-settings-to-other-success').show();

        document.getElementById('applyModalButton').removeAttribute('disabled');
        this.decrementReflexesCount();
      }
    );
  }
}
