/**
 * Theme: Hyper - Responsive Bootstrap 4 Admin Dashboard
 * Author: Coderthemes
 * Module/App: Main Js
 */

import 'bootstrap';

// other imports
import 'metismenu';
import 'select2';
import 'simplebar';
import moment from 'moment';
import 'daterangepicker/daterangepicker.js';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import 'bootstrap-timepicker/js/bootstrap-timepicker.min';
import 'bootstrap-timepicker/css/bootstrap-timepicker.min.css';
import 'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min';
import 'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css';
import 'bootstrap-maxlength';
import hljs from 'highlightjs';

import LeftSideBar from './leftSidebar';
import Topbar from './topbar';
import RightBar from './rightbar';

// style
import '../scss/app.scss';
// in order to have dark theme, import app-dark.scss instead of app.scss
// import '../scss/app-dark.scss';

// in order to have modern theme, import app-modern.scss instead of app.scss
// import '../scss/app-modern.scss';

// in order to have creative theme, import app-creative.scss instead of app.scss
// import '../scss/app-creative.scss';

import '../scss/icons.scss';

// Layout and theme manager
const SIDEBAR_THEME_DEFAULT = 'default';
const SIDEBAR_THEME_LIGHT = 'light';
const SIDEBAR_THEME_DARK = 'dark';

const DEFAULT_CONFIG = {
  sideBarTheme: SIDEBAR_THEME_DARK,
  isBoxed: false,
  isCondensed: false,
  isScrollable: false,
  isDarkModeEnabled: false
};

class LayoutTheme {
  constructor() {
    this.body = $('body');
    this.window = $(window);
    this._config = {};
    this.defaultSelectedStyle = null;

    this.leftSideBar = new LeftSideBar();
    this.topbar = new Topbar();
  }

  /**
    * Preserves the config
    */
  _saveConfig(newConfig) {
    $.extend(this._config, newConfig);
    // sessionStorage.setItem('_HYPER_CONFIG_', JSON.stringify(this._config));
  }

  /**
   * Get the stored config
   */
  _getStoredConfig() {
    var bodyConfig = this.body.data('layoutConfig');
    var config = DEFAULT_CONFIG;
    if (bodyConfig) {
      config['sideBarTheme'] = bodyConfig['leftSideBarTheme'];
      config['isBoxed'] = bodyConfig['layoutBoxed'];
      config['isCondensed'] = bodyConfig['leftSidebarCondensed'];
      config['isScrollable'] = bodyConfig['leftSidebarScrollable'];
      config['isDarkModeEnabled'] = bodyConfig['darkMode'];
    }
    return config;
  }

  /**
  * Apply the given config and sets the layout and theme
  */
  _applyConfig() {
    // getting the saved config if available
    this._config = this._getStoredConfig();

    // init menu
    this.leftSideBar.init();

    // sets the theme
    switch (this._config.sideBarTheme) {
      case SIDEBAR_THEME_DARK: {
        this.activateDarkSidebarTheme();
        break;
      }
      case SIDEBAR_THEME_LIGHT: {
        this.activateLightSidebarTheme();
        break;
      }
    }

    // enable or disable the dark mode
    if (this._config.isDarkModeEnabled)
      this.activateDarkMode();
    else
      this.deactivateDarkMode();

    // sets the boxed
    if (this._config.isBoxed) this.activateBoxed();

    // sets condensed view
    if (this._config.isCondensed) this.activateCondensedSidebar();

    // sets scrollable navbar
    if (this._config.isScrollable) this.activateScrollableSidebar();
  }

  /**
   * Initilizes the layout
   */
  _adjustLayout() {
    // in case of small size, add class enlarge to have minimal menu
    if (this.window.width() >= 750 && this.window.width() <= 1028) {
      this.activateCondensedSidebar(true);
    } else {
      var config = this._getStoredConfig();
      if (!config.isCondensed && !config.isScrollable)
        this.deactivateCondensedSidebar();
    }
  }

  /**
   * Activate fluid mode
   */
  activateFluid() {
    this._saveConfig({ isBoxed: false });
    this.body.attr('data-layout-mode', 'fluid');
  }

  /**
   * Activate boxed mode
   */
  activateBoxed() {
    this._saveConfig({ isBoxed: true });
    this.body.attr('data-layout-mode', 'boxed');
  }

  /**
   * Activates the condensed side bar
   */
  activateCondensedSidebar(ignoreToStore) {
    if (!ignoreToStore) {
      this._saveConfig({
        isCondensed: true,
        isScrollable: false
      });
    }
    this.leftSideBar.activateCondensedSidebar();
  }

  /**
   * Deactivates the condensed side bar
   */
  deactivateCondensedSidebar() {
    this._saveConfig({ isCondensed: false });
    this.leftSideBar.deactivateCondensedSidebar();
  }

  /**
   * Activates the scrollable sidenar
   */
  activateScrollableSidebar() {
    this._saveConfig({ isScrollable: true, isCondensed: false });
    this.leftSideBar.activateScrollableSidebar();
  }

  /**
   * Deactivates the scrollable sidenar
   */
  deactivateScrollableSidebar() {
    this._saveConfig({ isScrollable: false });
    this.leftSideBar.deactivateScrollableSidebar();
  }

  /**
   * Activates the default theme
   */
  activateDefaultSidebarTheme() {
    this.leftSideBar.activateDefaultTheme();
    this._saveConfig({ sideBarTheme: SIDEBAR_THEME_DEFAULT });
  }

  /**
   * Activates the light theme
   */
  activateLightSidebarTheme() {
    // this._resetLayout();
    this.leftSideBar.activateLightTheme();
    this._saveConfig({ sideBarTheme: SIDEBAR_THEME_LIGHT });
  }

  /**
   * Activates the dark theme
   */
  activateDarkSidebarTheme() {
    // this._resetLayout();
    this.leftSideBar.activateDarkTheme();
    this._saveConfig({ sideBarTheme: SIDEBAR_THEME_DARK });
  }

  /**
   * toggle the dark mode
   */
  activateDarkMode() {
    $("#light-style").attr("disabled", true);
    $("#dark-style").attr("disabled", false);
    this.leftSideBar.activateDarkTheme();
    this._saveConfig({ isDarkModeEnabled: true, sideBarTheme: SIDEBAR_THEME_DARK });
  }

  /**
   * Deactivate the dark mode
   */
  deactivateDarkMode() {
    $("#light-style").attr("disabled", false);
    $("#dark-style").attr("disabled", true);
    this._saveConfig({ isDarkModeEnabled: false });
  }

  /**
   * Clear out the saved config
   */
  clearSavedConfig() {
    this._config = DEFAULT_CONFIG;
  }

  /**
   * Gets the config
   */
  getConfig() {
    return this._config;
  }

  /**
   * Reset to default
   */
  reset(callback) {
    this.clearSavedConfig();
    if ($("#main-style-container").length) {
      this.defaultSelectedStyle = $("#main-style-container").attr('href');
    }
    this.deactivateCondensedSidebar();
    this.deactivateDarkMode();
    this.activateDefaultSidebarTheme();
    this.activateFluid();
    // calling the call back to let the caller know that it's done
    callback();
  }

  /**
   *
   */
  init() {
    if ($("#main-style-container").length) {
      this.defaultSelectedStyle = $("#main-style-container").attr('href');
    }

    // initilize the menu
    this._applyConfig();

    // adjust layout based on width
    // this._adjustLayout();

    // on window resize, make menu flipped automatically
    // this.window.on('resize', function (e) {
    //   e.preventDefault();
    //   this._adjustLayout();
    // });

    // topbar
    this.topbar.init();
  }
}

// App
class HyperApp {
  constructor() {
    this._body = $('body');
    this._window = $(window);

    this.wrapperEl = $('#mainwrapper');

    // remove loading
    window.setTimeout(() => {
      window.setTimeout(() => {
        document.body.classList.remove('loading');
      });
    });

    // rightbar
    this.rightBar = new RightBar();

    // layout manager
    this.layoutTheme = new LayoutTheme();
  }

  /**
   * Initlizes the plugins
   */
  initPlugins() {
    // Select2
    $('[data-toggle="select2"]').select2();

    // input mask
    $('[data-toggle="input-mask"]').each(function (idx, obj) {
      var maskFormat = $(obj).data('maskFormat');
      var reverse = $(obj).data('reverse');
      if (reverse != null) $(obj).mask(maskFormat, { reverse: reverse });
      else $(obj).mask(maskFormat);
    });

    // Datetime and date range picker
    var defaultOptions = {
      cancelClass: 'btn-light',
      applyButtonClasses: 'btn-success',
    };

    // date pickers
    $('[data-toggle="date-picker"]').each(function (idx, obj) {
      var objOptions = $.extend({}, defaultOptions, $(obj).data());
      $(obj).daterangepicker(objOptions);
    });

    //date pickers ranges only
    var start = moment().subtract(29, 'days');
    var end = moment();
    var defaultRangeOptions = {
      startDate: start,
      endDate: end,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month'),
        ],
      },
    };

    $('[data-toggle="date-picker-range"]').each(function (idx, obj) {
      var objOptions = $.extend({}, defaultRangeOptions, $(obj).data());
      var target = objOptions['targetDisplay'];
      //rendering
      $(obj).daterangepicker(objOptions, function (start, end) {
        if (target)
          $(target).html(
            start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
          );
      });
    });

    // time picker
    defaultOptions = {
      showSeconds: true,
      icons: {
        up: 'mdi mdi-chevron-up',
        down: 'mdi mdi-chevron-down',
      },
    };

    // time picker
    $('[data-toggle="timepicker"]').each(function (idx, obj) {
      var objOptions = $.extend({}, defaultOptions, $(obj).data());
      $(obj).timepicker(objOptions);
    });

    // touchspin
    $('[data-toggle="touchspin"]').each(function (idx, obj) {
      var objOptions = $.extend({}, {}, $(obj).data());
      $(obj).TouchSpin(objOptions);
    });

    // maxlength
    defaultOptions = {
      warningClass: 'badge badge-success',
      limitReachedClass: 'badge badge-danger',
      separator: ' out of ',
      preText: 'You typed ',
      postText: ' chars available.',
      placement: 'bottom',
    };

    // maxlength
    $('[data-toggle="maxlength"]').each(function (idx, obj) {
      var objOptions = $.extend({}, defaultOptions, $(obj).data());
      $(obj).maxlength(objOptions);
    });

    // password
    $("[data-password]").on('click', function () {
      if ($(this).attr('data-password') == "false") {
        $(this).siblings("input").attr("type", "text");
        $(this).attr('data-password', 'true');
        $(this).addClass("show-password");
      } else {
        $(this).siblings("input").attr("type", "password");
        $(this).attr('data-password', 'false');
        $(this).removeClass("show-password");
      }
    });

    //initializing tooltip
    $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip();

    //initializing popover
    $.fn.popover && $('[data-toggle="popover"]').popover();

    //initializing toast
    $.fn.toast && $('[data-toggle="toast"]').toast();

    //initializing Slimscroll
    $.fn.slimScroll &&
      $('.slimscroll').slimScroll({
        height: 'auto',
        position: 'right',
        size: '5px',
        touchScrollStep: 20,
        color: '#9ea5ab',
      });

    //initializing form validation
    $('.needs-validation').on('submit', function (event) {
      $(this).addClass('was-validated');
      if ($(this)[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      return true;
    });

    // syntax highlighting

    $(document).ready(function () {
      var text = '';
      document.querySelectorAll('pre span.escape').forEach(function (element) {
        if (element.classList.contains('escape')) {
          text = element.innerText;
        } else {
          text = element.innerText;
        }
        text = text.replace(/^\n/, '').trimRight(); // goodbye starting whitespace
        var to_kill = Infinity;
        var lines = text.split('\n');
        for (var i = 0; i < lines.length; i++) {
          if (!lines[i].trim()) {
            continue;
          }
          to_kill = Math.min(lines[i].search(/\S/), to_kill);
        }
        var out = [];
        for (i = 0; i < lines.length; i++) {
          out.push(
            lines[i].replace(new RegExp('^ {' + to_kill + '}', 'g'), '')
          );
        }
        element.innerText = out.join('\n');
      });

      document.querySelectorAll('pre span.escape').forEach(function (block) {
        hljs.highlightBlock(block);
      });
    });
  }

  /**
     * Activates the default theme
     */
  activateDefaultSidebarTheme() {
   this.layoutTheme.activateDefaultSidebarTheme();
  }

  /**
   * Activates the light theme
   */
  activateLightSidebarTheme() {
   this.layoutTheme.activateLightSidebarTheme();
  }

  /**
   * Activates the dark theme
   */
  activateDarkSidebarTheme() {
   this.layoutTheme.activateDarkSidebarTheme();
  }

  /**
   * Activates the condensed sidebar
   */
  activateCondensedSidebar() {
   this.layoutTheme.activateCondensedSidebar();
  }

  /**
   * Deactivates the condensed sidebar
   */
  deactivateCondensedSidebar() {
   this.layoutTheme.deactivateCondensedSidebar();
  }

  /**
   * Activates the scrollable sidebar
   */
  activateScrollableSidebar() {
   this.layoutTheme.activateScrollableSidebar();
  }

  /**
   * Deactivates the scrollable
   */
  deactivateScrollableSidebar() {
   this.layoutTheme.deactivateScrollableSidebar();
  }

  /**
   * Activates the boxed mode
   */
  activateBoxed() {
   this.layoutTheme.activateBoxed();
  }

  /**
   * Activate the fluid mode
   */
  activateFluid() {
   this.layoutTheme.activateFluid();
  }

  /**
   * Toggle the dark mode
   */
  activateDarkMode() {
   this.layoutTheme.activateDarkMode();
  }

  /**
   * Deactivate the dark mode
   */
  deactivateDarkMode() {
   this.layoutTheme.deactivateDarkMode();
  }

  /**
   * clear the saved layout related settings
   */
  clearSavedConfig() {
   this.layoutTheme.clearSavedConfig();
  }

  /**
   * Gets the layout config
   */
  getLayoutConfig() {
    return this.layoutTheme.getConfig();
  }

  /**
   * Reset the layout
   */
  resetLayout(callback) {
   this.layoutTheme.reset(callback);
  }

  /**
   * Initilizes the various things including layout, sidebar, etc
   */
  init() {
    this.layoutTheme.init();

    // plugins
    this.initPlugins();

    // remove loading
    setTimeout(function () {
      document.body.classList.remove('loading');
    }, 400);

    this.rightBar.init();

    // showing the sidebar on load if user is visiting the page first time only
    var bodyConfig = this._body.data('layoutConfig');
    if (window.sessionStorage && bodyConfig && bodyConfig.hasOwnProperty('showRightSidebarOnStart') && bodyConfig['showRightSidebarOnStart']) {
      var alreadyVisited = sessionStorage.getItem("_HYPER_VISITED_");
      if (!alreadyVisited) {
        this.rightBar.toggleRightSideBar();
        sessionStorage.setItem("_HYPER_VISITED_", true);
      }
    }

    // loader - Preloader
    $(window).on('load', function () {
      $('#status').fadeOut();
      $('#preloader').delay(350).fadeOut('slow');
    });
  }
}

$(document).on('turbolinks:load', function() {
  // init app
  window.hyperApp = new HyperApp();
  window.hyperApp.init();
})
