import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  initialize() {
    this.nextId = parseInt(this.element.getAttribute('data-items')) + 2;
  }

  add_association(event) {
    event.preventDefault();

    const wrapper = event.target.closest('.nested-elements-wrapper');
    const template = wrapper.querySelector('template');
    const elements = wrapper.querySelector('.nested-elements-items');

    const content = template.innerHTML
      .replace(/TEMPLATE_RECORD_CHILD_INDEX/g, this.nextId)
      .replace(/TEMPLATE_RECORD_NO/g, this.nextId);

    elements.insertAdjacentHTML('beforeend', content);
    this.nextId++;
    this.element.setAttribute('data-items', this.nextId);
  }

  remove_association(event) {
    event.preventDefault();
    const item = event.target.closest('.nested-fields');

    if (event.currentTarget.dataset.id) {
      item.querySelector("input[name*='_destroy']").value = 'true';
      // If quantity was empty, the validation would fail even though this price break is getting deleted
      item.querySelector("input[name*='quantity']").value = '0';
      item.style.display = 'none';
    } else {
      item.remove();
    }
  }
}
