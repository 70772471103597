import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    $(this.element).on('focusout', function () {
      const dataMask = this.getAttribute('data-mask-format');
      const allowNil = this.getAttribute('data-mask-allow-nil');

      if (this.value.length === 0) {
        if (allowNil !== 'true') {
          this.value = dataMask.replace('#', '0');
        }
      } else if (!this.value.includes('.')) {
        const decimalPoints = dataMask.split('0').length - 1;
        this.value = parseFloat(this.value).toFixed(decimalPoints);
      }
    });
  }
}
