import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'editSubmitBtn',
    'fileInput',
    'previewArea',
    'previewContainer',
    'submitBtn',
    'uploadArea'
  ];

  files = [];
  tagsSelector = null;
  newImageModal = null;

  destroyPath = '';
  imageToDelete = '';
  deletionSubmitBtn = null;
  deletionModal = null;

  imageToEdit = null;
  editModal = null;
  editTagsSelector = null;
  initialSelectedTags = null;

  filtersSelector = null;

  connect() {
    this.newImageModal = $('#new-bg-img-modal');
    this.tagsSelector = $('#tags-selector');

    this.deletionModal = $('#delete-bg-img-modal');
    this.deletionSubmitBtn = $('#deletion-submit-btn');

    this.editModal = $('#edit-bg-img-modal');
    this.editTagsSelector = $('#edit-tags-selector');

    this.newImageModal.on('shown.bs.modal', () => {
      this.tagsSelector.trigger('change');
    });

    this.newImageModal.on('hidden.bs.modal', () => {
      this.resetNewImageForm();
    });

    this.tagsSelector.on('change', () => {
      this.checkIfNewImageFormFulfilled();
    });

    this.deletionModal.on('shown.bs.modal', event => {
      const target = $(event.relatedTarget);

      this.destroyPath = target.data('destroy-link');
      this.imageToDelete = target.data('image-id');
    });

    this.deletionModal.on('hidden.bs.modal', () => {
      this.deletionSubmitBtn.prop('disabled', false);
    });

    this.deletionSubmitBtn.on('click', () => {
      this.submitImageDeletion();
    });

    this.editTagsSelector.on('change', () => {
      const selectedTags = this.editTagsSelector.select2('data');
      const isNoTags = selectedTags.length === 0;

      this.editSubmitBtnTarget.disabled = isNoTags;
    });

    this.filtersSelector = $('#filters-selector');
    this.filtersSelector.on('change', event =>
      this.changeCategories({ event })
    );
    this.filtersSelector.on('select2:selecting', event => {
      if (event.params.args.data.id === 'show_all') {
        event.preventDefault();
        this.changeCategories({ categoriesList: [] });
      }
    });
  }

  disconnect() {
    this.newImageModal.off('shown.bs.modal');
    this.newImageModal.off('hidden.bs.modal');
    this.tagsSelector.off('change');
    this.deletionModal.off('shown.bs.modal');
    this.deletionModal.off('hidden.bs.modal');
    this.deletionSubmitBtn.off('click');
    this.editTagsSelector.off('change');
    this.filtersSelector.off('change');
    this.filtersSelector.off('select2:selecting');
  }

  onFileInputChange() {
    const selectedFiles = Array.from(this.fileInputTarget.files);

    const newFiles = selectedFiles.filter(
      selectedFile =>
        !this.files.some(
          file =>
            file.name === selectedFile.name &&
            file.size === selectedFile.size &&
            file.lastModified === selectedFile.lastModified
        )
    );

    this.files.push(...newFiles);

    this.displayImagesPreview(newFiles);

    this.checkIfNewImageFormFulfilled();
  }

  displayImagesPreview(files) {
    if (!files.length) {
      return;
    }

    for (const file of files) {
      const { lastModified, name, size } = file;

      this.previewAreaTarget.classList.remove('hidden');

      const newPreviewContainer = this.previewContainerTarget.cloneNode(true);
      newPreviewContainer.classList.remove('hidden');
      newPreviewContainer.setAttribute('data-name', name);
      newPreviewContainer.setAttribute('data-size', size);
      newPreviewContainer.setAttribute('data-lastModified', lastModified);

      const lastPreviewContainer = this.previewContainerTargets[
        this.previewContainerTargets.length - 1
      ];
      lastPreviewContainer.after(newPreviewContainer);

      const img = newPreviewContainer.querySelector('img');
      img.src = URL.createObjectURL(file);

      const filenameContainer = newPreviewContainer.querySelector('.filename');
      filenameContainer.textContent = file.name;
    }
  }

  async submitNewImageForm() {
    this.submitBtnTarget.disabled = true;

    const selectedTags = this.tagsSelector.select2('data');
    const selectedTagsIds = selectedTags
      .filter(tag => tag.selected)
      .map(tag => ({ tag_id: tag.id }));

    const images = this.files.map(file => ({
      source: file,
      image_tags_attributes: selectedTagsIds
    }));

    const body = {
      image: images
    };

    const formData = new FormData();
    this.buildFormData(formData, body);

    const authenticityToken = this.getCSRFtoken();

    const endpoint = '/style_builder/images_library/images';

    const response = await fetch(endpoint, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': authenticityToken
      }
    });

    const result = await response.json();

    if (!response.ok) {
      this.submitBtnTarget.disabled = false;

      throw new Error(`Response not OK. ${response.status}: ${result}`);
    }

    this.refreshPage();
  }

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  removeFileFromInput(event) {
    const { target } = event;

    const previewContainer = target.closest('.preview-container');

    const filename = previewContainer.getAttribute('data-name');
    const size = previewContainer.getAttribute('data-size');
    const lastModified = previewContainer.getAttribute('data-lastModified');

    const fileToRemoveIndex = this.files.findIndex(
      file =>
        file.name === filename &&
        file.size === Number(size) &&
        file.lastModified === Number(lastModified)
    );

    this.files.splice(fileToRemoveIndex, 1);
    previewContainer.remove();
    if (this.files.length === 0) {
      this.previewAreaTarget.classList.add('hidden');
    }

    this.checkIfNewImageFormFulfilled();
  }

  onTagsSelectorChange() {
    this.checkIfNewImageFormFulfilled();
  }

  checkIfNewImageFormFulfilled() {
    if (this.files.length && this.tagsSelector.select2('data').length) {
      this.submitBtnTarget.disabled = false;
    } else {
      this.submitBtnTarget.disabled = true;
    }
  }

  resetNewImageForm() {
    this.fileInputTarget.value = '';
    this.files = [];

    this.tagsSelector.val(null).trigger('change');

    this.previewContainerTargets.forEach(container => {
      if (!$(container).hasClass('hidden')) {
        container.remove();
      }
    });
    this.previewAreaTarget.classList.add('hidden');
  }

  openNewImageModal() {
    this.newImageModal.modal('show');
  }

  async submitImageDeletion() {
    this.deletionSubmitBtn.prop('disabled', true);

    const authenticityToken = this.getCSRFtoken();

    const response = await fetch(this.destroyPath, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': authenticityToken
      }
    });

    if (!response.ok) {
      this.deletionSubmitBtn.prop('disabled', false);

      const result = await response.json();
      throw new Error(`Response not OK. ${response.status}: ${result}`);
    }

    this.deletionModal.modal('hide');
    this.refreshPage();
  }

  changeCategories({ event, categoriesList }) {
    let categories;
    if (event) {
      categories = $(event.target)
        .select2('data')
        .filter(({ id, selected }) => id !== 'show_all' && selected)
        .map(({ id }) => id);
    } else {
      categories = categoriesList;
    }

    const urlParams = [
      location.protocol,
      '//',
      location.host,
      location.pathname
    ];
    categories.forEach((category, index) => {
      if (index === 0) {
        urlParams.push('?');
      }
      urlParams.push(
        `categories[]=${category}${index !== categories.length - 1 ? '&' : ''}`
      );
    });
    const url = urlParams.join('');

    this.refreshPage(url);
  }

  async getImageData() {
    const authenticityToken = this.getCSRFtoken();

    const endpoint = `/style_builder/images_library/images/${this.editedImageId}`;

    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': authenticityToken
      }
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(`Response not OK. ${response.status}: ${result}`);
    }

    return await response.json();
  }

  updateModalContent(imageToEdit) {
    const { id, image_tags, source } = imageToEdit;
    const tagsIds = image_tags.map(imageTag => imageTag.tag_id);
    this.initialSelectedTags = image_tags.map(({ id, tag_id }) => ({
      id,
      tag_id
    }));

    const [img] = this.editModal.find('.preview img');
    this.editModal.attr('data-image-id', id);
    img.src = source;

    this.editTagsSelector.val(tagsIds);
    this.editTagsSelector.trigger('change');
  }

  async submitEditForm() {
    this.editSubmitBtnTarget.disabled = true;

    const selectedTags = this.editTagsSelector
      .select2('data')
      .filter(({ selected }) => selected)
      .map(({ id }) => ({ tag_id: Number(id) }));

    const newTags = selectedTags.filter(
      ({ tag_id }) => !this.initialSelectedTags.includes(tag_id)
    );

    this.initialSelectedTags.forEach(tag => {
      if (!selectedTags.includes(tag.tag_id)) {
        tag._destroy = true;
      }
    });

    const tags = this.initialSelectedTags.concat(newTags);

    const body = {
      image: {
        image_tags_attributes: tags
      }
    };

    const formData = new FormData();
    this.buildFormData(formData, body);

    const authenticityToken = this.getCSRFtoken();

    const imageId = this.editModal.data('imageId');

    const endpoint = `/style_builder/images_library/images/${imageId}`;

    const response = await fetch(endpoint, {
      method: 'PUT',
      body: formData,
      headers: {
        'X-CSRF-Token': authenticityToken
      }
    });

    const result = await response.json();

    if (!response.ok) {
      this.editSubmitBtnTarget.disabled = false;

      throw new Error(`Response not OK. ${response.status}: ${result}`);
    }

    this.refreshPage();
  }

  async openEditModal(event) {
    const { target } = event;
    this.editedImageId = target.dataset.imageId;

    const imageToEdit = await this.getImageData();
    this.updateModalContent(imageToEdit);

    this.editModal.modal('show');
    this.editSubmitBtnTarget.disabled = true;
  }

  refreshPage(url) {
    // eslint-disable-next-line no-undef
    Turbolinks.visit(url || window.location.href, { action: 'replace' });
  }
}
