import { delay, setAttributes } from '../utils';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['area', 'hint', 'backdrop'];
  static values = {
    step: Number,
    options: Array,
    context: String,
    contexts: Object
  };

  connect() {
    super.connect();

    Object.keys(this.contextsValue).forEach(key =>
      localStorage.setItem(key, this.contextsValue[key])
    );
  }

  async getHint(step) {
    await delay(300);

    const rect = this.areaTargets
      .find(({ dataset }) => Number(dataset.step) === step)
      .getBoundingClientRect();

    return { ...this.optionsValue[step], rect, step };
  }

  render({ rect, title, description, action, position, startStep, endStep }) {
    const root = document.querySelector('[data-controller*="hints"]');

    const hint = document.createElement('div');
    setAttributes({ class: `hint ${position}`, ['data-hints-target']: 'hint' })(
      hint
    );

    const hasSteps = startStep !== undefined && endStep !== undefined;

    hint.innerHTML = `
    <div class="title">${title}</div>
    <div class="description">${description}</div>
    <div class="actions" style="justify-content: ${
      hasSteps ? 'space-between' : 'flex-end'
    }">
      ${
        hasSteps
          ? `<span>
            Step ${startStep}/${endStep}
          </span>`
          : ''
      }
      <button class="btn btn-primary btn-sm button" data-action="click->hints#${action}">OK</button>
    </div>
    `;

    const backdrop = document.createElement('div');
    setAttributes({
      class: 'backdrop',
      ['data-action']: `click->hints#${action}`,
      ['data-hints-target']: 'backdrop'
    })(backdrop);

    root.appendChild(hint);
    root.appendChild(backdrop);

    const verticalOffset = 12;
    const horizontalOffset = 18;

    let left, top;

    switch (position) {
      case 'top-left':
        {
          left =
            rect.right -
            document.documentElement.scrollLeft -
            hint.clientWidth +
            verticalOffset;
          top =
            rect.top +
            document.documentElement.scrollTop -
            hint.clientHeight -
            verticalOffset;
        }
        break;
      case 'top-right':
        {
          left =
            rect.left - document.documentElement.scrollLeft - horizontalOffset;
          top =
            rect.top +
            document.documentElement.scrollTop -
            hint.clientHeight -
            verticalOffset;
        }
        break;
      case 'bottom-right':
        {
          left =
            rect.left - document.documentElement.scrollLeft - verticalOffset;
          top =
            rect.bottom + document.documentElement.scrollTop + verticalOffset;
        }
        break;
      default: {
        left = 0;
        top = 0;
      }
    }

    this.hintTarget.style = `top:${top}px;left:${left}px`;
  }

  async open({ target: { dataset } }) {
    this.close();

    this.stepValue = Number(dataset.step);

    const hint = await this.getHint(this.stepValue);
    this.render(hint);
  }

  close() {
    const root = document.querySelector('[data-controller*="hints"]');

    if (this.hasHintTarget) {
      root.removeChild(this.hintTarget);
    }

    if (this.hasBackdropTarget) {
      root.removeChild(this.backdropTarget);
    }
  }

  async next() {
    this.close();

    const hint = await this.getHint(++this.stepValue);
    this.render(hint);
  }

  updateToolsTourGuide() {
    this.stimulate(
      'ToolsTourGuideReflex#update_guide_complition',
      this.contextValue
    );
    // ToolsTourGuideReflex#update_guide_complition is a morph: none reflex
    // morph: none reflexes have a different lifecycle, we need to manually decrement reflexes count
    this.decrementReflexesCount();
  }

  save() {
    this.close();

    localStorage.setItem(this.contextValue, true);
    this.updateToolsTourGuide();
  }
}
