// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'controllers';
import './../themes/hyper/core/hyper';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import '../../assets/stylesheets/application';
import {
  restoreScrollPosition,
  saveScrollPosition
} from '../utils/scrollPositionHandler';

// eslint-disable-next-line no-unused-vars
const images = require.context('../../assets/images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = name => images(name, true);

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('jquery-serializejson');
require('bootstrap-select');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//

// fires once after the initial page load, and again after every Turbolinks visit.
// Access visit timing metrics with the event.data.timing object.
$(document).on('turbolinks:load', () => {
  $('select').not('.flatpickr-monthDropdown-months').selectpicker({
    dropupAuto: false
  });

  blockInvalidCharsForNumberInput();
  blockInputSubmitWhenEnter();
  restoreScrollPosition();
});

// fires before Turbolinks saves the current page to cache.
$(document).on('turbolinks:before-cache', () => {
  $('select').not('.flatpickr-monthDropdown-months').selectpicker('destroy');
});

// fires before rendering the page. Access the new <body> element with event.data.newBody
$(document).on('turbolinks:before-render', () => {
  // Keep side-menu component state
  const sideMenuTag = document.querySelector("[data-controller='side-menu']");
  if (!sideMenuTag) return;

  const sideMenuController = sideMenuTag.sideMenu;

  if (sideMenuController.hidden) {
    event.data.newBody
      .querySelector("[data-controller='side-menu']")
      .classList.add(sideMenuController.hidingClass);
  } else {
    event.data.newBody
      .querySelector("[data-controller='side-menu']")
      .classList.remove(sideMenuController.hidingClass);
  }

  saveScrollPosition();
});

$(document).on('submit', saveScrollPosition);

// Use this function after override submit by using event.preventDefault();
// form.submit() function not trigger event submit so the event must be triggered again
// https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
Element.prototype.submitForm = function () {
  const submitEvent = document.createEvent('Event');
  submitEvent.initEvent('submit', true, false);
  this.dispatchEvent(submitEvent);
  this.submit();
};

const blockInvalidCharsForNumberInput = () => {
  const numberInputs = document.querySelectorAll('input[type="number"]');
  const invalidChars = ['+', 'e'];

  for (let i = 0; i < numberInputs.length; i++) {
    const numberInput = numberInputs[i];

    numberInput.addEventListener('keydown', e => {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }
};

const blockInputSubmitWhenEnter = () => {
  const isUserOnAuthPage =
    document.body.firstElementChild.className === 'authentication-bg';

  if (!isUserOnAuthPage) {
    document.addEventListener('keypress', e => {
      if (clickedEnter(e) && eventTargetIsInput(e)) {
        e.preventDefault();
      }
    });
  }
};

const clickedEnter = e => e.key === 'Enter';
const eventTargetIsInput = e => e.target.tagName.toLowerCase() === 'input';
