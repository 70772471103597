import ApplicationController from './application_controller';

export default class extends ApplicationController {
  accessLevelUpdate(event) {
    const { value } = event.target;
    const { index } = event.target.dataset;

    this.companyGroupUpdate(value, index);
    this.billToAccountUpdate(value, index);
  }

  companyGroupUpdate(value, index) {
    // DashboardToolAccess::LEVEL_1, DashboardToolAccess::LEVEL_4, DashboardToolAccess::LEVEL_6
    if (['1', '4', '6'].includes(value)) {
      this.unsetDisable(index, 'company_group');
    } else {
      this.setDisable(index, 'company_group');
    }
  }

  billToAccountUpdate(value, index) {
    // DashboardToolAccess::LEVEL_1, DashboardToolAccess::LEVEL_4
    if (['1', '4'].includes(value)) {
      this.unsetDisable(index, 'bill_to_account');
    } else {
      this.setDisable(index, 'bill_to_account');
    }
  }

  unsetDisable(index, field) {
    $(
      `select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`
    ).prop('disabled', false);
    $(`select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`)
      .siblings()
      .removeClass('disabled');
    $(`select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`)
      .closest('.dropdown.bootstrap-select.select')
      .removeClass('disabled');
    $(`select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`)
      .closest(`.user_dashboard_tool_accesses_${field}`)
      .removeClass('disabled');
  }

  setDisable(index, field) {
    $(
      `select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`
    ).prop('disabled', true);
    $(`select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`)
      .siblings()
      .addClass('disabled');
    $(`select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`)
      .closest('.dropdown.bootstrap-select.select')
      .addClass('disabled');
    $(`select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`)
      .closest(`.user_dashboard_tool_accesses_${field}`)
      .addClass('disabled');
  }
}
