import { DirectUpload } from '@rails/activestorage';

const uploadFile = url => file =>
  new Promise((resolve, reject) =>
    new DirectUpload(file, url).create((error, blob) =>
      error ? reject(error) : resolve(blob)
    )
  );

export default uploadFile;
