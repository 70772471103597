const adCreatorHints = ({ isIntro, inch, position, columns, isShopPage }) => {
  const withoutTriggerButtonLink = Number(inch) < 3;
  const endIntroStep = withoutTriggerButtonLink || !isShopPage ? 8 : 9;
  const endStep = withoutTriggerButtonLink || !isShopPage ? 3 : 4;

  const hints = [
    {
      action: columns > 0 ? 'next' : 'close',
      title: 'Choose Layout',
      description: 'Choose how many Ads will be visible in the container.',
      position: 'top-right',
      ...(isIntro
        ? { startStep: 1, endStep: endIntroStep }
        : { startStep: 1, endStep: 3 })
    },
    {
      action: 'next',
      title: 'Choose Spacing',
      description: 'Spacing is allowed for 2 and 3 Ads Layout',
      position: 'top-right',
      ...(isIntro
        ? { startStep: 2, endStep: endIntroStep }
        : { startStep: 2, endStep: 3 })
    },
    {
      action: isIntro ? 'next' : 'close',
      title: 'Choose Ads Height',
      description: 'Choose the height of the container and Ads inside it',
      position: 'top-left',
      ...(isIntro
        ? { startStep: 3, endStep: endIntroStep }
        : { startStep: 3, endStep: 3 })
    },
    {
      action: 'close',
      title: 'Drag and Drop',
      description:
        'Drag chosen Template and drop it below in the Order section.',
      position: 'top-right',
      ...(isIntro ? { startStep: 4, endStep: endIntroStep } : {})
    },
    {
      action: 'close',
      title: 'Click on Ad',
      description:
        'Click on the chosen element to start edition in Preview below.',
      position: 'top-right',
      ...(isIntro ? { startStep: 5, endStep: endIntroStep } : {})
    },
    {
      action: 'next',
      description: 'Click on the background to edit it.',
      title: 'Edit Background',
      position: `top-${
        position === 'center' || position === 'left' ? 'left' : 'right'
      }`,
      ...(isIntro
        ? { startStep: 6, endStep: endIntroStep }
        : { startStep: 1, endStep: endStep })
    },
    {
      title: 'Edit Box',
      description: 'Click on the box to edit it.',
      action: 'next',
      position: `top-${
        position === 'center' || position === 'left' ? 'right' : 'left'
      }`,
      ...(isIntro
        ? { startStep: 7, endStep: endIntroStep }
        : { startStep: 2, endStep: endStep })
    },
    {
      title: 'Edit Headers',
      description: 'Click on the text to edit it',
      action: isIntro
        ? withoutTriggerButtonLink || !isShopPage
          ? 'save'
          : 'next'
        : withoutTriggerButtonLink || !isShopPage
        ? 'close'
        : 'next',
      position: 'top-right',
      ...(isIntro
        ? { startStep: 8, endStep: endIntroStep }
        : { startStep: 3, endStep: endStep })
    }
  ];

  return withoutTriggerButtonLink
    ? hints
    : [
        ...hints,
        {
          title: 'Adding Hyperlink',
          description: 'Click on the button to add hyperlink.',
          action: isIntro ? 'save' : 'close',
          position: 'top-right',
          ...(isIntro
            ? { startStep: endIntroStep, endStep: endIntroStep }
            : { startStep: endStep, endStep: endStep })
        }
      ];
};

export default adCreatorHints;
