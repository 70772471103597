import ApplicationController from './application_controller';

export default class extends ApplicationController {
  validate_and_submit(e) {
    e.preventDefault();
    this.stimulate('CategoryToolFormReflex#validate').then(result => {
      if (this.element.getAttribute('data-form-valid') === 'true') {
        this.element.submitForm();
      }
    });
  }
}
