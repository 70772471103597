import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    // This hangs a reference to the Stimulus controller instance off the DOM element that has the same name as the controller itself.
    // Now, if you can get a reference to the element, you can access element.controllerName anywhere you need it.
    // https://leastbad.com/stimulus-power-move
    // eg. document.querySelector("[data-controller='loading-alert']").loadingAlert.show();
    const identifier = this.identifier.replace(/-./g, x => x[1].toUpperCase());
    this.element[identifier] = this;
  }

  initialize() {
    document.addEventListener('stimulus-reflex:before', event => {
      setTimeout(() => {
        if (!this.reflexFinished()) {
          this.show();
        }
      }, 300);
    });

    document.addEventListener('stimulus-reflex:finalize', event => {
      if (this.reflexFinished()) {
        this.hide();
      }
    });

    document.addEventListener('submit', event => {
      setTimeout(() => {
        this.show();
      }, 300);
    });
  }

  hide() {
    this.element.classList.add('d-none');
  }

  show() {
    this.element.classList.remove('d-none');
  }

  reflexFinished() {
    return parseInt(document.body.getAttribute('data-reflexes')) === 0;
  }
}
