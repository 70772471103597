import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'checkbox',
    'received',
    'submitReceiptButton',
    'allbox',
    'alert',
    'alertCount'
  ];
  items = [];

  connect() {
    super.connect();
  }

  initialize() {
    if (this.element.dataset.precheckAll) {
      this.allboxTarget.checked = true;
      this.setAllCheckboxes(true);
    }
  }

  toggle(event) {
    if (event.target.checked) {
      this.addItem(event.target);
    } else {
      this.items.splice(this.findPOItemIndex(event.target.dataset), 1);
    }
    this.enableSubmitReceiptButton(this.items.length !== 0);
    this.enableAlert(this.items.length !== 0);
  }

  addItem(item) {
    const { itemId, itemName, itemOrderedQty, itemPoNumber } = item.dataset;
    if (this.findPOItemIndex(item.dataset) === -1) {
      this.items.push({
        po_id: itemId,
        po_number: itemPoNumber,
        item_name: itemName,
        qty: parseInt(itemOrderedQty),
        checked: true
      });
    } else {
      this.items[this.findPOItemIndex(item.dataset)].checked = true;
    }
  }

  toggleAll() {
    if (this.allboxTarget.checked) {
      this.setAllCheckboxes(true);
    } else {
      this.setAllCheckboxes(false);
      this.items = [];
    }
  }

  setAllCheckboxes(checked) {
    this.allboxTargets.checked = true;
    if (this.checkboxTargets.length === 0) return;

    this.checkboxTargets.forEach(el => {
      el.checked = checked;
      this.addItem(el);
    });
    this.itemIds = this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    this.enableSubmitReceiptButton(checked);
    this.enableAlert(checked);
  }

  showAlertSuccess(numberOfItems) {
    this.alertTarget.classList.remove('alert-custom');
    this.alertTarget.classList.add('alert-success');
    this.alertTarget.classList.remove('d-none');
    this.alertCountTarget.innerHTML = `<b>${numberOfItems}</b> items updated.`;
  }

  alertInfo() {
    this.alertTarget.classList.remove('alert-success');
    this.alertTarget.classList.add('alert-custom');
    this.alertCountTarget.innerHTML = `<b>${this.items.length}</b> items are selected.`;
  }

  enableAlert(enable) {
    if (enable === true) {
      this.alertInfo();
      this.alertTarget.classList.remove('d-none');
    } else {
      this.alertTarget.classList.add('d-none');
    }
  }

  enableSubmitReceiptButton(enable) {
    if (enable === true) {
      this.submitReceiptButtonTarget.classList.remove('disabled');
    } else {
      this.submitReceiptButtonTarget.classList.add('disabled');
    }
  }

  receivedQtyChanged(event) {
    const receivedQty = this.convertNumericInput(event);
    const { itemId, itemName, itemPoNumber } = event.target.dataset;
    if (this.findPOItemIndex(event.target.dataset) === -1) {
      this.items.push({
        po_id: itemId,
        item_name: itemName,
        qty: receivedQty,
        po_number: itemPoNumber
      });
    } else {
      this.items[this.findPOItemIndex(event.target.dataset)].qty = receivedQty;
    }
  }

  convertNumericInput(event) {
    const value = parseInt(event.target.value);
    const { itemOrderedQty } = event.target.dataset;
    if (value < 0) {
      event.target.value = 0;
      return 0;
    } else if (value > itemOrderedQty) {
      event.target.value = itemOrderedQty;
      return itemOrderedQty;
    }
    return value;
  }

  findPOItemIndex(itemDataset) {
    const { itemId, itemName } = itemDataset;
    return this.items.findIndex(
      item => item.item_name.concat(item.po_id) === itemName.concat(itemId)
    );
  }

  requestSubmitReceipt() {
    const data = this.prepareData();
    $.ajax({
      url: '/manage/receiving_purchase_orders/bills',
      data: { purchase_order_items: data },
      method: 'POST',
      success: () => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('filters[status]', 'Fulfilled');
        queryParams.set('section', 'received');
        history.pushState(null, null, `?${queryParams}`);
        this.stimulate();
      },
      error: data => {
        console.log(data);
      }
    });
  }

  prepareData() {
    const filteredItems = this.filterItems();
    filteredItems.forEach(item => {
      delete item['checked'];
    });
    return filteredItems;
  }

  filterItems() {
    return this.items.filter(item => item.checked === true && item.qty != null);
  }
}
