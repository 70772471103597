import disableSubmitButtons from '../utils/disableSubmitButtons';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'backButton',
    'commit',
    'form',
    'header',
    'nextButton',
    'isEditAction',
    'step',
    'submitButton'
  ];

  connect() {
    super.connect();
  }

  initialize() {
    this.step = parseInt(this.element.dataset.current_step);
    this.steps = parseInt(this.element.dataset.steps) - 1;
    this.modelName = this.element.dataset.model;
    this.isEditAction = JSON.parse(this.formTarget.dataset.isEditActionValue);
    this.showCurrentSlide();
  }

  afterReflex() {
    $('select').not('.flatpickr-monthDropdown-months').selectpicker({
      dropupAuto: false
    });
  }

  runValidationReflex() {
    return this.stimulate(`${this.modelName}#validate`, this.getFormParams());
  }

  updateWizardHeader() {
    return this.stimulate(`${this.modelName}#update_wizard_header`, {
      step: this.step,
      is_progress_header: this.headerTarget.dataset.isProgressHeaderValue
    });
  }

  // this is fired on `prev` button click
  // it changes wizard step to the previous one
  prev() {
    if (this.step === 0) {
      return;
    }
    this.stepTargets[this.step].classList.add('d-none');
    this.step--;
    this.updateWizardHeader();
    this.showCurrentSlide();
  }

  // this is fired on `next` button click
  // it runs validations reflex and changes wizard step to the next one if step is valid
  next() {
    if (this.step === this.steps) {
      return;
    }
    this.runValidationReflex().then(() => {
      if (this.checkIfStepValid()) {
        this.step++;
        this.clearValidations();
      }
      this.updateWizardHeader();
      this.showCurrentSlide();
    });
  }

  goToStep(event) {
    this.runValidationReflex().then(() => {
      if (this.checkIfStepValid()) {
        this.step = parseInt(event.srcElement.dataset.wizardHeaderStepValue);
        this.clearValidations();
      }
      this.updateWizardHeader();
      this.showCurrentSlide();
    });
  }

  // this is fired on `add` button click
  // it runs validations reflex and submits form if step is valid
  submit(event) {
    event.preventDefault();
    disableSubmitButtons(event.target);

    this.runValidationReflex().then(() => {
      if (this.checkIfStepValid()) {
        this.clearValidations();
        this.formTarget.submitForm();
      }
      this.updateWizardHeader();
      this.showCurrentSlide();
    });
  }

  saveAndContinue(event) {
    this.commitTarget.value = 'SAVE_AND_CONTINUE';
    this.submit(event);
  }

  saveAndStay(event) {
    this.commitTarget.value = 'SAVE_AND_STAY';
    this.submit(event);
  }

  getFormParams() {
    return $(this.formTarget).serializeJSON();
  }

  checkIfStepValid() {
    return (
      $(this.stepTargets[this.step]).children().find('.is-invalid').length === 0
    );
  }

  showCurrentSlide() {
    this.stepTargets.forEach(step => {
      step.classList.add('d-none');
    });

    this.stepTargets[this.step].classList.remove('d-none');

    this.setNavigationButton();
    $('#current_step').val(this.step);
  }

  showCurrentHeader() {}

  clearValidations() {
    $(this.stepTargets[this.step])
      .children()
      .find('.is-invalid')
      .removeClass('is-invalid');
  }

  setNavigationButton() {
    if (this.isEditAction) {
      this.backButtonTarget.classList.add('d-none');
      this.nextButtonTarget.classList.add('d-none');
      this.submitButtonTarget.classList.remove('d-none');
    } else {
      if (this.step === this.steps) {
        this.nextButtonTarget.classList.add('d-none');
        this.submitButtonTarget.classList.remove('d-none');
      } else {
        this.submitButtonTarget.classList.add('d-none');
        this.nextButtonTarget.classList.remove('d-none');
      }
    }
  }
}
