import { areArrays, lengthDiffers } from './array';

export const any = xs => (xs ? Object.keys(xs).length > 0 : false);

export const mapEntries = f => xs =>
  Object.entries(xs).map(([key, value]) => [key, f(value, key, xs)]);

export const fromEntries = entries =>
  entries.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const map = f => xs => fromEntries(mapEntries(f)(xs));

export const filter = f => xs =>
  fromEntries(Object.entries(xs).filter(([key, value]) => f(value, key, xs)));

export const isObject = x => ({}.toString.apply(x) === '[object Object]');

export const keySet = (a, b) => [
  ...new Set([...Object.keys(a), ...Object.keys(b)])
];

export const equalsDeeplyWith = f => (a, b) => {
  if (areArrays(a, b)) {
    return (
      !lengthDiffers(a, b) &&
      a.every((_, key) => equalsDeeplyWith(f)(_, b[key]))
    );
  }

  return isObject(a) && isObject(b)
    ? a === b ||
        keySet(a, b).every(
          key =>
            Object.prototype.hasOwnProperty.call(a, key) &&
            Object.prototype.hasOwnProperty.call(b, key) &&
            equalsDeeplyWith(f)(a[key], b[key])
        )
    : f(a, b);
};

const defaultEquals = (a, b) => a === b;
export const equalsDeeply = equalsDeeplyWith(defaultEquals);
