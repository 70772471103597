import $ from 'jquery';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    super.initialize();
  }

  connect() {
    $('.order-line-items-chevron').on('click', () =>
      $('.order-line-items').toggleClass('active')
    );
  }

  disconnect() {
    $('.order-line-items-chevron').off('click');
  }
}
