import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      handle: '.drag-handle'
    });
  }

  end(event) {
    const { index } = event.item.dataset;
    const data = new FormData();
    data.append('position', event.newIndex + 1);

    Rails.ajax({
      url: this.data.get('url').replace(':id', index),
      type: 'PATCH',
      data: data
    });
  }
}
