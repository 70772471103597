import ApplicationController from './application_controller';

export default class extends ApplicationController {
  toggle(event) {
    const otherSwitch = document.getElementById(
      event.target.dataset.switchTarget
    );

    otherSwitch.disabled = event.target.checked;

    if (event.target.checked) {
      otherSwitch.checked = false;
    }
  }
}
