import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  submit(e) {
    const params = $(this.element).serializeJSON();
    e.target.setAttribute('disabled', 'disabled');
    e.preventDefault();

    this.stimulate(
      'SlidesCreatorReflex#apply_slider_to_other_company_group',
      params
    ).then(result => {
      $('.select2').val(null).trigger('change');
      $('#applySlidesToOthersModal').modal('hide');
      $('#applySlidesToOthersModalSuccess').modal('show');
      document
        .getElementById(`applyModalButton${this.data.get('id')}`)
        .removeAttribute('disabled');
      this.decrementReflexesCount();
      this.forceHideLoadingMessage();
    });
  }
}
