class ApplySettingsModal {
  constructor() {
    $('.select2').selectpicker('destroy');
    $('.select2')
      .select2()
      .on('select2:unselecting', () => {
        $(this).data('unselecting', true);
      })
      .on('select2:opening', event => {
        if ($(this).data('unselecting')) {
          $(this).removeData('unselecting');
          event.preventDefault();
        }
      })
      .on('select2:selecting', e => {
        if (e.params.args.data.id === 'select_all') {
          const masterBlanksAll = JSON.parse(
            e.target.getAttribute('data-master-blanks-all')
          );
          $(e.target).val(masterBlanksAll);
          $(e.target).trigger('change');
          $(e.target).select2('close');

          this.handleSelectChange($(e.target).val());
          e.preventDefault();
          e.stopPropagation();
        } else if (e.params.args.data.id === 'select_color_set') {
          const masterBlanksInColorSet = JSON.parse(
            e.target.getAttribute('data-master-blanks-in-color-set')
          );
          $(e.target).val(masterBlanksInColorSet);
          $(e.target).trigger('change');
          $(e.target).select2('close');

          this.handleSelectChange($(e.target).val());
          e.preventDefault();
          e.stopPropagation();
        }

        this.handleSelectChange($(e.target).val());
      })
      .on('select2:select', e => {
        this.handleSelectChange($(e.target).val());
      })
      .on('select2:unselect', e => {
        this.handleSelectChange($(e.target).val());
      });
  }

  handleSelectChange(selectedItems = []) {
    selectedItems.length > 0
      ? document.getElementById('applyModalButton').removeAttribute('disabled')
      : document
          .getElementById('applyModalButton')
          .setAttribute('disabled', 'disabled');
  }
}
export default ApplySettingsModal;
